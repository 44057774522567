import { UseQueryResult, useQuery } from "react-query";

import {
  LeaseApplicationSchema,
  LeaseApplication,
} from "domains/leaseApplication/models/LeaseApplication";
// import service from "domains/leaseApplication/service";

// import { useGetToken } from "hooks";

const fakeData = {
  id: "algún id",
  status: "algo",
  contract_payment_id: "algi",
  country: "Colombia",
  contract_preparation_form: {
    contract_init_date: "2021-09-01T00:00:00Z",
    number_of_adults: 1,
    number_of_minors: 1,
    has_pet: false,
  },
  contract: {
    id: 1,
    status: "Boton de pago enviado",
  },
  evaluation: {
    id: "asdasd",
    status: "approved",
    country: "Colombia",
    members: [
      {
        member_id: "aca va un id",
        role: "main",
        status: "approved",
        user_data: {
          id: 1,
          name: "Testname",
          last_name: "Testlastname",
          second_last_name: "Testsecondlastname",
          phone: "+549123455678",
          email: "testevaluation@nothoum.com",
          document: "111111111111",
          document_type: 1,
          country: "Colombia",
          created_at: "2021-09-01T00:00:00Z",
        },
      },
    ],
  },
  property: {
    id: 1,
    uid: "algún uid",
    address: "Calle falsa 123",
    price: 1000000,
    currency: "COP",
  },
  application_user: {
    id: 1,
    name: "Gabriel",
    last_name: "Rodriguez",
    second_last_name: "Gonzalez",
    email: "algo@asd.com",
    document: "124341917",
    phone: "+573209613583",
  },
};

interface Props {
  leaseApplicationId: string;
}

const useLeaseApplication = ({ leaseApplicationId }: Props) => {
  //const { getToken } = useGetToken();

  const query: UseQueryResult<LeaseApplication, Error> = useQuery(
    ["lease-application", leaseApplicationId],
    async () =>
      //const authToken = await getToken();
      // const response = service.getLeaseApplication(leaseApplicationId, authToken);
      LeaseApplicationSchema.parse(fakeData),
    {
      enabled: !!leaseApplicationId,
      retry: false,
    }
  );

  return query;
};

export default useLeaseApplication;
