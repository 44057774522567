import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { PhoneInput } from "@houm-com/ui/FieldPhoneNumber/utils/types";

import { setThirdPartyData, setReady } from "context/closureFormContext/closureFormActions";
import { useClosureForm } from "context/closureFormContext/closureFormContext";
import { ThirdPartyProps } from "context/closureFormContext/closureFormTypes";
import fields from "context/closureFormContext/closureFormFields";
import { useFocusError, useFormNotifications } from "hooks";
import { requiredText } from "utils/yupValidators";

import FilteredPhoneNumber from "./FilteredComponents/FilteredPhoneNumber";
import { thirdPartyValidationSchema } from "./validationSchema";
import { FilteredTextField } from "./FilteredComponents";
import { handleDocumentTypeChange } from "./utils";
import CallToAction from "./CustomCallToAction";
import UserDocuments from "./UserDocuments";

export default function ThirdPartyStep() {
  const { state, dispatch } = useClosureForm();
  const [documentRegex, setDocumentRegex] = useState<RegExp>();
  const stepState = state.thirdPartyData;
  const stepFields = fields.thirdParty;

  const methods = useForm<ThirdPartyProps>({
    resolver: yupResolver(thirdPartyValidationSchema(documentRegex)),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<ThirdPartyProps>({ errors, requiredText });
  useFocusError(errors, methods.setFocus);

  const onSubmit: SubmitHandler<ThirdPartyProps> = (data) => {
    dispatch(
      setThirdPartyData({
        ...stepState,
        ...data,
        country: state.propertyData.country,
        phone: (data.phone as PhoneInput).parsedPhone,
      })
    );
    dispatch(setReady("thirdPartyData"));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FilteredTextField id="name" fieldInfo={stepFields.name()} defaultValue={stepState.name} />

        <UserDocuments
          documentTypeField={stepFields.documentType()}
          documentTypeState={stepState.documentType}
          documentTypes={state.documentTypes}
          documentField={stepFields.document()}
          documentState={stepState.document}
          handleDocumentTypeChange={handleDocumentTypeChange(state.documentTypes, setDocumentRegex)}
        />

        <FilteredTextField
          id="email"
          fieldInfo={stepFields.email()}
          defaultValue={stepState.email}
        />

        <FilteredPhoneNumber
          id="phone"
          fieldInfo={stepFields.phone()}
          defaultValue={stepState.phone as string}
        />

        <FilteredTextField
          id="address"
          fieldInfo={stepFields.address()}
          defaultValue={stepState.address}
        />

        <CallToAction />
      </form>
    </FormProvider>
  );
}
