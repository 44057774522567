import { Locale } from "models/Countries";
import * as yup from "yup";

import { ContractPersonType, IDocumentTypeList } from "./types";
import { phoneValidator } from "utils/yupValidators/phone";

export const requiredText = "Obligatorio";
const wrongEmail = "Ingrese un email válido";

const regexRfcValidation = (value: string) =>
  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{3})$/.test(
    value.toUpperCase()
  );

const rfcValidation = (_: any, value: string) => {
  if (value && regexRfcValidation(value)) {
    return value;
  } else if (!value) {
    return null;
  }
  return false;
};

export const codebtorFormSchema = (
  documentTypeList: IDocumentTypeList[],
  country: Locale,
  type: ContractPersonType
) =>
  yup
    .object({
      email: yup.string().email(wrongEmail).nullable().required(requiredText),
      phone: phoneValidator,
      name: yup.string().nullable().required(requiredText),
      address: yup.string().nullable().required(requiredText),
      document: yup.string().nullable().required(requiredText),
      document_type: yup.string().nullable().required(requiredText),
      tributaryType: type === "owner" && yup.string().required(requiredText),
      rfcNumber:
        country === "mx" &&
        yup
          .string()
          .nullable()
          .transform(rfcValidation)
          .required(requiredText)
          .typeError("RFC Inválido"),
    })
    .test("validationDocument", null, (obj) => {
      if (!obj.document_type)
        return new yup.ValidationError("Obligatorio", null, "document_type", "typeError");
      const regexValue = documentTypeList.filter(
        (doc) => doc.value === parseInt(obj.document_type)
      )[0];
      const regex = new RegExp(regexValue.regex);
      if (regex.test(obj.document.toUpperCase())) return true;
      return new yup.ValidationError("Documento no válido", null, "document", "typeError");
    });
