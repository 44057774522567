import ModalDialog from "@houm-com/ui/ModalDialog";

import { ContractPreparation } from "domains/leaseApplication/models/LeaseApplication";

import InitContract from "./components/InitContract";

interface Props {
  open: boolean;
  handleOpenModal: () => void;
  contractPreparation: ContractPreparation;
}

const ContractForm = ({ open, handleOpenModal, contractPreparation }: Props) => (
  <ModalDialog title="Generar contrato" isOpen={open} onClose={handleOpenModal}>
    <InitContract contractPreparation={contractPreparation} />
  </ModalDialog>
);

export default ContractForm;
