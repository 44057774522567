import { validate, format } from "rut.js";
import * as yup from "yup";

import { BooleanString as BS } from "context/closureFormContext/constants";
import { requiredText, invalidFormat } from "utils/yupValidators";
import { phoneValidator } from "utils/yupValidators/phone";
import { CountryUpperCase } from "models/Countries";

const stringInteger = yup
  .string()
  .nullable()
  .min(0)
  .matches(/^$|^\d+$/, "Sólo se permiten números positivos");

const commaSeparatedIntegers = yup
  .string()
  .nullable()
  .matches(/^$|^\d+(,\d+)*$/, "Sólo se permiten números separados por coma");

const percentageMsg = "Debe ser un número entre 0 y 1";
const percentage = yup.number().min(0, percentageMsg).max(1, percentageMsg);

const rutValidationCl = yup.mixed().test({
  name: "rut",
  message: "Rut inválido",
  test: (value) => validate(format(value)),
});

const rfcValidation = yup
  .string()
  .nullable()
  .matches(
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{3})$/,
    invalidFormat
  )
  .required(requiredText);

const minNotRequired = (value) =>
  yup
    .string()
    .matches(
      new RegExp(`^[a-zA-Z0-9-]{${value},}$|^$`),
      `${invalidFormat}: debe tener más de ${value} caracteres`
    );

const documentValidation = (regex: RegExp) => yup.string().matches(regex, invalidFormat);

export const propertyValidationSchema = (country: CountryUpperCase | "") =>
  yup.object().shape({
    availableDate: country === "Mexico" && yup.string().required(requiredText),
    rentValue: stringInteger,
    hasCommonExpenses: yup.string(),
    commonExpenses: yup.string().when("hasCommonExpenses", {
      is: BS.YES,
      then: stringInteger.required(requiredText),
    }),
    country: yup.string(),
    maxPeopleQuantity: stringInteger,
    warehouseNumber: commaSeparatedIntegers,
    parkingNumbers: commaSeparatedIntegers,
    departmentNumber: stringInteger,
    addressNumber: stringInteger,
    address: yup.string().required(requiredText),
    publicDeed: yup.string(),
    publicDeedNumber: minNotRequired(5),
    publicDeedDate: yup.string().when("publicDeed", {
      is: BS.YES,
      then: country === "Mexico" ? yup.string().required(requiredText) : yup.string().nullable(),
    }),
    propertyRegistrationNumber: minNotRequired(3),
    predialNumber: minNotRequired(5).max(18, `${invalidFormat}: debe tener menos de 18 caracteres`),
    warranty: country === "Mexico" ? yup.string().required(requiredText) : yup.string().nullable(),
  });

export const landlordValidationSchema = (documentRegex: RegExp, country: CountryUpperCase | "") =>
  yup.object().shape({
    name: yup.string().required(requiredText),
    rfc: country === "Mexico" && rfcValidation,
    document:
      country === "Chile"
        ? rutValidationCl.required(requiredText)
        : documentValidation(documentRegex).required(requiredText),
    documentType: yup.string().matches(/[^0]+/, invalidFormat),
    phoneNumber: phoneValidator,
    email: yup.string().email("Mail inválido"),
    address: yup.string().required(requiredText),
    civilStatus: yup.string(),
    maritalRegime: yup.string().when("civilStatus", {
      is: "married",
      then: yup.string().required(requiredText),
    }),
    landlordBrokerageCommission: percentage,
    tenantBrokerageCommission: percentage,
  });

export const thirdPartyValidationSchema = (documentRegex: RegExp) =>
  yup.object().shape({
    name: yup.string().required(requiredText),
    document: documentValidation(documentRegex).required(requiredText),
    documentType: yup.string().matches(/[^0]+/, requiredText),
    phone: phoneValidator,
  });

export const servicesValidationSchema = yup.object({});

export const adminValidationSchema = yup.object({});

export const filesValidationSchema = yup.object({});
