import { IPlan } from "models/Plan";

import { Macrozone, Neighborhood } from "./Property";
import { HoumChecker, UserModel } from "./User";
import { CountryUpperCase } from "./Countries";
import { PlanMovementType } from "./Payments";
import { DocumentType } from "./Document";
import { Codebtor } from "./Evaluation";
import { CurrencyCode } from "./Currencies";

export interface ISign {
  id?: number;
  created_at?: string;
  updated_at?: string;
  s3_ci_front_key?: string;
  s3_ci_back_key?: string;
  s3_ci_signature_key?: string;
}

export interface ILegalRepresentative {
  address?: string;
  city?: string;
  civil_status?: string;
  country?: string;
  created_at?: string;
  document?: string;
  document_type?: DocumentType;
  email?: string;
  id?: number;
  marital_regime?: string;
  name?: string;
  nonce?: string;
  phone?: string;
  rut?: string;
  sign?: ISign;
  tributary_type?: string;
  updated_at?: string;
  ws_signatory_id?: string;
  average_salary: string;
  employer_name: string;
  nationality: string;
  occupation: string;
  rfc_number: string;
}

export type BillingDocumentType = "Boleta" | "Factura";

export interface ContractModel {
  id?: number;
  amount_policy: number;
  common_expenses?: number;
  created_at?: string;
  quotation?: any;
  updated_at?: string;
  init_date?: string;
  property_delivery_date?: string;
  months?: number;
  pay_day?: string | number;
  amount?: number;
  type_money?: string;
  observations?: string;
  landlord_observations?: string;
  automatic_renewal?: boolean;
  lessor_brokerage_discount?: string | number;
  lessee_brokerage_discount?: string | number;
  lessor_legal_representative?: ILegalRepresentative;
  lessee_legal_representative?: ILegalRepresentative;
  lessor_email?: string;
  propietary_email?: string;
  first_payment_date?: string;
  person_warranty?: "Houm" | "Lessor";
  readjust?: string;
  readjust_date?: string;
  is_transfer?: boolean;
  plan?: IPlan;
  end_notice_period?: number;
  is_payment_simulation: boolean;
  houmer?: {
    id: string;
  };
  has_houm_cleaning?: boolean;
  has_brokerage?: boolean;
  property?: {
    id?: number;
    deleted_at?: string;
    is_deleted?: boolean;
    address?: string;
    apartment_floor?: number;
    available_from?: string;
    can_schedule?: boolean;
    code?: string | number;
    comuna?: string;
    corretaje?: number;
    country?: CountryUpperCase;
    created_at?: string;
    deal_id?: string;
    external_id?: string | number;
    for_rental?: boolean;
    for_sale?: boolean;
    homechecker_last_visit_date?: string;
    houm_owner_observations?: string;
    insight_sent?: boolean;
    is_favorite?: boolean;
    is_new?: boolean;
    keys_info?: string;
    mortgage_status?: string;
    numero_depto?: string;
    pin?: string;
    propietary_comment?: string;
    region?: string;
    second_line_address?: string;
    stratus?: number;
    street_number?: string;
    taxes?: number;
    taxes_currency?: string;
    taxes_currency_code?: string | number;
    type?: string;
    uid?: string;
    updated_at?: string;
    video_url?: string;
    video360_url?: string;
    vitruvio_suggest?: number;
    vitruvio_max?: number;
    vitruvio_min?: number;
    year_built?: number;
    is_iva_responsible?: boolean;
    administration_email?: string;
    administration_phonenumber?: string;
    priority?: number;
    first_publication_date?: string;
    last_publication_date?: string;
    lessor_brokerage_commission?: number;
    priority_sales?: any;
    antiquity?: number;
    half_baths?: number | boolean;
    published?: boolean;
    reservado?: boolean;
    lost?: boolean;
    lost_reason?: string;
    state?: string;
    suggested_price_status?: string;
    city?: string;
    hotness_score?: number;
    need_republish?: boolean;
    price?: number;
    property_relation?: string;
    homechecker?: HoumChecker;
    houm_owner?: UserModel;
    lessor?: null | any;
    macrozone?: Macrozone;
    neighborhood?: Neighborhood;
    plan?: string | number;
    user?: UserModel;
    pipedrive_owner?: any;
  };
  signature_date?: string;
  owner?: Codebtor;
  main_tenant?: Codebtor;
  lessee_aval?: any;
  codebtors?: Codebtor[];
  init_date_proposal?: string;
  status?: string;
  rent_application?: any;
  document_url?: string | number;
  warehouse_number?: string;
  parking_number?: string;
  habitants_description?: string;
  tenant_has_pets?: boolean;
  online_process_status?: string;
  max_people_quantity?: number;
  pet_bans?: string;
  other_bans?: string;
  rent_include_common_expenses?: boolean;
  warranty?: string;
  plan_discount?: string | number;
  has_promotion?: boolean;
  promotional_price?: number;
  promotion_months?: string | number;
  lessor_brokerage_commission?: string;
  lessee_brokerage_commission?: string;
  evaluation_ids?: number[];
  management?: boolean;
  discard_reason?: string;
  discard_author?: string;
  discard_comment?: string;
  billing_document?: BillingDocumentType;
  renewal_at?: string;
  property_furnishing?: string;
}

export type TributaryType = "natural" | "corporative";

export interface IPerson {
  rut?: string;
  document_type?: DocumentType;
  email?: string;
  phone?: string;
  name?: string;
  address?: string;
  document?: string;
  rfcNumber?: string;
  id?: number;
  identification?: string;
  nationality?: string;
  occupation?: string;
  civil_status?: string;
  employer?: string;
  salaryAverage?: number;
  evaluationId?: number;
  isCodebtor?: boolean;
  documentType?: string;
  rfc_number?: string;
  tributary_type?: TributaryType;
}

export interface IMonthlyData {
  payment_detail_id: string;
  contract_id: number;
  lessee_brokerage: number;
  lessor_brokerage: number;
  created_at: string;
  updated_at: string;
  creator_email: string;
  eliminated: boolean;
  movement_created: boolean;
  currency: "COP" | "CLP" | "MXN" | "UF";
  plan_type: PlanMovementType;
  month_number: number;
  management_fee: number;
  lessee_payment: number;
  lessor_payment: number;
  common_expenses: number;
  total_payment_lessee: number;
  total_payment_lessor: number;
  total_profit: number;
}

interface IBreakdownDetail {
  identifier: string;
  created_at: string;
  label: string;
  description: string;
  value: number;
}

interface IPromotionDetail {
  benefit_id: number;
  created_at: string;
  currency: CurrencyCode;
  description: string;
  identifier: string;
  label: string;
  promotion_amount: number;
  value: number;
}

export interface ISimulationContractDetails {
  additional_lessee: number;
  additional_lessor: number;
  advancement_pay: number;
  advancement_management: number;
  advancement_common_expenses?: number;
  additional_breakdown_lessor?: IBreakdownDetail[];
  additional_breakdown_lessee?: IBreakdownDetail[];
  advancement_management_discount?: number;
  advancement_pay_lessee: number;
  contract_id: number;
  contract_payment_id: number;
  common_expenses?: number;
  created_at: string;
  creator_email: string;
  currency: "COP" | "CLP" | "MXN" | "UF";
  discount_lessee: number;
  discount_lessor: number;
  discounts_breakdown_lessee: IBreakdownDetail[];
  discounts_breakdown_lessor: IBreakdownDetail[];
  eliminated: false;
  insurance?: number;
  lease?: number;
  lease_lessee?: number;
  lease_lessor?: number;
  lessee_brokerage: number;
  lessor_brokerage: number;
  lease_management?: number;
  lease_management_discount?: number;
  lessor_brokerage_discount?: number;
  lessee_brokerage_discount?: number;
  lessor_operational_charge?: number;
  lessee_operational_charge?: number;
  management: number;
  movement_created: false;
  notarial_charge: number;
  payment_detail_id: string;
  paid_reservations: number[];
  plan_type: PlanMovementType;
  proportional_days_payment: number;
  proportional_common_expenses: number;
  proportional_days_management: number;
  proportional_days_management_discount?: number;
  reservation_total: number;
  total_payment_lessee: number;
  total_payment_lessor: number;
  total_profit: number;
  updated_at: string;
  warranty: number;
  warranty_retainer: "HOUM" | "LESSOR";
  lessee_promotions?: IPromotionDetail[];
  lessor_promotions?: IPromotionDetail[];
}

export interface ISimulationData {
  contract_details: ISimulationContractDetails;
  monthly_details: IMonthlyData[];
}

export interface IPlanMovement {
  amount: string;
  person: "Lessee" | "Lessor";
  description: string;
  descriptionAux: string;
  deposit_date: string;
  type_movement: string;
  contract: number;
  id: number;
}

export interface ISignatureInfo {
  cp_id: number | string;
  status: string;
  name: string;
  signature?: string;
  sign_proof?: string;
  sign_stored: string;
}

export interface ISignatures {
  lessor: ISignatureInfo;
  main_tenant: ISignatureInfo;
  aval: ISignatureInfo;
  lessor_legal_representative: ISignatureInfo;
  lessee_legal_representative: ISignatureInfo;
  codebtor?: ISignatureInfo[];
}

export interface IContractTemplate {
  contract_general_template: File | string;
  country: "Chile" | "Colombia" | "Mexico";
  id: number;
  mandate_general_template: File | string;
  description: string;
  name: string;
}

export const exitProcessNotStartedStatus = "NOT_STARTED";
export const exitProcessStartedStatus = "IN_PROCESS";
export const exitProcessEndedStatus = "ENDED";
export const exitProcessCancelledStatus = "CANCELLED";
export const exitProcessNoRenovationType = "NO_RENOVATION";
export const exitProcessEarlyFinishType = "EARLY_FINISH";
export const exitProcessAbandonmentType = "ABANDONMENT";
export const exitProcessEvictionType = "EVICTION";
export const exitProcessRequestedByTenantType = "TENANT";
export const exitProcessRequestedByLandlordType = "LANDLORD";
export const exitProcessRequestedByAdminType = "ADMIN";

export type ContractExitProcessTypes =
  | "NO_RENOVATION"
  | "EARLY_FINISH"
  | "ABANDONMENT"
  | "EVICTION";

export type ContractExitProcessStatuses = "NOT_STARTED" | "IN_PROCESS" | "ENDED" | "CANCELLED";

export type ContractExitProcessRequestedByUser = "TENANT" | "LANDLORD" | "ADMIN";

export interface IContractExitProcess {
  contract_id: number;
  status: ContractExitProcessStatuses;
  type: ContractExitProcessTypes;
  requested_by_user: ContractExitProcessRequestedByUser;
  estimated_date: string;
  agreed_date: string;
  comment: string;
}
