export type Country = "chile" | "mexico" | "colombia";

export type CountryUpperCase = "Chile" | "Colombia" | "Mexico" | "México";

export type Timezones =
  | "America/Santiago"
  | "America/Bogota"
  | "America/Mexico_City"
  | "America/Tijuana";

export type Currency = "MXN" | "COP" | "CLF" | "CLP" | "USD";

export enum CountriesEnum {
  CL = "Chile",
  CO = "Colombia",
  MX = "Mexico",
}

export enum LocaleCountries {
  CL = "cl",
  CO = "co",
  MX = "mx",
}

export const COUNTRY_LOCALE_ARRAY = [
  LocaleCountries.CL,
  LocaleCountries.CO,
  LocaleCountries.MX,
] as const;
export const COUNTRY_ARRAY = [CountriesEnum.CL, CountriesEnum.CO, CountriesEnum.MX] as const;

export type Locale = "cl" | "co" | "mx";

export type Regions = "Baja California";

export enum Countries {
  CHILE = "chile",
  COLOMBIA = "colombia",
  MEXICO = "mexico",
}
