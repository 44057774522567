import Button from "@houm-com/ui/Button";

import { ContractPreparation } from "domains/leaseApplication/models/LeaseApplication";
import useModal from "hooks/useModal";

import ContractForm from "../ContractForm";

interface Props {
  contractPreparation: ContractPreparation;
}

const GenerateContract = ({ contractPreparation }: Props) => {
  const { handleOpenModal: onHandleToggleContractFrom, open: openContractFrom } = useModal();

  const onGenerateContract = () => onHandleToggleContractFrom();

  return (
    <>
      <Button size="md" variant="primary" type="button" onClick={onGenerateContract}>
        Generar contrato
      </Button>
      <ContractForm
        contractPreparation={contractPreparation}
        handleOpenModal={onHandleToggleContractFrom}
        open={openContractFrom}
      />
    </>
  );
};

export default GenerateContract;
