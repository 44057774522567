import { es } from "date-fns/locale";
import { format } from "date-fns";
import clx from "classnames";

import { Typography } from "@material-ui/core";

import useGeneralDetailsStyles from "./GeneralDetailsStyles";
import { Locale } from "models/Countries";

interface Props {
  id: number;
  initDate?: string;
  readjustDate?: string;
  payDay: number;
  firstPaymentDate: string;
  labelClassName?: string;
  country?: Locale;
}

const GeneralDetails = ({
  id,
  initDate,
  readjustDate,
  payDay,
  firstPaymentDate,
  labelClassName,
  country,
}: Props) => {
  const classes = useGeneralDetailsStyles();
  const parseStringDate = (dateString: string, formatType: string): string => {
    const dt = new Date(dateString);
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    return format(dtDateOnly, formatType, { locale: es });
  };

  return (
    <>
      <Typography className={clx(classes.text, labelClassName)}>
        <b>ID:</b>
        {` ${id}`}
      </Typography>
      <Typography className={clx(classes.text, labelClassName)}>
        <b>Fecha de inicio:</b>
        {` ${initDate ? parseStringDate(initDate, "dd/MM/yyyy") : "-"}`}
      </Typography>
      {country === "cl" && (
        <Typography className={clx(classes.text, labelClassName)}>
          <b>Fecha de reajuste:</b>
          {` ${readjustDate ? parseStringDate(readjustDate, "dd/MM/yyyy") : "-"}`}
        </Typography>
      )}
      <Typography className={clx(classes.text, labelClassName)}>
        <b>Día de pago de arriendo:</b>
        {` ${payDay ? `Día ${payDay}` : "-"}`}
      </Typography>
      <Typography className={clx(classes.text, labelClassName)}>
        <b>Fecha primer pago:</b>
        {` ${firstPaymentDate ? parseStringDate(firstPaymentDate, "PPP ") : "-"}`}
      </Typography>
    </>
  );
};

export default GeneralDetails;
