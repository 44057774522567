import { FC, InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";

import { Grid, Typography } from "@material-ui/core";
import FieldPhoneNumber from "@houm-com/ui/FieldPhoneNumber";

import useDataWrapperStyles from "./PhoneDataWrapperStyles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  editMode?: boolean;
  id: string;
  label: string;
  value: string | number;
}

const PhoneDataWrapper: FC<Props> = ({ editMode, id, label, value }: Props) => {
  const classes = useDataWrapperStyles();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  if (!editMode)
    return (
      <Grid item xs={6}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.value}>{value || "Sin registro"}</Typography>
      </Grid>
    );

  return (
    <Grid item xs={6} className={classes.dataWrapperRoot}>
      <FieldPhoneNumber
        id={id}
        label={label}
        placeholder={label}
        classNameContainer="relative"
        defaultValue={String(value)}
        onlyCountries={["CL", "CO", "MX", "US"]}
        errorMessage={errors[id]?.message as string}
        {...register(id)}
      />
    </Grid>
  );
};

export default PhoneDataWrapper;
