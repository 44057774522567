import * as yup from "yup";
import { phoneValidator } from "utils/yupValidators/phone";

import { Locale } from "models/Countries";

export const requiredText = "Obligatorio";
export const emailInvalid = "El email debe ser un email válido";

interface IDocumentTypeList {
  value: number;
  label: string;
  regex: string;
}

export const userFormSchema = (documentTypeList: IDocumentTypeList[], country: Locale) =>
  yup
    .object({
      email: yup.string().nullable().email(emailInvalid).required(requiredText),
      phone: phoneValidator,
      name: yup.string().nullable().required(requiredText),
      lastName: yup.string().nullable().required(requiredText),
      secondLastName: yup.string().nullable().required(requiredText),
      document: country !== "mx" && yup.string().nullable().required(requiredText),
      occupation: yup.string().nullable().required(requiredText),
      documentType: country !== "mx" && yup.string().nullable().required(requiredText),
    })
    .test("validationDocument", null, (obj) => {
      if (country !== "mx") {
        if (!obj.documentType) {
          return new yup.ValidationError("Obligatorio", null, "document_type", "typeError");
        }
        const regexValue = documentTypeList.filter(
          (doc) => doc.value === parseInt(obj.documentType)
        )[0];
        const regex = new RegExp(regexValue.regex);
        if (regex.test(obj.document.toUpperCase())) {
          return true;
        }
        return new yup.ValidationError("Documento no válido", null, "document", "typeError");
      }
      return true;
    });
