import * as yup from "yup";
import moment from "moment";
import { phoneValidator } from "utils/yupValidators/phone";

import { getTimezoneWithCommune, parseToUtc } from "utils/datetime";
import { CountryUpperCase } from "models/Countries";
import { UserModel } from "models/User";

const countrysForValidation = ["Chile", "Colombia"];

export const requiredText = "Obligatorio";
export const invalid = "El valor ingresado no es válido";
export const long = "El comentario puede tener un máximo de 255 caracteres";
export const hcDetails = "Deben existir los houmchecker details";
export const emailError = "Debe ser un email valido";
export const phoneError = "Número de celular inválido";

interface IDocumentTypeList {
  value: number;
  label: string;
  regex: string;
}
const hourFormat = "HH:mm";

export const userFormSchema = (
  documentTypeList: IDocumentTypeList[],
  country: CountryUpperCase,
  user: UserModel
) =>
  yup
    .object({
      event_type: yup
        .object({
          value: yup.string().nullable().required(requiredText),
        })
        .nullable()
        .required(requiredText),

      appraiser: yup
        .object({
          value: yup.string().nullable().required(requiredText),
          label: yup.string().nullable().required(requiredText),
        })
        .nullable()
        .required(requiredText),

      property: yup
        .object()
        .when("event_type", {
          is: (option) => option?.value?.includes("Visit"),
          then: () =>
            yup
              .object({
                home_checker_details: yup.array().min(1, hcDetails),
              })
              .nullable()
              .required(requiredText)
              .typeError(requiredText),
        })
        .when("event_type", {
          is: (option) => option?.value !== "Advisory",
          then: yup.object().nullable().required(requiredText).typeError(requiredText),
        }),
      date: yup.string().required(requiredText).typeError(invalid),
      end_date: yup.string().required(requiredText).typeError(invalid),
      begin_date: yup.string().required(requiredText).typeError(invalid),
      timezone: yup.string().required(requiredText).typeError(invalid),
      email:
        !user &&
        yup.string().when("event_type", {
          is: (option) => option?.value === "Advisory" || option?.value?.includes("Visit"),
          then: () => yup.string().email(emailError).required(requiredText),
        }),
      name:
        !user &&
        yup.string().when("event_type", {
          is: (option) => option?.value === "Advisory" || option?.value?.includes("Visit"),
          then: () => yup.string().required(requiredText),
        }),
      last_name:
        !user &&
        yup.string().when("event_type", {
          is: (option) => option?.value === "Advisory" || option?.value?.includes("Visit"),
          then: () => yup.string().required(requiredText),
        }),
      phone:
        !user &&
        yup.mixed().when("event_type", {
          is: (option) => option?.value === "Advisory" || option?.value?.includes("Visit"),
          then: phoneValidator,
        }),
      document:
        countrysForValidation.includes(country) &&
        !user &&
        yup.string().when("event_type", {
          is: (option) => option?.value === "Advisory" || option?.value?.includes("Visit"),
          then: () => yup.string().nullable().required(requiredText),
        }),

      documentType:
        countrysForValidation.includes(country) &&
        !user &&
        yup.string().when("event_type", {
          is: (option) => option?.value === "Advisory" || option?.value?.includes("Visit"),
          then: () => yup.string().nullable().required(requiredText),
        }),
      availability: yup.object().nullable(),
    })
    .test(
      "typeTest",
      null,
      ({
        date,
        name,
        email,
        phone,
        property,
        end_date,
        timezone,
        document,
        last_name,
        event_type,
        begin_date,
        documentType,
        availability,
      }) => {
        const [eventType, forRental, forSale] = [
          event_type?.value,
          property?.for_rental,
          property?.for_sale,
        ];

        if (!property && eventType !== "Advisory") {
          return new yup.ValidationError("Propiedad no seleccionada", null, "required");
        }
        if (!eventType) {
          return new yup.ValidationError(
            "Se debe selecionar un tipo de agendamiento",
            null,
            "required"
          );
        }

        const hourValidation = validateHours({
          date,
          timezone,
          end_date,
          property,
          eventType,
          begin_date,
          availability,
        });

        const userValidation = validateUser({
          user,
          name,
          email,
          phone,
          country,
          document,
          eventType,
          documentType,
          documentTypeList,
          lastName: last_name,
        });
        const propertyTypeValidation = validateType({
          eventType,
          forRental,
          forSale,
        });
        return propertyTypeValidation || hourValidation || userValidation || true;
      }
    );

const validateHours = ({
  begin_date,
  end_date,
  property,
  timezone,
  availability,
  date,
  eventType,
}) => {
  const isBeforeNow = checkTimeIsBeforeNow({
    date,
    timezone,
    property,
    beginDate: begin_date,
    endDate: end_date,
  });

  if (isBeforeNow) {
    return new yup.ValidationError("No se pueden agendar citas en el pasado", null, "required");
  }

  const beginDateMoment = moment(begin_date, hourFormat);
  const endDateMoment = moment(end_date, hourFormat);
  const isWrongMinutTime = endDateMoment.isBefore(beginDateMoment);

  if (isWrongMinutTime) {
    return new yup.ValidationError(
      "Error en horarios seleccionados. El horario de término debe ser posterior al de inicio.",
      null,
      null
    );
  }

  const hoursInValidRange = isInsideRanges({
    availability,
    beginDateMoment,
    endDateMoment,
    date,
  });
  if (
    !hoursInValidRange &&
    !eventType.includes("Photo") &&
    !eventType.includes("Keys") &&
    !eventType.includes("Advisory")
  ) {
    return new yup.ValidationError(
      "El horario selecionado no se encuentra dentro del horario disponible",
      null,
      "required"
    );
  }
  return false;
};

const checkTimeIsBeforeNow = ({ property, timezone, date, beginDate, endDate }) => {
  const tz = property ? getTimezoneWithCommune(property.country, property.comuna) : timezone;
  const { begin_hour: beginHour } = parseToUtc(`${date} ${beginDate}`, endDate, tz);
  const datetimeMoment = moment.utc(beginHour);
  const isBeforeNow = datetimeMoment.isBefore(moment.now());

  return isBeforeNow;
};

const isInsideRanges = ({ date, availability, beginDateMoment, endDateMoment }) => {
  const weekDay = moment(date, "YYYY-MM-DD").weekday() + 1;

  const ranges = availability ? availability[weekDay] : [];

  const isValidHours =
    ranges &&
    ranges?.find(({ beginHour, endHour }) => {
      const beginMoment = moment(beginHour, hourFormat); //comes from availability
      const endMoment = moment(endHour, hourFormat); //comes from availability

      const leftInsideLimit =
        beginDateMoment.isAfter(beginMoment) || beginDateMoment.isSame(beginMoment);

      const rightInsideLimit = endDateMoment.isBefore(endMoment) || endDateMoment.isSame(endMoment);

      return leftInsideLimit && rightInsideLimit;
    });
  return isValidHours;
};

const validateUser = ({
  user,
  eventType,
  name,
  email,
  phone,
  document,
  country,
  lastName,
  documentType,
  documentTypeList,
}) => {
  if (!user && (eventType.includes("Visit") || eventType === "Advisory")) {
    if (!email) {
      return new yup.ValidationError(
        "No se pueden agendar citas sin email del postulante",
        null,
        "required"
      );
    }

    if (!name) {
      return new yup.ValidationError(
        "No se pueden agendar citas sin el nombre del postulante",
        null,
        "required"
      );
    }

    if (!lastName) {
      return new yup.ValidationError(
        "No se pueden agendar citas sin el apellido del postulante",
        null,
        "required"
      );
    }

    if (!phone) {
      return new yup.ValidationError(
        "No se pueden agendar citas sin teléfono del postulante",
        null,
        "required"
      );
    }

    if (!document && countrysForValidation.includes(country)) {
      return new yup.ValidationError(
        "No se pueden agendar citas sin el documento",
        null,
        "required"
      );
    }

    if (!documentType && countrysForValidation.includes(country)) {
      return new yup.ValidationError(
        "No se pueden agendar citas sin el tipo de documento",
        null,
        "required"
      );
    }
    const regexValue = documentTypeList.filter((doc) => doc.value === parseInt(documentType))[0];
    const regex = new RegExp(regexValue.regex.replace(/[/]/gi, ""));
    if (!regex.test(document.toUpperCase())) {
      return new yup.ValidationError("Documento no valido, verifica los datos", null, "required");
    }
  }
  return false;
};

const validateType = ({ eventType, forSale, forRental }) => {
  if (eventType === "Keys") return false;
  if (eventType === "Advisory") return false;
  if (eventType === "SalesVisit" && forSale) return false;
  if (eventType === "Visit" && forRental) return false;
  if (eventType === "SalesPhoto" && forSale) return false;
  if (eventType === "Photo" && forRental) return false;
  if (eventType === "Exit" && forRental) return false;
  if (eventType === "Inspection" && forRental) return false;
  if (eventType === "Cleaning") return false;

  return new yup.ValidationError(
    `No es posible coordinar la agenda de tipo ${eventType}  para una propiedad de ${
      (forSale && "venta") || ""
    } ${(forRental && "renta") || ""} `,
    null,
    "required"
  );
};

export default userFormSchema;
