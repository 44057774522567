import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router-dom";

import FieldPhoneNumber from "@houm-com/ui/FieldPhoneNumber";
import { Button, Divider } from "@material-ui/core";

import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import { useFormNotifications, useGetToken } from "hooks";

import { useContractPerson } from "context/contractPersonContext/contractPersonContext";
import { PersonProps } from "context/contractPersonContext/contractPersonTypes";
import { backParsePerson } from "context/contractPersonContext/utils";
import {
  submitCreateContract,
  submitCreateContractPerson,
} from "context/contractPersonContext/contractPersonActions";

import { mexicoValidationSchema, generalValidationSchema, requiredText } from "./validationSchema";
import useStyles from "../ContractPersonEditStyles";
import DocumentsInput from "./DocumentsInput";

export default function PersonForm() {
  const classes = useStyles();
  const { state } = useContractPerson();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const history = useHistory();
  const { getToken } = useGetToken();

  const methods = ["Mexico", "México"].includes(state.country)
    ? useForm<PersonProps>({
        resolver: yupResolver(
          mexicoValidationSchema({
            rfc: state.documentTypes.find((doc) => doc.type === "RFC")?.regex,
            document: state.person.documentType?.regex,
          })
        ),
        reValidateMode: "onBlur",
      })
    : useForm<PersonProps>({
        resolver: yupResolver(
          generalValidationSchema({
            document: state.person.documentType?.regex,
          })
        ),
        reValidateMode: "onBlur",
      });
  const { errors } = methods.formState;
  useFormNotifications<PersonProps>({ errors, requiredText });

  const onSubmit: SubmitHandler<PersonProps> = async (formData) => {
    const authToken = await getToken();
    setIsSubmitLoading(true);
    const data = backParsePerson(formData, state);

    if (!state.contractId) {
      await submitCreateContract(data, authToken, history.push);
    } else {
      await submitCreateContractPerson(data, authToken, state);
    }
    setIsSubmitLoading(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={classes.ContractPersonEditWrapper}>
          <p>{`Datos ${state.title}`}</p>
          <DocumentsInput />
          <Divider />
          <ControlledTextField
            label="Nombre Completo"
            id="name"
            textFieldProps={{
              placeholder: "Nombre",
            }}
            defaultValue={state.person.name}
          />
          <Divider />
          <FieldPhoneNumber
            id="phone"
            label="Teléfono"
            placeholder="Teléfono"
            defaultValue={String(state.person.phone)}
            onlyCountries={["CL", "CO", "MX", "US"]}
            errorMessage={errors.phone?.message}
            {...methods.register("phone")}
          />
          <ControlledTextField
            label="Email"
            id="email"
            textFieldProps={{
              placeholder: "Email",
            }}
            defaultValue={state.person.email}
          />
          <Divider />
          <ControlledTextField
            label="Dirección"
            id="address"
            textFieldProps={{
              placeholder: "Direccion Actual",
            }}
            defaultValue={state.person.address}
          />
          <Divider />
          <ControlledTextField
            label="Ocupación"
            id="occupation"
            textFieldProps={{
              placeholder: "Ocupación",
            }}
            defaultValue={state.person.occupation}
          />
          <Divider />

          <Button
            id="continue"
            variant="contained"
            color="primary"
            type="submit"
            className={classes.ContractPersonUpdateButton}
            disabled={isSubmitLoading}>
            {state.isNewPerson ? "Crear y continuar" : "Actualizar y continuar"}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
