import { useFormContext } from "react-hook-form";

import FieldPhoneNumber from "@houm-com/ui/FieldPhoneNumber";

import { useClosureForm } from "context/closureFormContext/closureFormContext";

import useStyles from "../ClosureFormStyles";
import { Props, filter } from "./utils";

interface FilteredPhoneNumberProps extends Props {
  defaultValue: string | number;
  hint?: string;
  id: "phone" | "phoneNumber";
}

const FilteredPhoneNumber: React.FC<FilteredPhoneNumberProps> = ({
  id,
  fieldInfo,
  defaultValue,
  hint = "",
}) => {
  const { state } = useClosureForm();
  const classes = useStyles();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  if (filter(fieldInfo, state.isAdmin, state.propertyData.country)) return null;
  return (
    <>
      <FieldPhoneNumber
        id={id}
        label={fieldInfo.label(state.propertyData.country)}
        onlyCountries={["CL", "CO", "MX", "US"]}
        defaultValue={String(defaultValue)}
        errorMessage={errors[id]?.message as string}
        {...register(id)}
      />
      {hint && <p className={classes.hint}>{hint}</p>}
      <div className={classes.divider} />
    </>
  );
};

export default FilteredPhoneNumber;
