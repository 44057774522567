import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  underlined: {
    borderBottom: "1px solid gray",
    marginBottom: "0px",
  },
  phoneField: {
    marginBottom: "1em",
  },
}));
