import { useHistory } from "react-router-dom";

import Button from "@houm-com/ui/Button";
import Tag from "@houm-com/ui/Tag";

import { Avatar } from "@material-ui/core";

import { classes } from "apps/brokerage-admin/screens/LeaseApplication/components/User/UserStyles";
import { LeaseApplicant } from "domains/leaseApplication/models/LeaseApplication";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";

interface Props {
  user: LeaseApplicant;
  title: string;
}

const User: React.FC<Props> = ({ user, title }) => {
  const history = useHistory();

  const onHandleEditUser = () => history.push(`/admin/users/${user.id}/edit`);

  return (
    <AdminCard
      title={title}
      showTitleBackground={true}
      withSpace={false}
      className={classes.userCardRoot}>
      <div className={classes.userCardContainer}>
        <div className={classes.userCardAvatarContainer}>
          <Avatar alt="user" />
          <Button variant="primary" size="sm" onClick={onHandleEditUser}>
            Editar {title}
          </Button>
        </div>
        <div>
          <p className={classes.userCardText}>
            {`${user.name} ${user.lastName} ${user.secondLastName}`}
          </p>
          <p className={classes.userCardText}>{user.email}</p>
          {user.document ? (
            <p className={classes.userCardText}>{user.document}</p>
          ) : (
            <Tag label="Falta Documento" variant="error" />
          )}
          <p className={classes.userCardText}>{user.phone}</p>
        </div>
      </div>
    </AdminCard>
  );
};

export default User;
