import { ComponentProps } from "react";

import Tag from "@houm-com/ui/Tag";

import { EvaluationMemberStatus, StatusMemberEnum } from "domains/evaluations/models/Evaluations";

export const statusVariant: {
  [locale in EvaluationMemberStatus]: ComponentProps<typeof Tag>["variant"];
} = {
  [StatusMemberEnum.COMMERCIALLY_APPROVED]: "success",
  [StatusMemberEnum.DESISTED]: "error",
  [StatusMemberEnum.PENDING_DOCS]: "warning",
  [StatusMemberEnum.READY_FOR_EVALUATION]: "info",
  [StatusMemberEnum.NEW_DOCS]: "warning",
  [StatusMemberEnum.APPROVED]: "success",
  [StatusMemberEnum.REJECTED]: "error",
  [StatusMemberEnum.START]: "default",
  [StatusMemberEnum.NO_DOCS]: "default",
};
