import { parseISO, subDays, format } from "date-fns";

import { TENANT_PLAN_OPTIONS } from "constants/tenantPlans";
import { IExternalCollection, IExternalCollectionResponse } from "services/contractService/types";
import { IInsuranceCompany } from "services/insuranceService/types";
import { IAppraiser } from "services/schedulesService/types";
import { IParsePlan } from "hooks/usePlans/utils/types";
import { Locale } from "models/Countries";
import { TenantPlan } from "models/Plan";

import { ContractType, PlanProps } from "./types";
import { CONTRACT_TYPES } from "./constants";

export const parsePlanForm = (data: PlanProps, brokerage) => ({
  amount_policy: 0,
  automatic_renewal: data.automaticRenewal === "true",
  end_notice_period: data.notification,
  first_payment_date: data.firstPaymentDate,
  has_brokerage: brokerage,
  houmer: data.houmer === "none" ? null : data.houmer,
  init_date: data.signatureDate,
  is_common_expenses_included: data.commonExpensedIncluded === "true",
  is_transfer: data.isTransfer === "true",
  pay_day: data.payDay,
  person_warranty: data.personWarranty,
  plan: data.planId,
  plan_discount: data.planDiscount,
  readjust: data.readjust,
  rent_include_common_expenses: data.commonExpensedIncluded === "true",
  warranty: data.warranty,
  lessee_brokerage_discount: data.lesseeBrokerageDiscount,
  lessor_brokerage_discount: data.lessorBrokerageDiscount,
  property_furnishing: data.propertyFurnishing,
});

export const parsePlanOptions = (plans: IParsePlan[]) =>
  plans.map((p: IParsePlan) => ({
    value: p.id,
    label: p.name,
  }));

export const parseAppraisersOptions = (appraisers?: IAppraiser[]) => {
  const result = [
    {
      value: "none",
      label: "Ningúno (traspaso/sin corretaje)",
    },
  ];
  if (appraisers) {
    appraisers.map((a) =>
      result.push({
        value: a.id,
        label: a.name,
      })
    );
  }
  return result;
};

export const parseInsuranceCompanyOptions = (insuranceCompanies: IInsuranceCompany[]) =>
  insuranceCompanies.map((company) => ({
    value: company.id,
    label: company.name,
  }));

export const parseTenantPlanRequestBody = (tenantPlan: TenantPlan) => ({
  tenant_plan: tenantPlan,
});

export const parseTenantPlansResponse = (tenantPlans: TenantPlan[]) =>
  tenantPlans.map((tenantPlan) => TENANT_PLAN_OPTIONS[tenantPlan.toLocaleUpperCase()]);

export const parseExternalColection = (externalCollection: IExternalCollectionResponse) => ({
  contractId: externalCollection.contract_id,
  contractType: externalCollection.contract_type,
  exclusivityConditions: externalCollection.exclusivity_conditions
    ? {
        futurePlan: externalCollection.exclusivity_conditions.future_plan,
        externalContractInitDate:
          externalCollection.exclusivity_conditions.external_contract_init_date,
      }
    : null,
});

export const parseExternalColectionRequestBody = (
  formData: PlanProps,
  country: Locale,
  contractId: number
): IExternalCollection => ({
  contract_id: contractId,
  contract_type:
    country === "cl" ? formData.contractType : (CONTRACT_TYPES.lease.value as ContractType),
  exclusivity_conditions: {
    plan_id: formData.futurePlanId ? Number(formData.futurePlanId) : null,
    external_contract_init_date:
      country === "cl" && formData.contractType === CONTRACT_TYPES.collection_service.value
        ? formData.externalContractInitDate
        : null,
  },
});

export const getMaxExternalContractInitDate = (signatureDate: string) => {
  if (!signatureDate) return null;
  const date = parseISO(signatureDate);
  const maxDate = subDays(date, 1);
  return format(maxDate, "yyyy-MM-dd");
};
