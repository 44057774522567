import { useEffect } from "react";
import { useParams } from "react-router-dom";

import Tag from "@houm-com/ui/Tag";
import Spinner from "@houm-com/ui/Spinner";

import {
  FACTOR_STATUS,
  FACTOR_TITLE,
} from "apps/evaluator-admin/components/MemberCard/components/Factor/utils/constants";
import { factorStatusVariant } from "apps/evaluator-admin/components/MemberCard/components/Factor/utils/utils";
import { EvaluationFactors } from "domains/evaluations/models/Evaluations";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import useFactorMember from "domains/evaluations/hooks/useFactorMember";
import { Locale } from "models/Countries";
import FactorResult from "apps/evaluator-admin/components/MemberCard/components/Factor/components/FactorResult";
import { classes } from "apps/evaluator-admin/components/MemberCard/components/Factor/FactorStyles";

interface Props {
  factor: EvaluationFactors;
}

const Factor = ({ factor }: Props) => {
  const { evaluationId, country } = useParams<{ evaluationId: string; country: Locale }>();
  const { mutate, isLoading, isSuccess } = useFactorMember({ country, evaluationId });

  useEffect(() => {
    mutate({
      evaluationMemberFactorId: factor.id,
      evaluationMemberId: factor.evaluationMemberId,
    });
  }, []);

  return (
    <AdminCard
      title={FACTOR_TITLE[factor.providerName]}
      component={
        factor.result?.status && (
          <Tag
            label={FACTOR_STATUS[factor.result?.status]}
            variant={factorStatusVariant[factor.result?.status]}
          />
        )
      }
      withSpace={false}>
      {isLoading && (
        <div className={classes.factorSpinner}>
          <Spinner variant="primary" size="lg" />
        </div>
      )}
      {isSuccess && <FactorResult result={factor.result} />}
    </AdminCard>
  );
};

export default Factor;
