import {
  StatusMemberEnum,
  EvaluationMemberStatus,
  EvaluationStatus,
  EvaluationStatusListEnum,
} from "domains/evaluations/models/Evaluations";

export enum Permissions {
  UPDATE_USER = "update:users:all",
  DELETE_FILE = "update:evaluation_documents:all",
  UPDATE_EVALUATION = "update:evaluations:all",
  CREATE_COMMENT = "create:evaluation_comments:all",
  READ_FILES = "read:evaluation_documents:all",
  UPDATE_EVALUATION_MEMBER = "update:evaluation_member:all",
  UPDATE_MAIN = "update:evaluation_main:all",
  DELETE_MEMBER = "delete:evaluation_member:all",
  READ_MEMBER_INFORMATION = "read:evaluation_member_information:all",
  UPDATE_MEMBER_INFORMATION = "update:person_information:all",
  UPDATE_HOUMER = "update:applicant_appraiser:all",
  UPDATE_BROKERAGE = "update:evaluation_brokerage:all",
}

export const EvaluationMemberStatusDictionary: {
  [locale in EvaluationMemberStatus]: string;
} = {
  [StatusMemberEnum.COMMERCIALLY_APPROVED]: "Aprobada comercialmente",
  [StatusMemberEnum.DESISTED]: "Desistida",
  [StatusMemberEnum.PENDING_DOCS]: "Documentación pendiente",
  [StatusMemberEnum.READY_FOR_EVALUATION]: "Lista para evaluar",
  [StatusMemberEnum.NEW_DOCS]: "Nuevos documentos",
  [StatusMemberEnum.APPROVED]: "Perfil aprobado",
  [StatusMemberEnum.REJECTED]: "Perfil rechazado",
  [StatusMemberEnum.START]: "Por comenzar",
  [StatusMemberEnum.NO_DOCS]: "Sin documentos",
};

export const EvaluationStatusDictionary: {
  [locale in EvaluationStatus]: string;
} = {
  [EvaluationStatusListEnum.PENDING]: "Pendiente",
  [EvaluationStatusListEnum.APPROVED]: "Aprobada",
  [EvaluationStatusListEnum.REJECTED]: "Rechazada",
  [EvaluationStatusListEnum.CANCELLED]: "Cancelada",
  [EvaluationStatusListEnum.INCOMPLETE]: "Incompleta",
  [EvaluationStatusListEnum.PARTIALLY_APPROVED]: "Pre-aprobada",
};
