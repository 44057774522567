import { SnackbarProvider } from "notistack";

import LeaseApplication from "./LeaseApplication";

const LeaseApplicationPage = () => (
  <SnackbarProvider>
    <LeaseApplication />
  </SnackbarProvider>
);

export default LeaseApplicationPage;
