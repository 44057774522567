import React from "react";
import { useFormContext } from "react-hook-form";

import { Grid } from "@material-ui/core";
import FieldPhoneNumber from "@houm-com/ui/FieldPhoneNumber";

import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import { NewAppraiserForm } from "context/controlTower/controlTowerTypes";
import useFormStyles from "./FormStyles";
import FormBlock from "./FormBlock";

function ContactFields() {
  const classes = useFormStyles();
  const {
    register,
    formState: { errors },
  } = useFormContext<NewAppraiserForm>();
  return (
    <FormBlock>
      <Grid container>
        <Grid item xs={12} className={classes.phoneField}>
          <FieldPhoneNumber
            id="phone"
            label="Teléfono"
            onlyCountries={["CL", "CO", "MX", "US"]}
            errorMessage={errors.phone?.message}
            {...register("phone")}
          />
        </Grid>
        <Grid item xs={12} className={classes.underlined}>
          <ControlledTextField id="email" label="Correo" />
        </Grid>
      </Grid>
    </FormBlock>
  );
}

export default React.memo(ContactFields);
