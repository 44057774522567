import { useState, useEffect, useRef } from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import momentTz from "moment-timezone";
import { Box, Grid, Typography, CircularProgress, ThemeProvider } from "@material-ui/core";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { NextButton } from "Components/Admin/administrator/properties/components/FormElements";
import ScheduleError from "Components/Admin/UIComponents/ScheduleError";
import useCreateVisitSchedule from "hooks/useCreateVisitSchedule";
import { parseScheduleAvailability } from "utils/datetime";
import scheduleService from "services/schedulesService";
import { PropertyData } from "models/Property";
import { getTimezone } from "utils";

import CustomThemePicker from "./customDatePickerStyles";
import schedulersStyles from "./SchedulersStyle";
import SelectHour from "./SelectHour";
import { useGetToken } from "hooks";

interface IAvailability {
  [k: string]: string[];
}

interface Props {
  property: PropertyData;
  scheduleType: "Exit" | "Inspection" | "Cleaning";
}

const ScheduleInspector = ({ property, scheduleType }: Props) => {
  const { mutate, isError, error, isLoading, isSuccess } = useCreateVisitSchedule();
  const { getToken } = useGetToken();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [allowedDays, setAllowedDays] = useState<moment.Moment[]>([]);
  const [availability, setAvailability] = useState<IAvailability | undefined>();
  const [selectedDay, setSelectedDay] = useState<moment.Moment>(moment());
  const [aviliableHours, setAviliableHours] = useState<string[]>([]);
  const [availabilityParsed, setAvailabilityParsed] = useState<IAvailability | undefined>();

  const selectHourRef = useRef(null);
  const commentRef = useRef(null);

  useEffect(() => {
    momentTz.tz.setDefault();
    const getAvailability = async () => {
      setLoading(true);
      const authToken = await getToken();
      const availabilityResponse = (await scheduleService.getAvailableHours(
        scheduleType,
        property.uid,
        authToken
      )) as AxiosResponse<any>;
      setLoading(false);
      if (availabilityResponse) {
        setAvailability(availabilityResponse.data);
      } else {
        Swal.fire({
          type: "error",
          title: "No se pudieron obtener los horarios.",
          timer: 7500,
        });
      }
    };
    getAvailability();
  }, []);

  useEffect(() => {
    const parsedAvailability = parseScheduleAvailability(
      availability,
      getTimezone(property?.country, property?.comuna)
    );
    setAvailabilityParsed(parsedAvailability);

    if (Object.keys(parsedAvailability).length > 0) {
      setIsEmpty(false);
      setAllowedDays(Object.keys(parsedAvailability).map((value) => moment(value)));
      setSelectedDay(moment(Object.keys(parsedAvailability)[0]));
      const filteredHours = parsedAvailability[Object.keys(parsedAvailability)[0]];
      setAviliableHours(filteredHours);
      selectHourRef.current?.setValue(filteredHours[0]);
    } else {
      setIsEmpty(true);
    }
  }, [availability]);

  const handleChangeDay = (day) => {
    if (day.isValid() && availability) {
      setSelectedDay(day);
      const newDay = Object.keys(availabilityParsed).find((item) =>
        moment(item).isSame(day, "date")
      );
      if (newDay) {
        const filteredHours = availabilityParsed[newDay];
        setAviliableHours(filteredHours);
      }
    }
  };

  const handleSubmit = async () => {
    const commentValue = commentRef.current.value;
    if (selectHourRef.current?.validate()) {
      const selectedHour = selectHourRef.current.getValue().hour;
      const begin = moment
        .utc(selectedHour)
        .clone()
        .tz(getTimezone(property?.country, property?.comuna));
      const end = moment
        .utc(selectedHour)
        .clone()
        .add(2, "hours")
        .tz(getTimezone(property?.country, property?.comuna));
      mutate({
        begin_hour: begin,
        end_hour: end,
        prop: property.uid,
        date: begin,
        type: scheduleType,
        origin: "Admin - Propiedad",
        ...(commentValue && { comment: commentValue }),
      });
    }
  };

  if (isSuccess)
    Swal.fire({
      type: "success",
      title: "Se ha agendado la visita con exito",
    });

  const classes = schedulersStyles();
  return (
    <Box>
      {loading && <CircularProgress />}
      {isEmpty && !loading && (
        <Typography variant="h6">No se ha encontrado agenda disponible</Typography>
      )}
      {isError && <ScheduleError error={error} />}
      {!isEmpty && (
        <Grid container spacing={4} justifyContent="center">
          <Grid item sm={12} className={classes.tabs}>
            <ThemeProvider theme={(theme) => CustomThemePicker(theme)}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                  shouldDisableDate={(date) => !allowedDays.find((day) => day.isSame(date, "date"))}
                  value={selectedDay}
                  variant="static"
                  disableToolbar
                  disablePast
                  onChange={handleChangeDay}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </Grid>
          <Grid item sm={12}>
            <SelectHour
              id="hour"
              hours={aviliableHours}
              day={selectedDay.format("YYYY-MM-DD")}
              country={property?.country || "Chile"}
              commune={property?.comuna}
              ref={selectHourRef}
            />
          </Grid>
          <Grid item sm={12}>
            <label className={classes.label}>
              Comentario:
              <textarea ref={commentRef} className={classes.commentBox} />
            </label>
          </Grid>
          <Box justifyContent="flex-end" display="flex" width="100%">
            <NextButton disabled={isLoading} onClick={handleSubmit}>
              {isLoading ? <CircularProgress color="secondary" size={20} /> : "Agendar inspección"}
            </NextButton>
          </Box>
        </Grid>
      )}
    </Box>
  );
};

export default ScheduleInspector;
