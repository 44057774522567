import { useMutation } from "react-query";
import { AxiosError } from "axios";

import contractService from "services/contractService";
import { TenantPlan } from "models/Plan";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

import { parseTenantPlanRequestBody } from "../utils/utils";

const useSaveContractTenantPlan = (contractId: number) => {
  const { getToken } = useGetToken();

  const { mutate: saveContractTenantPlanMutation, isLoading: submittingTenantPlan } = useMutation(
    async (tenantPlan: TenantPlan) => {
      const authToken = await getToken();
      await contractService.setContractTenantPlan(
        contractId,
        parseTenantPlanRequestBody(tenantPlan),
        authToken
      );
    },
    {
      onError: (e: AxiosError<{ error: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "Error al asignar plan",
          disclaimer: `No se pudo asignar plan de arrendatario al contrato. Error: ${
            reason ? reason?.data?.error : e
          }`,
        });
      },
    }
  );
  return { saveContractTenantPlanMutation, submittingTenantPlan };
};

export default useSaveContractTenantPlan;
