export type CurrencyCode = "MXN" | "CLP" | "CLF" | "COP";

export enum CurrencyCodeEnum {
  MXN = "MXN",
  CLP = "CLP",
  CLF = "CLF",
  COP = "COP",
}

export const CURRENCIES_ARRAY = [
  CurrencyCodeEnum.CLP,
  CurrencyCodeEnum.COP,
  CurrencyCodeEnum.MXN,
  CurrencyCodeEnum.CLF,
] as const;
