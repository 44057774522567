import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import GenerateContract from "./components/GenerateContract";
import {
  ContractLeaseApplication,
  ContractPreparation,
} from "domains/leaseApplication/models/LeaseApplication";

import { classes } from "./ContractCardStyles";
import Contract from "./components/Contract";

interface Props {
  contractGenerated: ContractLeaseApplication | null;
  contractPreparation: ContractPreparation | null;
}

const ContractCard = ({ contractGenerated, contractPreparation }: Props) => (
  <AdminCard
    title="Contrato"
    showTitleBackground={true}
    withSpace={false}
    className={classes.contractCardRoot}>
    <div className={classes.contractCardContainer}>
      {contractGenerated && <Contract contract={contractGenerated} />}
      {!contractGenerated && <GenerateContract contractPreparation={contractPreparation} />}
    </div>
  </AdminCard>
);

export default ContractCard;
