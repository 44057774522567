import { useGetToken } from "hooks";
import { Locale } from "models/Countries";
import { UseQueryResult, useQuery } from "react-query";
import insuranceService from "services/insuranceService";
import { IInsuranceCompany } from "services/insuranceService/types";

const useGetInsuranceCompanies = (country: Locale) => {
  const { getToken } = useGetToken();
  const {
    data = [],
    error,
    isLoading,
  }: UseQueryResult<IInsuranceCompany[], Error> = useQuery<IInsuranceCompany[], Error>(
    ["insuranceCompanies", country],
    async () => {
      const authToken = await getToken();
      const response = await insuranceService.getInsuranceCompanies(country, authToken);
      return response.data;
    },
    {
      enabled: !!country,
    }
  );
  return {
    insuranceCompanies: data,
    insuranceCompaniesError: error,
    insuranceCompaniesLoading: isLoading,
  };
};

export default useGetInsuranceCompanies;
