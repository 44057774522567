import { format } from "rut.js";

import { PhoneInput } from "@houm-com/ui/FieldPhoneNumber/utils/types";

import { UserModel } from "models/User";
import { UpdateFormData } from "./types";

export const getUserDefaultData = (user: UserModel) => ({
  name: user?.name ?? "",
  lastName: user?.last_name ?? "",
  secondLastName: user?.second_last_name ?? "",
  documentType: user?.document_type ? (user?.document_type as number).toString() : "",
  document: user?.document ?? "",
  email: user?.email ?? "",
  phone: user?.phone,
});

export const parseFormData = (data: UpdateFormData) => ({
  email: data.email.toLowerCase(),
  phone: (data.phone as PhoneInput).parsedPhone,
  name: data.name,
  last_name: data.lastName,
  second_last_name: data.secondLastName,
  rut: data.country === "cl" ? format(data.document) : data.document,
  document: data.country === "cl" ? format(data.document) : data.document,
  document_type: Number(data.documentType),
  role: data.role,
});
