import {
  FactorResultStatusEnum,
  FactorResultStatusList,
} from "domains/evaluations/models/Evaluations";
import { classes } from "apps/evaluator-admin/components/MemberCard/components/Factor/components/FactorResult/FactorResultStyles";

interface Props {
  result: {
    score?: number;
    status?: FactorResultStatusList;
  };
}

const FactorResult = ({ result }: Props) => (
  <div>
    {result?.score ? (
      <div className={classes.factorResultContainer}>
        <span className={classes.factorResultInformationContainer}>
          <p className={classes.factorResultTextBold}>Score:</p>
          <p className={classes.factorResultTextNormal}>
            {result?.status !== FactorResultStatusEnum.ERROR ? result?.score : "-"}
          </p>
        </span>
      </div>
    ) : (
      <p className={classes.factorResultTextNormal}>Sin información</p>
    )}
  </div>
);

export default FactorResult;
