import zod from "zod";

export enum ContractStatusEnum {
  TO_APPROVE_BY_LESSOR = "Aprobacion propietario",
  PROFILE_SENT = "Perfil enviado",
  READY_TO_WRITE = "Listo para redaccion",
  APPROVED_BY_LESSOR = "Aprobado por propietario",
  PAYMENT_BUTTON_SENT = "Boton de pago enviado",
  REJECTED = "Rechazado",
  DESISTED = "Desistido",
  GENERATE_PAYMENT_BUTTON = "Generar boton de pago",
  PAID = "Pagado",
  PROFILE_APPROVED_BY_LESSOR = "Perfil aprobado",
  PROFILE_REEVALUATION = "Re-evaluacion",
  PROFILE_REJECTED_BY_LESSOR = "Perfil rechazado",
  FINALIZED_CONTRACT = "Finalizado",
}

export const ContractStatusListEnum = [
  ContractStatusEnum.TO_APPROVE_BY_LESSOR,
  ContractStatusEnum.PROFILE_SENT,
  ContractStatusEnum.READY_TO_WRITE,
  ContractStatusEnum.APPROVED_BY_LESSOR,
  ContractStatusEnum.PAYMENT_BUTTON_SENT,
  ContractStatusEnum.REJECTED,
  ContractStatusEnum.DESISTED,
  ContractStatusEnum.GENERATE_PAYMENT_BUTTON,
  ContractStatusEnum.PAID,
  ContractStatusEnum.PROFILE_APPROVED_BY_LESSOR,
  ContractStatusEnum.PROFILE_REEVALUATION,
  ContractStatusEnum.PROFILE_REJECTED_BY_LESSOR,
  ContractStatusEnum.FINALIZED_CONTRACT,
] as const;

export const ContractStatusSchema = zod.enum(ContractStatusListEnum);
