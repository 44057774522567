import { FormProvider, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";

import ModalDialog from "@houm-com/ui/ModalDialog";

import useGetInsuranceCompanies from "domains/insuranceCompanies/hooks/useGetInsuranceCompanies";
import { ContractPreparation } from "domains/leaseApplication/models/LeaseApplication";
import Spinner from "Components/Shared/partials/Spinner";
import useReactHookForm from "hooks/useReactHookForm";
import { Locale } from "models/Countries";

import { initContractSchema } from "../../utils/validationSchema";
import InitContractForm from "../InitContractForm";
import { IContractInit } from "../../utils/types";
import ErrorsList from "./components/ErrorsList";
import { classes } from "./InitContractStyles";

interface Props {
  contractPreparation: ContractPreparation;
}

const InitContract = ({ contractPreparation }: Props) => {
  const { country } = useParams<{ country: Locale }>();
  const { insuranceCompanies, insuranceCompaniesError, insuranceCompaniesLoading } =
    useGetInsuranceCompanies(country);
  const { methods } = useReactHookForm<IContractInit>({
    formSchema: initContractSchema,
    formOptions: {
      defaultValues: {
        adultHabitants: contractPreparation.numberOfAdults.toString(),
        childHabitants: contractPreparation.numberOfMinors.toString(),
        selectedDate: contractPreparation.contractInitDate,
        tenantHasPets: contractPreparation.hasPet ? "yes" : "no",
      },
    },
  });

  // TODO: IMPLEMENT NEW ENDPOINT TO CREATE CONTRACT
  const onSubmit: SubmitHandler<IContractInit> = async (formData) => console.log(formData);

  if (insuranceCompaniesError)
    return <ErrorsList errors={[{ text: "Error cargando aseguradoras", id: 1 }]} />;

  if (insuranceCompaniesLoading) return <Spinner />;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalDialog.Content classNameContent={classes.modalDialogContent}>
          <InitContractForm insuranceCompanies={insuranceCompanies} />
        </ModalDialog.Content>
        <ModalDialog.Actions
          submitButtonLabel="Generar"
          submitButtonProps={{
            type: "submit",
          }}
        />
      </form>
    </FormProvider>
  );
};

export default InitContract;
