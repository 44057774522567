import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import { EvaluationStatus } from "domains/evaluations/models/Evaluations";
import service from "domains/evaluations/service";
import { useGetToken } from "hooks";
import { Locale } from "models/Countries";

interface IFormData {
  status: EvaluationStatus;
}

interface Props {
  evaluationId: string;
  country: Locale;
  onMutateSuccess?: () => void;
}

const useApproveEvaluation = ({ evaluationId, country, onMutateSuccess }: Props) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const query = useMutation(async (formData: IFormData) => {
    const authToken = await getToken();
    const response = await service.editEvaluation(evaluationId, country, formData, authToken);
    return response;
  });

  useEffect(() => {
    if (query.isSuccess) {
      queryClient.invalidateQueries("evaluation", { exact: false });
      alert.success({
        message: "La evaluación ha sido aprobada",
      });
      onMutateSuccess?.();
    }
  }, [query.isSuccess]);

  useEffect(() => {
    if (query.isError) {
      alert.error({
        message: "No se pudo aprobar la evaluación",
      });
    }
  }, [query.isError]);

  return query;
};

export default useApproveEvaluation;
