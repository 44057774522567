import * as yup from "yup";
import "yup-phone";
import { phoneValidator } from "utils/yupValidators/phone";

const rolLength = "Se requiere por lo menos un rol";
const mcLength = "Se requiere por lo menos una macrozona";
export const requiredText = "Obligatorio";
export const wrongType = "Formato incorrecto";
export const wrongEmail = "Ingrese un email válido";
export const wrongDoc =
  "El número de documento sólo puede contener números, guiones y puntos. Revisa si dejaste un espacio.";

export default yup.object({
  name: yup.string().required(requiredText).typeError(wrongType),
  last_name: yup.string().required(requiredText).typeError(wrongType),
  username: yup.string().required(requiredText).typeError(wrongType),
  phone: phoneValidator,
  photo: yup.mixed().required(requiredText),
  email: yup.string().email(wrongEmail).required(requiredText).typeError(wrongType),
  doc_type: yup.string().required(requiredText),
  doc_number: yup
    .string()
    .matches(/^[0-9.-]*$/, { message: wrongDoc })
    .required(requiredText),
  country: yup.string().required(requiredText),
  roles: yup.array().min(1, rolLength),
  contract_type: yup.string().required(requiredText),
  macrozones: yup.array().when("contract_type", {
    is: (contractType) => {
      const shouldValidate = contractType !== "B2B";
      return shouldValidate;
    },
    then: yup
      .array()
      .min(1, mcLength)
      .transform((macrozones) => macrozones.map((macrozone) => Number(macrozone.value))),
  }),
});
