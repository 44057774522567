import { useParams } from "react-router-dom";
import Button from "@houm-com/ui/Button";
import ConfirmDialog from "@houm-com/ui/ConfirmDialog";

import { classes } from "apps/evaluator-admin/components/ApproveCard/ApprovedCardStyles";
import useApproveEvaluation from "domains/evaluations/hooks/useApproveEvaluation";
import usePropertyPriceRequired from "domains/evaluations/hooks/usePropertyPriceRequired";
import { EvaluationStatusListEnum } from "domains/evaluations/models/Evaluations";
import { Locale } from "models/Countries";
import useModal from "hooks/useModal";

interface Props {
  propertyId: number;
  totalSalary: string;
}

const ApproveCard = ({ propertyId, totalSalary }: Props) => {
  const { evaluationId, country } = useParams<{ evaluationId: string; country: Locale }>();
  const { isLoading, getPropertyPrice, isError, isSuccess } = usePropertyPriceRequired({
    propertyId,
  });
  const { handleOpenModal: handleToggleModal, open } = useModal();
  const { handleOpenModal: onHandleToggleDisclaimer, open: openDisclaimer } = useModal();
  const { mutate, isLoading: isLoadingApprove } = useApproveEvaluation({
    evaluationId,
    country,
    onMutateSuccess: () => {
      if (open) handleToggleModal();
      if (openDisclaimer) onHandleToggleDisclaimer();
    },
  });

  if (isLoading) return null;

  const onApproveEvaluation = () => {
    const propertyPrice = getPropertyPrice();
    if (Number(totalSalary) < propertyPrice) handleToggleModal();
    else onHandleToggleDisclaimer();
  };

  const onSubmit = () => mutate({ status: EvaluationStatusListEnum.APPROVED });
  const onAcceptDisclaimer = () => {
    handleToggleModal();
    onHandleToggleDisclaimer();
  };

  return (
    <>
      {isError && (
        <p className={classes.approvedCardErrorText}>Error, no se pudo aprobar la evaluación</p>
      )}
      {isSuccess && (
        <div className={classes.approvedCardButtonContainer}>
          <Button size="md" variant="primary" type="button" onClick={onApproveEvaluation}>
            Aprobar evaluación
          </Button>
        </div>
      )}
      <ConfirmDialog
        title="Estás seguro de que quieres generar el contrato?"
        subtitle="Esta evaluación no cumple la regla de que las rentas sumen 3 veces el valor del arriendo"
        isOpen={open}
        onClose={handleToggleModal}
        onSubmit={onAcceptDisclaimer}
        submitButtonLabel="Sí, aprobar"
        submitButtonProps={{
          isLoading: isLoadingApprove,
        }}
      />
      <ConfirmDialog
        title="Estás seguro de que quieres aprobar esta evaluación?"
        subtitle="Una vez aprobada la evaluación no se podrán hacer modificaciones a la misma"
        isOpen={openDisclaimer}
        onClose={onHandleToggleDisclaimer}
        onSubmit={onSubmit}
        submitButtonLabel="Sí, aprobar"
        submitButtonProps={{
          isLoading: isLoadingApprove,
        }}
      />
    </>
  );
};

export default ApproveCard;
