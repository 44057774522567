import * as yup from "yup";
import { isNaN } from "lodash";

import { Locale } from "models/Countries";

import { CONTRACT_TYPES } from "./plan/PlanCard/utils/constants";

export const requiredText = "Obligatorio";
const invalidInteger = "El valor del canon de arriendo no puede tener decimales";
const invalidIntegerWarranty = "El valor de la garantía no puede tener decimales";
const invalidValue = "El valor ingresado debe ser mayor a 0";

const validateCommonExpenses = (typeMoney: string) =>
  typeMoney === "pesos"
    ? yup
        .number()
        .min(0)
        .integer(invalidInteger)
        .required(requiredText)
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
    : yup
        .number()
        .required(requiredText)
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable();

export const contractSchema = (country: Locale, typeMoney: string) =>
  yup.object({
    billingDocument: yup.string().required(requiredText),
    typeMoney: country === "cl" && yup.string().required(requiredText),
    hasHoumCleaning: yup.string().required(requiredText),
    canonAmount: yup
      .number()
      .required(requiredText)
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    commonExpenses: country !== "cl" && validateCommonExpenses(typeMoney),
  });

export const contractPlanSchema = (typeMoney: string, country: Locale) =>
  yup.object({
    planId: yup.number().typeError(requiredText).required(requiredText),
    tenantPlan: country === "cl" && yup.string().required(requiredText),
    signatureDate: yup.string().required(requiredText),
    firstPaymentDate: yup.string().nullable().required(requiredText),
    warranty:
      typeMoney === "pesos"
        ? yup
            .number()
            .typeError(requiredText)
            .integer(invalidIntegerWarranty)
            .required(requiredText)
        : yup.number().typeError(requiredText).required(requiredText),
    personWarranty: yup.string().required(requiredText),
    //amountPolicy: yup.number().typeError(requiredText).required(requiredText),
    readjust: yup.string().required(requiredText),
    automaticRenewal: yup.string().required(requiredText),
    commonExpensedIncluded: yup.string().required(requiredText),
    notification: yup.number().typeError(requiredText).required(requiredText),
    observations: yup.string(),
    houmer: yup.string().typeError(requiredText).required(requiredText),
    payDay: yup.number().typeError(requiredText).required(requiredText),
    planDiscount: yup.number().typeError(requiredText).required(requiredText),
    propertyFurnishing: country === "cl" && yup.string().required(requiredText),
    lesseeBrokerageDiscount: yup
      .number()
      .min(0, invalidValue)
      .typeError(requiredText)
      .required(requiredText),
    lessorBrokerageDiscount: yup
      .number()
      .min(0, invalidValue)
      .typeError(requiredText)
      .required(requiredText),
    insuranceCompany: yup
      .string()
      .typeError(requiredText)
      .test("insuranceCompany", requiredText, (value) => value !== "")
      .when(country, { is: "co", then: yup.string().required(requiredText) }),
    guaranteedMonths: yup
      .number()
      .typeError(requiredText)
      .when(country, { is: "co", then: yup.string().required(requiredText) }),
    contractType: country === "cl" && yup.string().required(),
    futurePlanId: yup.string().when("contractType", {
      is: (value: string) => country === "cl" && value === CONTRACT_TYPES.collection_service.value,
      then: yup.string().required(requiredText),
    }),
    externalContractInitDate: yup.string().when("contractType", {
      is: (value: string) => value === CONTRACT_TYPES.collection_service.value,
      then: yup
        .string()
        .nullable()
        .required(requiredText)
        .test(
          "isPreviousDate",
          "Debe ser antes de la fecha de inicio de deuda",
          (value, { parent }) => {
            const { signatureDate } = parent;
            if (!value || !signatureDate) return false;
            return value < signatureDate;
          }
        ),
    }),
  });

export const propertyFormSchema = yup.object({
  address: yup.string().required(requiredText),
  canon: yup.number().required(requiredText),
  executive: yup.object().nullable().required(requiredText),
  houmerId: yup.string().nullable().required(requiredText),
  id: yup.number().required(requiredText),
  max_people_quantity: yup.number().typeError(requiredText).required(requiredText),
  publishedDate: yup.string().required(requiredText),
  other_bans: yup.string().required(requiredText),
  pet_bans: yup.string().required(requiredText),
  warehouse_number: yup.string().required(requiredText),
  parking_number: yup.string().required(requiredText),
});

export const movementPlanSchema = yup.object({
  amount: yup.number().typeError(requiredText).required(requiredText),
  description: yup.string().required(requiredText),
  type_movement: yup.string().required(requiredText),
});

export const buttonGeneratorSchema = yup.object({
  //houmerId: yup.string().typeError(requiredText).required(requiredText),
  firstPaymentDate: yup.string().required(requiredText),
  payDay: yup.number().typeError(requiredText).required(requiredText),
});
