import { ControlledList } from "Components/Admin/UIComponents/ControlledForm";
import { CountryUpperCase } from "models/Countries";
import useGuard from "domains/auth/hooks/useGuard";
import { ContractModel } from "models/Contract";

import useContractDetailsStyles from "./ContractDetailsStyles";
import GeneralDetails from "../../../GeneralDetails";
import Label from "../../../Label";
import { countryCode } from "utils";

interface Props {
  contract: ContractModel | null;
  country: CountryUpperCase;
}

const ContractDetails = ({ contract, country }: Props) => {
  const classes = useContractDetailsStyles();
  const { guard } = useGuard();

  const isEnabled = guard.permissions.all(["create:contract_change_request:all"]);

  return (
    <>
      <GeneralDetails
        id={contract?.id}
        initDate={contract?.init_date}
        readjustDate={contract?.readjust_date}
        payDay={contract?.pay_day as number}
        firstPaymentDate={contract?.first_payment_date}
        labelClassName={classes.label}
        country={countryCode(country)}
      />
      {country === "Chile" && (
        <Label label="Reajuste:" labelClassName={classes.field}>
          <ControlledList
            label=""
            id="readjust"
            defaultValue={contract?.readjust ?? ""}
            options={[
              { value: "Anual", label: "Anual" },
              { value: "Semestral", label: "Semestral" },
              { value: "Trimestral", label: "Trimestral" },
              { value: "Sin Reajuste", label: "Sin Reajuste" },
            ]}
            classes={{
              select: classes.select,
            }}
            disableUnderline
            disabled={!isEnabled}
          />
        </Label>
      )}
    </>
  );
};

export default ContractDetails;
