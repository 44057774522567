import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid, Card, CardContent, CircularProgress } from "@material-ui/core";

import { createNewAppraiser, getMacrozones } from "context/controlTower/controlTowerActions";
import { ControlledAutocomplete } from "Components/Admin/UIComponents/ControlledForm";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import { NewAppraiserForm } from "context/controlTower/controlTowerTypes";
import { useDashboard } from "context/controlTower/controlTowerContext";
import { useFormNotifications, useGetToken } from "hooks";

import validationSchema, { requiredText } from "./validationSchema";
import useNewAppraiserStyles from "./useNewAppraiserStyles";
import ContractFields from "./Components/ContractFields";
import ContactFields from "./Components/ContactFields";
import NameFields from "./Components/NameFields";
import RoleFields from "./Components/RoleFields";
import PhotoField from "./Components/PhotoField";
import IdFields from "./Components/IdFields";

function AppraiserForm() {
  const { state, dispatch } = useDashboard();
  const classes = useNewAppraiserStyles();
  const { getToken } = useGetToken();
  const methods = useForm<NewAppraiserForm>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
  });

  const country = methods.watch("country") ?? "Chile";
  const contractType = methods.watch("contract_type");

  useEffect(() => {
    getToken().then(async (authToken) => {
      getMacrozones(authToken, country, dispatch);
    });
  }, [country]);

  const onSubmit: SubmitHandler<NewAppraiserForm> = (data) => {
    getToken().then((authToken) => createNewAppraiser(authToken, data, dispatch));
  };

  const { errors } = methods.formState;

  useFormNotifications<NewAppraiserForm>({ errors, requiredText });

  return (
    <FormProvider {...methods} key="DashboardProvider">
      <form key="newAppraiserForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <Card className={classes.propertyInformationCard}>
          <CardContent>
            <Grid container spacing={2} justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <NameFields />
                <ContactFields />
                <IdFields />
              </Grid>
              <Grid item xs={5}>
                <PhotoField />
                <RoleFields />
                <ContractFields />
              </Grid>
              {contractType !== "B2B" && (
                <Grid item xs={11}>
                  <ControlledAutocomplete
                    multiple
                    defaultValue={[]}
                    id="macrozones"
                    label="Macrozona"
                    options={state.macrozones}
                  />
                </Grid>
              )}
              <Grid item xs={11}>
                <CustomButton kind="main" active className={classes.submitButton} type="submit">
                  {state.loadingCreate ? (
                    <CircularProgress size={30} color="secondary" />
                  ) : (
                    "Crear Houmer"
                  )}
                </CustomButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
}

export default AppraiserForm;
