import { FactorProviderEnum, FactorResultStatusEnum } from "domains/evaluations/models/Evaluations";

export const FACTOR_TITLE = {
  [FactorProviderEnum.DATA_CREDITO]: "Data crédito",
  [FactorProviderEnum.AVLA]: "AVLA",
  [FactorProviderEnum.BURO]: "Buró de crédito",
};

export const FACTOR_STATUS = {
  [FactorResultStatusEnum.APPROVED]: "Aprobado",
  [FactorResultStatusEnum.ERROR]: "Error",
  [FactorResultStatusEnum.REJECTED]: "Rechazado",
};
