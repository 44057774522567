import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";

import { useFeatureManager } from "@houm-com/feature-manager";
import Button from "@houm-com/ui/Button";
import Grid from "@material-ui/core/Grid";

import ControlledDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledDatePicker";
import ControlledList from "Components/Admin/UIComponents/ControlledForm/ControlledList";
import ControlledOptions from "Components/Admin/UIComponents/ControlledForm/ControlledOptions";
import ControlledTextField from "Components/Admin/UIComponents/ControlledForm/ControlledTextField";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import ControlledNumber from "Components/Admin/UIComponents/ControlledForm/ControlledNumber";
import usePostInsuranceCompanyContract from "domains/insuranceCompanies/hooks/usePostInsuranceCompanyContract";
import { guaranteedMonthsOptionsCo } from "domains/insuranceCompanies/constants";
import useGetAppraisers from "domains/appraisers/hooks/useGetAppraisers";
import { IContractInsuranceCompany } from "models/InsuranceCompany";
import { ContractModel } from "models/Contract";
import { IPlan, TenantPlan } from "models/Plan";
import { IInsuranceCompany } from "services/insuranceService/types";
import { IParsePlan } from "hooks/usePlans/utils/types";
import { useFormNotifications } from "hooks";
import { countryCode as toCountryCode } from "utils";
import { TENANT_PLANS } from "constants/tenantPlans";

import { contractPlanSchema, requiredText } from "../../../../validationSchema";
import { renderMoneyType } from "../../../../utils";
import PlanDetails from "../../../PlanDetails";
import { CONTRACT_TYPES, READJUST_TRANSLATE } from "../../utils/constants";
import { ExternalCollection, PlanProps } from "../../utils/types";
import {
  getMaxExternalContractInitDate,
  parseAppraisersOptions,
  parseInsuranceCompanyOptions,
  parsePlanOptions,
} from "../../utils/utils";
import usePlanFormStyles from "./PlanFormStyles";
import usePostPlan from "../../hooks/usePostPlan";
import useSaveContractTenantPlan from "../../hooks/useSaveContractTenantPlan";
import useGetContractTenantPlans from "../../hooks/useGetContractTenantPlans";
import usePostExternalCollection from "../../hooks/usePostExternalCollection";
import useGetContractTypes from "../../hooks/useGetContractTypes";

interface Props {
  contract: ContractModel;
  plans: IParsePlan[];
  isDisabled?: boolean;
  brokerage?: boolean;
  rentalDetails?: { plan: Partial<IPlan>; readjust: string };
  insuranceCompany?: IContractInsuranceCompany;
  insuranceCompanies: IInsuranceCompany[];
  externalCollection: ExternalCollection;
  defaultContractTenantPlan: TenantPlan;
}

function PlanForm({
  contract,
  plans,
  isDisabled = false,
  brokerage = true,
  rentalDetails = null,
  insuranceCompany,
  insuranceCompanies,
  externalCollection,
  defaultContractTenantPlan,
}: Props) {
  const classes = usePlanFormStyles();
  const countryCode = toCountryCode(contract.property.country);
  const [selectedPlan, setSelectedPlan] = useState<IParsePlan | null>(null);
  const {
    data: externalCollectionPlanCategoryIds,
    status: externalCollectionPlanCategoryIdsStatus,
  } = useFeatureManager("external-collection-plan-categories");
  const externalDebtCollectionPlanCategoryIds =
    externalCollectionPlanCategoryIds?.content?.ids ?? [];
  const { postPlanAsync, submittingPlan } = usePostPlan();
  const { mutate: saveContractTypeMutation, submittingExternalCollection } =
    usePostExternalCollection(contract.id, countryCode);
  const { postInsuranceCompanyContract, submittingInsuranceCompanyContract } =
    usePostInsuranceCompanyContract();
  const { saveContractTenantPlanMutation, submittingTenantPlan } = useSaveContractTenantPlan(
    contract.id
  );
  const { appraisers, appraisersLoading } = useGetAppraisers();
  const { data: contractTenantPlansOptions = [], isLoading: isLoadingTenantPlans } =
    useGetContractTenantPlans(countryCode);
  const { data: contractTypesOptions, isLoading: isLoadingContractTypes } =
    useGetContractTypes(countryCode);

  const isLoading =
    appraisersLoading ||
    isLoadingTenantPlans ||
    isLoadingContractTypes ||
    externalCollectionPlanCategoryIdsStatus === "loading";

  const isSubmitting =
    submittingTenantPlan ||
    submittingPlan ||
    submittingInsuranceCompanyContract ||
    submittingExternalCollection;

  const methods = useForm<PlanProps>({
    resolver: yupResolver(contractPlanSchema(contract.type_money, countryCode)),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  const [transferFlag, planIdFlag, tenantPlan, contractType, signatureDate] = methods.watch([
    "isTransfer",
    "planId",
    "tenantPlan",
    "contractType",
    "signatureDate",
  ]);
  useFormNotifications<PlanProps>({ errors, requiredText });

  const planDefaultValue = contract?.plan?.id ?? rentalDetails?.plan?.id ?? "";
  const futurePlanDefaultValue = externalCollection?.exclusivityConditions?.futurePlan ?? "";
  const externalContractInitDateDefaultValue =
    externalCollection?.exclusivityConditions?.externalContractInitDate ?? "";
  const hasInsurance = selectedPlan?.category?.hasInsurance;
  const selectedPlanHasManagementCL = countryCode === "cl" && selectedPlan?.category?.hasManagement;
  const isSmartLeasingOptionSelectedCL =
    countryCode === "cl" && tenantPlan === TENANT_PLANS.SMART_LEASING;
  const isPremiumPlan = ["premium", "premium_plus"].includes(selectedPlan?.category?.key);
  const isCollectionServiceSelected =
    (contractType ?? externalCollection?.contractType) === CONTRACT_TYPES.collection_service.value;
  const disablePersonWarrantyOptions =
    countryCode === "cl" &&
    (!selectedPlanHasManagementCL || isSmartLeasingOptionSelectedCL || isCollectionServiceSelected);

  const readjustDefaultValue = () => {
    if (contract?.type_money && contract.type_money === "uf")
      return READJUST_TRANSLATE["no_readjust"];
    if (contract?.readjust === "Sin Reajuste" || !contract?.readjust)
      return READJUST_TRANSLATE[rentalDetails?.readjust];
    return contract?.readjust;
  };

  const personWarrantyDefaultValue = () => {
    if (contract?.person_warranty) return contract.person_warranty;
    if (!isDisabled) return "Houm";
    return "";
  };

  const changeHoumer = () => {
    if (transferFlag === "true" || !brokerage || contract.is_transfer) {
      methods.setValue("houmer", "none");
    } else {
      methods.setValue("houmer", contract?.houmer ? contract?.houmer?.id : "");
    }
  };

  const planOptions = parsePlanOptions(
    isCollectionServiceSelected
      ? plans.filter((plan) => externalDebtCollectionPlanCategoryIds.includes(plan.category?.id))
      : plans.filter((plan) => !externalDebtCollectionPlanCategoryIds.includes(plan.category?.id))
  );

  const plansWithManagementOptions = parsePlanOptions(
    plans.filter(
      (plan) =>
        plan.category?.hasManagement &&
        !externalDebtCollectionPlanCategoryIds.includes(plan.category?.id)
    )
  );

  useEffect(() => {
    if (planIdFlag) {
      const selectedPlan = plans.find((plan) => plan.id === Number(planIdFlag));
      setSelectedPlan(selectedPlan);
      methods.setValue("duration", selectedPlan?.monthsDuration);
    }
  }, [planIdFlag]);

  useEffect(() => {
    changeHoumer();
  }, [transferFlag]);

  useEffect(() => {
    changeHoumer();
  }, [brokerage]);

  useEffect(() => {
    if (rentalDetails?.plan) {
      const selectedPlan = plans.find((p) => p.id === rentalDetails.plan?.id);
      setSelectedPlan(selectedPlan);
    }
    if (contract?.plan) {
      const selectedPlan = plans.find((p) => p.id === contract.plan?.id);
      setSelectedPlan(selectedPlan);
    }
  }, [contract, rentalDetails]);

  useEffect(() => {
    if (!isCollectionServiceSelected && !isDisabled) {
      methods.setValue("personWarranty", selectedPlanHasManagementCL ? "Houm" : "Lessor");
    }
  }, [
    selectedPlanHasManagementCL,
    isSmartLeasingOptionSelectedCL,
    isCollectionServiceSelected,
    isDisabled,
  ]);

  useEffect(() => {
    methods.setValue(
      "tenantPlan",
      selectedPlanHasManagementCL && !isCollectionServiceSelected
        ? defaultContractTenantPlan
        : TENANT_PLANS.NO_PLAN
    );
  }, [selectedPlanHasManagementCL, isCollectionServiceSelected]);

  useEffect(() => {
    if (isPremiumPlan) {
      methods.setValue("propertyFurnishing", "unfurnished");
    }
  }, [isPremiumPlan]);

  useEffect(() => {
    if (!isLoading && contractType && !isCollectionServiceSelected) {
      methods.setValue("futurePlanId", "");
      if (externalDebtCollectionPlanCategoryIds.includes(selectedPlan?.category?.id)) {
        methods.setValue("planId", "");
      }
    }
  }, [isCollectionServiceSelected, selectedPlan, isLoading, contractType]);

  useEffect(() => {
    if (!isLoading && isCollectionServiceSelected && !isDisabled) {
      methods.setValue("planId", String(planOptions[0]?.value));
      methods.setValue("isTransfer", "true");
      methods.setValue("personWarranty", "Lessor");
      methods.setValue("readjust", "Sin Reajuste");
      methods.setValue("automaticRenewal", "false");
      methods.setValue("commonExpensedIncluded", "false");
    }
  }, [isCollectionServiceSelected, isLoading, planDefaultValue, isDisabled]);

  const onSubmit: SubmitHandler<PlanProps> = (data) => {
    (async () => {
      const currency = renderMoneyType(
        contract?.type_money,
        toCountryCode(contract?.property?.country)
      );
      const validationText = `${currency} ${data.warranty}`;
      const warningText =
        countryCode === "cl"
          ? `El valor de la garantía será ${validationText} y el tipo de moneda es ${currency}. Una vez seleccionado el plan, deberá actualizar los borradores de mandato y contrato, para confirmar, escriba <b>"${validationText}"</b>`
          : `El valor de la garantía será ${validationText} y el tipo de moneda es ${currency}. Una vez seleccionado el plan, deberá actualizar los borradores de mandato y contrato.`;
      Swal.fire({
        title: "Atención",
        html: warningText,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Guardar",
        ...(countryCode === "cl" && { input: "text" }),
      }).then(async (result) => {
        postInsuranceCompanyContract({
          insuranceCompanyId:
            countryCode === "co" ? data.insuranceCompany : String(insuranceCompany?.id),
          contractId: String(contract.id),
          guaranteedMonths:
            countryCode === "co" ? data.guaranteedMonths : selectedPlan.guaranteedMonths,
          objectId: insuranceCompany?.objectId,
        });
        await postPlanAsync({
          data,
          result,
          country: contract.property.country,
          validationText,
          brokerage,
          contractId: contract.id,
        });
        saveContractTenantPlanMutation(data.tenantPlan);
        saveContractTypeMutation(data);
      });
    })();
  };

  const checkIfPlanExist = () => plans.find((p) => p.id === planDefaultValue);

  return (
    <Loading loading={isLoading}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContent}>
          <Grid item container spacing={2} alignItems="flex-start">
            <Grid item container xs={6} md={4}>
              {countryCode === "cl" && (
                <Grid item xs={12}>
                  <ControlledList
                    label="Selecciona el tipo de contrato"
                    id="contractType"
                    defaultValue={externalCollection?.contractType ?? ""}
                    disableUnderline
                    options={contractTypesOptions}
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDisabled}
                  />
                </Grid>
              )}
              {hasInsurance && (
                <Grid item xs={12}>
                  <ControlledList
                    label="Empresa aseguradora"
                    id="insuranceCompany"
                    defaultValue={insuranceCompany?.id}
                    placeholder="Sin información"
                    disableUnderline
                    options={parseInsuranceCompanyOptions(insuranceCompanies)}
                    disabled={countryCode !== "co" || isDisabled}
                  />
                </Grid>
              )}
              {countryCode === "co" && hasInsurance && (
                <Grid item xs={12}>
                  <ControlledList
                    label="Meses asegurados"
                    id="guaranteedMonths"
                    defaultValue={insuranceCompany?.guaranteedMonths}
                    disableUnderline
                    placeholder="Sin información"
                    options={guaranteedMonthsOptionsCo.map((months) => ({
                      value: months,
                      label: String(months),
                    }))}
                    disabled={isDisabled}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <ControlledTextField
                  label="Duración del contrato"
                  id="duration"
                  defaultValue={contract?.plan ? contract?.plan?.months_duration : ""}
                  disabled
                  textFieldProps={{
                    InputProps: {
                      classes: {
                        root: classes.textInput,
                      },
                      endAdornment: "meses",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <PlanDetails planDetails={selectedPlan} />
              </Grid>
              <Grid item xs={12}>
                <ControlledNumber
                  label="Descuento corretaje propietario (%)"
                  id="lessorBrokerageDiscount"
                  defaultValue={contract?.lessor_brokerage_discount ?? 0}
                  textFieldProps={{
                    placeholder: "Ingresar descuento, si no tiene ingresar 0",
                    InputProps: {
                      classes: {
                        root: classes.textInput,
                      },
                      endAdornment: "%",
                    },
                  }}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledNumber
                  label="Descuento corretaje arrendatario (%)"
                  id="lesseeBrokerageDiscount"
                  defaultValue={contract?.lessee_brokerage_discount ?? 0}
                  textFieldProps={{
                    placeholder: "Ingresar descuento, si no tiene ingresar 0",
                    InputProps: {
                      classes: {
                        root: classes.textInput,
                      },
                      endAdornment: "%",
                    },
                  }}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledNumber
                  label="Descuento comisión administración (%)"
                  id="planDiscount"
                  defaultValue={contract?.plan_discount ?? 0}
                  textFieldProps={{
                    placeholder: "Ingresar descuento, si no tiene ingresar 0",
                    InputProps: {
                      classes: {
                        root: classes.textInput,
                      },
                      endAdornment: "%",
                    },
                  }}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
            <Grid item container xs={8} md={4}>
              <Grid item xs={12}>
                <ControlledList
                  label="Selecciona el plan"
                  id="planId"
                  defaultValue={checkIfPlanExist() ? planDefaultValue : ""}
                  disableUnderline
                  options={planOptions}
                  classes={{
                    select: classes.select,
                  }}
                  disabled={isDisabled}
                />
              </Grid>
              {selectedPlanHasManagementCL && (
                <Grid item xs={12}>
                  <ControlledList
                    label="Selecciona el plan arrendatario"
                    id="tenantPlan"
                    defaultValue={defaultContractTenantPlan}
                    disableUnderline
                    options={contractTenantPlansOptions}
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDisabled || isCollectionServiceSelected}
                  />
                </Grid>
              )}
              <Grid item container xs={6}>
                {isCollectionServiceSelected && (
                  <Grid item xs={12}>
                    <ControlledDatePicker
                      label="Fecha inicio de contrato de arriendo externo"
                      id="externalContractInitDate"
                      defaultValue={externalContractInitDateDefaultValue}
                      type="date"
                      textFieldProps={{
                        InputProps: {
                          inputProps: {
                            max: getMaxExternalContractInitDate(signatureDate),
                          },
                          classes: {
                            root: classes.textInput,
                          },
                        },
                      }}
                      disabled={isDisabled}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <ControlledDatePicker
                    id="signatureDate"
                    label={
                      isCollectionServiceSelected
                        ? "Fecha inicio de deuda"
                        : "Fecha inicio contrato"
                    }
                    defaultValue={contract?.init_date ? contract?.init_date : ""}
                    type="date"
                    textFieldProps={{
                      InputProps: {
                        classes: {
                          root: classes.textInput,
                        },
                      },
                    }}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledDatePicker
                    label="Fecha primer pago"
                    id="firstPaymentDate"
                    defaultValue={contract?.first_payment_date ? contract?.first_payment_date : ""}
                    type="date"
                    textFieldProps={{
                      InputProps: {
                        classes: {
                          root: classes.textInput,
                        },
                      },
                    }}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledNumber
                    label="Monto garantía"
                    id="warranty"
                    defaultValue={contract?.warranty ?? ""}
                    textFieldProps={{
                      placeholder: "Ingresar monto garantía, ej: 5",
                      InputProps: {
                        classes: {
                          root: classes.textInput,
                        },
                      },
                    }}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledOptions
                    id="personWarranty"
                    defaultValue={personWarrantyDefaultValue()}
                    options={[
                      {
                        value: "Houm",
                        label: "Retiene Houm",
                      },
                      {
                        value: "Lessor",
                        label: "Retiene propietario",
                      },
                    ]}
                    disabled={isDisabled || disablePersonWarrantyOptions}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={12}>
                  <ControlledList
                    label="Mostrador"
                    id="houmer"
                    defaultValue=""
                    options={parseAppraisersOptions(appraisers)}
                    disableUnderline
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDisabled || methods.watch("isTransfer") === "true" || !brokerage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledList
                    label="Reajuste"
                    id="readjust"
                    defaultValue={readjustDefaultValue()}
                    disableUnderline
                    options={[
                      { value: "Anual", label: "Anual" },
                      {
                        value: "Semestral",
                        label: "Semestral",
                      },
                      {
                        value: "Sin Reajuste",
                        label: "Sin Reajuste",
                      },
                      {
                        value: "Trimestral",
                        label: "Trimestral",
                      },
                    ]}
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDisabled || isCollectionServiceSelected}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledOptions
                    label="Renovación automática"
                    id="automaticRenewal"
                    defaultValue={`${!!contract.automatic_renewal}`}
                    options={[
                      { value: "true", label: "Si" },
                      { value: "false", label: "No" },
                    ]}
                    column={false}
                    disabled={isDisabled || isCollectionServiceSelected}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledOptions
                    label="Incluye gastos comunes"
                    id="commonExpensedIncluded"
                    defaultValue={`${!!contract.rent_include_common_expenses}`}
                    options={[
                      { value: "true", label: "Si" },
                      { value: "false", label: "No" },
                    ]}
                    column={false}
                    disabled={isDisabled || isCollectionServiceSelected}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={6} md={4} direction="column">
              <ControlledTextField
                label="Día de pago"
                id="payDay"
                defaultValue={contract?.pay_day ?? ""}
                textFieldProps={{
                  placeholder: "Ingresar número, ej: 5",
                  type: "number",
                  InputProps: {
                    classes: {
                      root: classes.textInput,
                    },
                  },
                }}
                disabled={isDisabled}
              />
              <Grid item xs={12}>
                <ControlledOptions
                  label="¿Es traspaso?"
                  id="isTransfer"
                  defaultValue={`${!!contract?.is_transfer}`}
                  options={[
                    { value: "true", label: "Si" },
                    { value: "false", label: "No" },
                  ]}
                  column={false}
                  disabled={isDisabled || isCollectionServiceSelected}
                />
              </Grid>
              {isCollectionServiceSelected && (
                <Grid item xs={12}>
                  <ControlledList
                    label="Selecciona el plan a futuro"
                    id="futurePlanId"
                    disableUnderline
                    defaultValue={futurePlanDefaultValue}
                    options={plansWithManagementOptions}
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDisabled}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <ControlledTextField
                  label="Plazo notificación término contrato"
                  id="notification"
                  defaultValue={contract?.end_notice_period ?? ""}
                  textFieldProps={{
                    placeholder: "Ingresar plazo, ej: 5",
                    type: "number",
                    InputProps: {
                      classes: {
                        root: classes.textInput,
                      },
                    },
                  }}
                  disabled={isDisabled}
                />
              </Grid>
              {countryCode === "cl" && (
                <ControlledOptions
                  id="propertyFurnishing"
                  label="Tipo de inmueble"
                  defaultValue={contract.property_furnishing}
                  disabled={isDisabled || isPremiumPlan}
                  options={[
                    {
                      value: "furnished",
                      label: "Amoblado",
                    },
                    {
                      value: "unfurnished",
                      label: "No amoblado",
                    },
                  ]}
                />
              )}
              <Grid item xs={12}>
                <div className={classes.ctaContent}>
                  <Button
                    variant="primary"
                    size="md"
                    isLoading={isSubmitting}
                    disabled={isDisabled}>
                    Guardar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Loading>
  );
}

export default PlanForm;
