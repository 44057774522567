import { BaseOption } from "@houm-com/ui/FieldSelect/FieldSelect";
import { TenantPlan } from "models/Plan";

export const TENANT_PLANS: { [key: string]: TenantPlan } = {
  NO_PLAN: "no_plan",
  SMART_LEASING: "smart_leasing",
};

export const TENANT_PLAN_OPTIONS: { NO_PLAN: BaseOption; SMART_LEASING: BaseOption } = {
  NO_PLAN: {
    value: "no_plan",
    label: "Sin plan",
  },
  SMART_LEASING: {
    value: "smart_leasing",
    label: "Arriendo inteligente",
  },
};
