import { Grid, Typography } from "@material-ui/core";

import useGetCurrentContractTenantPlan from "hooks/useTenantPlan";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { TENANT_PLANS } from "constants/tenantPlans";
import { Locale } from "models/Countries";

import useWarranty from "../../hooks/useWarranty";
import WarrantyInfo from "../WarrantyInfo";
import Discounts from "../Discounts";

interface Props {
  contractId: number;
  countryCode: Locale;
  landlordId: number;
  tenantId: number | null;
  propertyId: number;
  subscriptionId: number;
}

const Warranty = ({
  contractId,
  countryCode,
  landlordId,
  tenantId,
  propertyId,
  subscriptionId,
}: Props) => {
  const { data, isLoading: isLoadingWarranty, isError } = useWarranty(contractId, countryCode);
  const { data: tenantPlan, isLoading: isLoadingTenantPlan } = useGetCurrentContractTenantPlan(
    contractId,
    countryCode
  );
  const isSmartLeasing = tenantPlan === TENANT_PLANS.SMART_LEASING;
  const isLoading = isLoadingWarranty || isLoadingTenantPlan;

  return (
    <Loading loading={isLoading}>
      <>
        {isError && (
          <Grid item xs={12}>
            <Typography>Hubo un error al obtener la garantía</Typography>
          </Grid>
        )}
        {data && (
          <>
            <Grid item xs={12}>
              <WarrantyInfo
                originalAmount={data.originalAmount}
                remainingAmount={data.remainingAmount}
                currencyCode={data.currency}
                retainer={data.retainer}
                receiver={data.receiver}
                agreedDate={data.agreedDate}
                contractId={contractId}
                countryCode={countryCode}
                status={data.status}
                landlordId={landlordId}
                tenantId={tenantId}
                propertyId={propertyId}
                subscriptionId={subscriptionId}
                isSmartLeasing={isSmartLeasing}
              />
            </Grid>
            <Grid item xs={12}>
              <Discounts
                contractId={contractId}
                country={countryCode}
                discounts={data.discountsBreakdown}
                status={data.status}
                currencyCode={data.currency}
              />
            </Grid>
          </>
        )}
      </>
    </Loading>
  );
};

export default Warranty;
