import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "@houm-com/ui/Button";

import FieldSwitchSelector from "@houm-com/ui/FieldSwitchSelector";
import FieldPhoneNumber from "@houm-com/ui/FieldPhoneNumber";
import FieldSelect from "@houm-com/ui/FieldSelect";
import FieldInput from "@houm-com/ui/FieldInput";

import { useDocumentTypeList, useFormNotifications } from "hooks";
import { CIVIL_STATUSES } from "context/closureFormContext/constants";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { ContractModel, IPerson } from "models/Contract";
import { Locale } from "models/Countries";
import useEditContract from "hooks/useEditContract";
import { localeToCountry } from "utils/countryCode";

import { codebtorFormSchema, requiredText } from "./utils/validationSchema";
import { ContractPersonType, IPersonData } from "./utils/types";
import classes from "./CodebtorFormEditStyles";
import { parseCodebtorForm } from "./utils/parsers";
import useCodebtor from "./hooks/useCodebtor";

interface Props {
  contract: ContractModel;
  country: Locale;
  person: IPerson;
  type: ContractPersonType;
  typeAction?: "edit" | "creation";
}

function CodebtorFormEdit({ contract, person, country, type, typeAction = "edit" }: Props) {
  const { documentTypeList, loading: loadingDocumentType } = useDocumentTypeList(
    localeToCountry[country]
  );
  const { mutate, isLoading } = useCodebtor({
    contractId: contract.id,
    type,
    typeAction,
  });
  const { mutate: mutateEditContract, isLoading: isLoadingEditContract } = useEditContract({
    contractId: contract?.id,
  });

  const methods = useForm<IPersonData>({
    resolver: yupResolver(codebtorFormSchema(documentTypeList, country, type)),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<IPersonData>({ errors, requiredText });

  const tributaryType = methods.watch("tributaryType");

  const onSubmit: SubmitHandler<IPersonData> = (data) => {
    (async () => {
      if (isLoading || isLoadingEditContract) return;
      const formData = parseCodebtorForm({
        codebtorForm: data,
        type,
        country,
        personId: person?.id,
        propertyId: contract.property.id,
      });
      if (
        type === "owner" &&
        formData?.tributary_type === "corporative" &&
        contract.billing_document === "Boleta"
      )
        mutateEditContract({
          billing_document: "Factura",
        });
      mutate(formData);
    })();
  };

  const getPersonDocumentType = () => {
    if (person?.document_type) {
      return person?.document_type.id;
    } else if (person?.documentType) {
      return person.documentType;
    }
    return "";
  };

  return (
    <Loading loading={loadingDocumentType}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContent}>
          <FieldInput
            id="name"
            label="Nombre completo"
            required
            errorMessage={errors.name?.message}
            defaultValue={person?.name ?? ""}
            form="novalidateform"
            {...methods.register("name")}
          />
          {country === "mx" && (
            <FieldInput
              id="rfcNumber"
              label="RFC"
              required
              errorMessage={errors.rfcNumber?.message}
              defaultValue={person?.rfc_number ?? ""}
              form="novalidateform"
              {...methods.register("rfcNumber")}
            />
          )}
          {documentTypeList && (
            <FieldSelect
              label="Tipo de Documento"
              id="document_type"
              required
              errorMessage={errors.document_type?.message}
              defaultValue={getPersonDocumentType()}
              placeholder="Selecciona un documento"
              options={documentTypeList}
              form="novalidateform"
              {...methods.register("document_type")}
            />
          )}
          <FieldInput
            id="document"
            label={`${country === "cl" ? "RUT" : "Documento"}`}
            required
            defaultValue={person?.document ?? ""}
            form="novalidateform"
            errorMessage={errors.document?.message}
            {...methods.register("document")}
          />
          <FieldPhoneNumber
            label="Teléfono"
            id="phone"
            defaultValue={person?.phone}
            required
            form="novalidateform"
            onlyCountries={["CL", "CO", "MX", "US"]}
            errorMessage={errors.phone?.message}
            {...methods.register("phone")}
          />
          <FieldInput
            label="Correo electrónico"
            id="email"
            defaultValue={person?.email ?? ""}
            required
            form="novalidateform"
            errorMessage={errors.email?.message}
            {...methods.register("email")}
          />
          <FieldInput
            label="Domicilio"
            id="address"
            defaultValue={person?.address ?? ""}
            required
            form="novalidateform"
            errorMessage={errors.address?.message}
            {...methods.register("address")}
          />
          {((!tributaryType && person?.tributary_type === "natural") ||
            tributaryType !== "yes") && (
            <FieldSelect
              id="civil_status"
              label="Estado civil"
              required
              errorMessage={errors.civil_status?.message}
              defaultValue={person?.civil_status}
              placeholder="Selecciona un estado civil"
              options={CIVIL_STATUSES}
              form="novalidateform"
              {...methods.register("civil_status")}
            />
          )}
          {type === "owner" && (
            <FieldSwitchSelector
              id="tributaryType"
              options={[
                {
                  label: "Si",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ]}
              labelPosition="top"
              label="¿Propietario es empresa?"
              required
              errorMessage={errors.tributaryType?.message}
              classNameSwitch="mr-[19px]"
              defaultValue={person?.tributary_type === "natural" ? "no" : "yes"}
              {...methods.register("tributaryType")}
            />
          )}
          {(type === "tenant" || type === "codebtor") && (
            <>
              <FieldInput
                label="Nacionalidad"
                id="nationality"
                defaultValue={person?.nationality ?? ""}
                required
                form="novalidateform"
                errorMessage={errors.nationality?.message}
                {...methods.register("nationality")}
              />
              <FieldInput
                label="Ocupación"
                id="occupation"
                defaultValue={person?.occupation ?? ""}
                required
                form="novalidateform"
                errorMessage={errors.occupation?.message}
                {...methods.register("occupation")}
              />
              <FieldInput
                label="Empleador"
                id="employer"
                defaultValue={person?.employer ?? ""}
                required
                form="novalidateform"
                errorMessage={errors.employer?.message}
                {...methods.register("employer")}
              />
              <FieldInput
                label="Liquidaciones promedio"
                id="salaryAverage"
                defaultValue={person?.salaryAverage ?? ""}
                required
                type="number"
                min="0"
                step="1"
                errorMessage={errors.salaryAverage?.message}
                form="novalidateform"
                {...methods.register("salaryAverage")}
              />
            </>
          )}
          <div className={classes.ctaContent}>
            <Button
              variant="primary"
              size="md"
              type="submit"
              isLoading={isLoading || isLoadingEditContract}>
              Confirmar datos
            </Button>
          </div>
        </form>
      </FormProvider>
    </Loading>
  );
}

export default CodebtorFormEdit;
