import currency from "@houm-com/formats/currency";
import Button from "@houm-com/ui/Button";

import {
  EvaluationFactors,
  EvaluationStatus,
  UserProfile,
} from "domains/evaluations/models/Evaluations";
import Factor from "apps/evaluator-admin/components/MemberCard/components/Factor";
import ProtectComponent from "apps/evaluator-admin/components/ProtectComponent";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { Permissions as P } from "domains/evaluations/utils/constants";
import { localeToCurrency } from "utils/countryCurrency";
import { Locale } from "models/Countries";
import useModal from "hooks/useModal";

import { classes } from "./MemberInfoStyles";
import EditUserModal from "../EditUserModal";

interface Props {
  member: UserProfile;
  salaryAverage: number;
  country: Locale;
  evaluationStatus: EvaluationStatus;
  factors: EvaluationFactors[];
}

const MemberInfo = ({ member, salaryAverage, country, evaluationStatus, factors }: Props) => {
  const { handleOpenModal, open } = useModal();

  return (
    <div className={classes.container}>
      <div className={classes.gap4Container}>
        <AdminCard
          title={`${member.name} ${member.lastName}`}
          className={classes.container}
          withSpace={false}>
          <div className={classes.gap2Container}>
            <div>
              <span className={classes.informationContainer}>
                <p className={classes.textBold}>Id:</p>
                <p className={classes.textNormal}>{member.id ?? "Sin información"}</p>
              </span>
              <span className={classes.informationContainer}>
                <p className={classes.textBold}>Documento:</p>
                <p className={classes.textNormal}>{member.document ?? "Sin información"}</p>
              </span>
              <span className={classes.informationContainer}>
                <p className={classes.textBold}>E-mail:</p>
                <p className={classes.textNormal}>{member.email ?? "Sin información"}</p>
              </span>
              <span className={classes.informationContainer}>
                <p className={classes.textBold}>Teléfono:</p>
                <p className={classes.textNormal}>{member.phone ?? "Sin información"}</p>
              </span>
            </div>
            <ProtectComponent
              evaluationStatus={evaluationStatus}
              requiredPermissions={[P.UPDATE_USER]}>
              <div className={classes.buttonContainer}>
                <Button size="sm" variant="tertiary" onClick={handleOpenModal}>
                  Editar
                </Button>
              </div>
            </ProtectComponent>
          </div>
        </AdminCard>
        {factors.map((factor) => (
          <Factor key={factor.id} factor={factor} />
        ))}
        {salaryAverage > 0 && (
          <AdminCard title="Liquidación" withSpace={false}>
            <div className={classes.gap2Container}>
              <span className={classes.informationContainer}>
                <p className={classes.textBold}>Total:</p>
                <p className={classes.textNormal}>
                  {currency(salaryAverage, localeToCurrency[country], {
                    maximumFractionDigits: 0,
                  })}
                </p>
              </span>
            </div>
          </AdminCard>
        )}
      </div>
      <EditUserModal handleOpenModal={handleOpenModal} open={open} memberId={member.id} />
    </div>
  );
};

export default MemberInfo;
