import { Link } from "react-router-dom";

import currency from "@houm-com/formats/currency";

import { PropertyLeaseApplication } from "domains/leaseApplication/models/LeaseApplication";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";

import { classes } from "./PropertyCardStyles";

interface Props {
  property: PropertyLeaseApplication;
}

const PropertyCard = ({ property }: Props) => (
  <AdminCard
    title="Propiedad"
    showTitleBackground={true}
    withSpace={false}
    className={classes.propertyCardRoot}>
    <div>
      <Link to={`/admin/properties/${property.uid}`}>
        {`[ID: ${property.id}] ${property.address}`}
      </Link>
      <p>{`Monto Arriendo: ${currency(property.price, property.currency)} `}</p>
    </div>
  </AdminCard>
);

export default PropertyCard;
