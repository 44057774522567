import { Grid } from "@material-ui/core";

import DesignCardContent from "Components/Admin/administrator/subscriptions/components/DesignCardContent/DesignCardContent";
import { ContractModel } from "models/Contract";
import { countryCode } from "utils";

import ContractButtons from "./components/ContractButtons";
import PaymentDetails from "./components/PaymentDetails";
import PlanDetails from "./components/PlanDetails";
import { currencyParse } from "../../utils/utils";
import GeneralDetails from "../GeneralDetails";

interface Props {
  contract: ContractModel;
  handleIsEdit: () => void;
}

const DisplayCard = ({ contract, handleIsEdit }: Props) => (
  <>
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <DesignCardContent title="Detalles generales">
          <GeneralDetails
            id={contract?.id}
            initDate={contract?.init_date}
            readjustDate={contract?.readjust_date}
            payDay={contract?.pay_day as number}
            firstPaymentDate={contract?.first_payment_date}
            country={countryCode(contract?.property.country)}
          />
        </DesignCardContent>
      </Grid>
      <Grid item xs={12} md={4}>
        <DesignCardContent title="Detalles de pago">
          <PaymentDetails
            amount={contract?.amount}
            typeMoney={currencyParse[contract?.property?.country][contract?.type_money]}
            countryCode={countryCode(contract?.property.country)}
          />
        </DesignCardContent>
      </Grid>
      <Grid item xs={12} md={4}>
        <DesignCardContent title="Detalles de plan">
          <PlanDetails
            adminCommission={contract?.plan?.admin_commission}
            planName={contract?.plan?.name}
            planType={contract?.plan?.type}
            readjust={contract?.readjust}
            planDiscount={contract?.plan_discount as string}
            warranty={contract?.warranty}
            personWarranty={contract?.person_warranty}
            currency={currencyParse[contract?.property?.country][contract?.type_money]}
          />
        </DesignCardContent>
      </Grid>
    </Grid>
    <ContractButtons contractId={contract?.id} handleIsEdit={handleIsEdit} />
  </>
);

export default DisplayCard;
