import zod from "zod";
import { es } from "date-fns/locale";
import { format, formatDistance } from "date-fns";

export enum SwitchBrokerageTypes {
  NO_BROKERAGE = "no_brokerage",
  BROKERAGE = "brokerage",
}

export enum SwitchBrokerageLabels {
  NO_BROKERAGE = "Sin corretaje",
  BROKERAGE = "Con corretaje",
}

export enum CommentTypeParsedEnum {
  document_request = "Pedir documentación",
  simple_comment = "Comentario",
}

export enum IncomeTypeDicEnum {
  SALARY = "salary",
  OTHER = "other",
}

export enum StatusMemberEnum {
  COMMERCIALLY_APPROVED = "commercially_approved",
  DESISTED = "desisted",
  PENDING_DOCS = "pending_docs",
  READY_FOR_EVALUATION = "ready_for_evaluation",
  NEW_DOCS = "new_docs",
  APPROVED = "approved",
  REJECTED = "rejected",
  START = "start",
  NO_DOCS = "no_docs",
}

export enum StatusReservationEnum {
  PAID = "PAID",
  PENDING = "PENDING",
  ERROR = "ERROR",
}

export enum StatusReservationParsedEnum {
  PAID = "Pagada",
  PENDING = "No pagada",
  ERROR = "Error",
}

export enum RoleEnum {
  MAIN = "main",
  CODEBTOR = "codebtor",
  COMPLEMENT = "rent_complement",
}

export const EvaluationMemberStatusEnum = [
  StatusMemberEnum.COMMERCIALLY_APPROVED,
  StatusMemberEnum.DESISTED,
  StatusMemberEnum.PENDING_DOCS,
  StatusMemberEnum.READY_FOR_EVALUATION,
  StatusMemberEnum.NEW_DOCS,
  StatusMemberEnum.APPROVED,
  StatusMemberEnum.REJECTED,
  StatusMemberEnum.START,
  StatusMemberEnum.NO_DOCS,
] as const;

export enum EvaluationStatusListEnum {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  INCOMPLETE = "incomplete",
  PARTIALLY_APPROVED = "partially_approved",
}

export const EvaluationStatusEnum = [
  EvaluationStatusListEnum.PENDING,
  EvaluationStatusListEnum.APPROVED,
  EvaluationStatusListEnum.REJECTED,
  EvaluationStatusListEnum.CANCELLED,
  EvaluationStatusListEnum.INCOMPLETE,
  EvaluationStatusListEnum.PARTIALLY_APPROVED,
] as const;
const MemberRolesEnum = [RoleEnum.MAIN, RoleEnum.CODEBTOR, RoleEnum.COMPLEMENT] as const;
export const ReservationStatusEnum = [
  StatusReservationEnum.PAID,
  StatusReservationEnum.PENDING,
  StatusReservationEnum.ERROR,
] as const;
export const EvaluationVisaEnum = ["definitive", "student", "temporary", ""] as const;
export const CommentTypeEnum = ["document_request", "simple_comment"] as const;

export const EvaluationMemberStatusSchema = zod.enum(EvaluationMemberStatusEnum);
export const EvaluationStatusSchema = zod.enum(EvaluationStatusEnum);
export const MemberRolesSchema = zod.enum(MemberRolesEnum);
export const ReservationStatusSchema = zod.enum(ReservationStatusEnum);
export const VisaTypeSchema = zod.enum(EvaluationVisaEnum);
export const CommentTypeSchema = zod.enum(CommentTypeEnum);

export const StatisticsResponseSchema = zod
  .object({
    start: zod.number(),
    no_docs: zod.number(),
    pending_docs: zod.number(),
    ready_for_evaluation: zod.number(),
    new_docs: zod.number(),
    commercially_approved: zod.number(),
    rejected: zod.number(),
    desisted: zod.number(),
  })
  .transform(
    ({
      no_docs,
      pending_docs,
      ready_for_evaluation,
      new_docs,
      commercially_approved,
      ...rest
    }) => ({
      noDocs: no_docs,
      pendingDocs: pending_docs,
      readyForEvaluation: ready_for_evaluation,
      newDocs: new_docs,
      commerciallyApproved: commercially_approved,
      ...rest,
    })
  );

export const UserFullNameSchema = zod
  .object({
    full_name: zod.string(),
  })
  .transform(({ full_name }) => ({
    fullName: full_name,
  }));

export const EvaluationsListSchema = zod
  .object({
    applicant_uid: zod.string().nullable(),
    created_at: zod.string().transform((value) =>
      format(new Date(value), "Pp", {
        locale: es,
      })
    ),
    rental_evaluation_id: zod.union([zod.string(), zod.number()]),
    rental_evaluation_member_id: zod.union([zod.string(), zod.number()]),
    rental_evaluation_member_user_id: zod.union([zod.string(), zod.number()]),
    property_id: zod.number(),
    property_uid: zod.string(),
    status: EvaluationMemberStatusSchema,
    updated_at: zod.string().transform(
      (value) =>
        `${format(new Date(value), "Pp", {
          locale: es,
        })} (${formatDistance(new Date(value), new Date(), { addSuffix: true, locale: es })})`
    ),
    main_member_name: zod.string(),
    member_name: zod.string(),
    main_member_user_id: zod.union([zod.string(), zod.number()]),
    reservation_status: ReservationStatusSchema,
  })
  .transform(
    ({
      rental_evaluation_id,
      applicant_uid,
      created_at,
      property_id,
      updated_at,
      main_member_name,
      member_name,
      main_member_user_id,
      rental_evaluation_member_id,
      reservation_status,
      property_uid,
      ...rest
    }) => ({
      id: rental_evaluation_member_id,
      applicantId: applicant_uid,
      createdAt: created_at,
      propertyId: property_id,
      updatedAt: updated_at,
      mainMemberName: main_member_name,
      memberName: member_name,
      mainMemberUserId: main_member_user_id,
      rentalEvaluationId: rental_evaluation_id,
      reservationStatus: reservation_status,
      propertyUid: property_uid,
      ...rest,
    })
  );

export const EvaluationsListResponseSchema = zod
  .object({
    results: zod.array(EvaluationsListSchema),
    count: zod.number(),
  })
  .transform((data) => ({
    evaluations: data.results,
    totalCount: data.count,
  }));

export const EvaluationsFilterSchema = zod.object({
  size: zod.number(),
  page: zod.number(),
  evaluationId: zod.number().nullable(),
  status: EvaluationStatusSchema,
  memberStatus: EvaluationMemberStatusSchema,
  applicantName: zod.string().nullable(),
  propertyId: zod.number().nullable(),
  sort: zod.string().nullable(),
  order: zod.string().nullable(),
});

export const IncomeTypeEnum = ["salary", "other"] as const;
export const IncomeTypeSchema = zod.enum(IncomeTypeEnum);
export const IdentificationSchema = zod
  .object({
    document_identifier: zod.string().nullable(),
    document_number: zod.string().nullable(),
    document_identifier_external_id: zod.number().nullable(),
  })
  .nullable()
  .transform(({ document_identifier, document_number, document_identifier_external_id }) => ({
    documentIdentifier: document_identifier,
    documentNumber: document_number,
    documentIdentifierExternalId: document_identifier_external_id,
  }));

export const IncomeSchema = zod.array(
  zod
    .object({
      amount: zod.string().nullable(),
      money_type: zod.string().nullable(),
      month: zod.number().nullable(),
      type: IncomeTypeSchema,
      description: zod.string().nullable(),
    })
    .transform(({ money_type, ...rest }) => ({
      moneyType: money_type,
      ...rest,
    }))
);

const AddressSchema = zod
  .object({
    // city: zod.string().nullable(),
    // street: zod.string().nullable(),
    full_address: zod.string().nullable(),
  })
  .transform(({ full_address }) => ({ fullAddress: full_address }));

export const MemberPersonalInformationSchema = zod
  .object({
    address: zod.nullable(AddressSchema),
    comment: zod.string().nullable(),
    // created_at: zod.string(),
    employer: zod.string().nullable(),
    employment_contract_type: zod.string(),
    id: zod.string(),
    identification: zod.nullable(IdentificationSchema),
    identifications: zod.array(IdentificationSchema),
    income_source_type: zod.string(),
    // updated_at: zod.string(),
    name: zod.string().nullable(),
    nationality: zod.string().nullable(),
    occupation: zod.string().nullable(),
    incomes: IncomeSchema,
    visa_type: zod.nullable(VisaTypeSchema),
  })
  .transform(({ employment_contract_type, income_source_type, visa_type, ...rest }) => ({
    employmentContractType: employment_contract_type,
    incomeSourceType: income_source_type,
    visaType: visa_type,
    ...rest,
  }));

export const UserProfileSchema = zod
  .object({
    id: zod.number(),
    name: zod.string(),
    last_name: zod.string(),
    second_last_name: zod.string(),
    phone: zod.string().nullable(),
    email: zod.string(),
    document: zod.string().nullable(),
    document_type: zod.number().nullable(),
    country: zod.string().nullable(),
    created_at: zod.string(),
  })
  .transform(({ last_name, created_at, document_type, second_last_name, ...rest }) => ({
    lastName: last_name,
    createdAt: created_at,
    documentType: document_type,
    secondLastName: second_last_name,
    ...rest,
  }));

export const EvaluationCommentSchema = zod
  .object({
    comment: zod.string().nullable(),
    comment_type: zod.nullable(CommentTypeSchema),
    commenter_name: zod.string().nullable(),
    commenter_id: zod.number(),
    created_at: zod.string(),
    id: zod.string(),
    updated_at: zod.string().nullable(),
  })
  .transform(({ comment_type, created_at, commenter_id, updated_at, commenter_name, ...rest }) => ({
    commentType: comment_type,
    createdAt: created_at,
    commenterId: commenter_id,
    updatedAt: updated_at,
    commenterName: commenter_name,
    ...rest,
  }));

export const EvaluationDocumentSchema = zod
  .object({
    content_type: zod.string().nullable(),
    created_at: zod.string(),
    document_type: zod.string().nullable(),
    filename: zod.string().nullable(),
    id: zod.string().nullable(),
    updated_at: zod.string().nullable(),
    url: zod.string().nullable(),
  })
  .transform(({ content_type, created_at, document_type, updated_at, ...rest }) => ({
    contentType: content_type,
    createdAt: created_at,
    documentType: document_type,
    updatedAt: updated_at,
    ...rest,
  }));

export enum FactorProviderEnum {
  DATA_CREDITO = "datacredito",
  BURO = "buro",
  AVLA = "avla",
}
export const FactorProviderEnumList = [
  FactorProviderEnum.DATA_CREDITO,
  FactorProviderEnum.BURO,
  FactorProviderEnum.AVLA,
] as const;
export const FactorProvider = zod.enum(FactorProviderEnumList);

export enum FactorStatusEnum {
  RESOLVED = "resolved",
  PENDING = "pending",
  ONGOING = "ongoing",
}
export const FactorStatusEnumList = [
  FactorStatusEnum.RESOLVED,
  FactorStatusEnum.PENDING,
  FactorStatusEnum.ONGOING,
] as const;
export const FactorStatus = zod.enum(FactorStatusEnumList);

export enum FactorResultStatusEnum {
  ERROR = "error",
  APPROVED = "approved",
  REJECTED = "rejected",
  PENDING = "pending",
}
export const FactorResultStatusEnumList = [
  FactorResultStatusEnum.APPROVED,
  FactorResultStatusEnum.ERROR,
  FactorResultStatusEnum.REJECTED,
  FactorResultStatusEnum.PENDING,
] as const;
export const FactorResultStatus = zod.enum(FactorResultStatusEnumList);

const RawInput = zod
  .object({
    dni: zod.string(),
    document_type: zod.string(),
    last_name: zod.string(),
  })
  .nullable()
  .transform(({ document_type, last_name, ...rest }) => ({
    documentType: document_type,
    lastName: last_name,
    ...rest,
  }));

export const EvaluationFactorsSchema = zod
  .object({
    created_at: zod.string(),
    evaluation_member_id: zod.string(),
    events: zod.array(zod.any()),
    id: zod.string(),
    provider_name: FactorProvider,
    raw_input: zod.nullable(RawInput),
    raw_output: zod
      .object({
        message: zod.string(),
        score: zod.number(),
        status: zod.string(),
      })
      .nullable(),
    result: zod
      .object({
        score: zod.number().optional(),
        status: zod.nullable(FactorResultStatus).optional(),
      })
      .nullable(),
    status: zod.nullable(FactorStatus),
    updated_at: zod.string(),
  })
  .transform(
    ({
      created_at,
      evaluation_member_id,
      provider_name,
      raw_input,
      raw_output,
      updated_at,
      ...rest
    }) => ({
      createdAt: created_at,
      evaluationMemberId: evaluation_member_id,
      providerName: provider_name,
      rawInput: raw_input,
      rawOutput: raw_output,
      updatedAt: updated_at,
      ...rest,
    })
  );

export const EvaluationMemberSchema = zod
  .object({
    comments: zod.array(EvaluationCommentSchema),
    documents: zod.array(EvaluationDocumentSchema),
    external_id: zod.number().nullable(),
    factors: zod.array(EvaluationFactorsSchema),
    incomes_avg: zod.string(),
    member_id: zod.string(),
    role: MemberRolesSchema,
    status: EvaluationMemberStatusSchema,
    person_information: MemberPersonalInformationSchema,
    user_data: UserProfileSchema,
  })
  .transform(({ external_id, member_id, person_information, user_data, incomes_avg, ...rest }) => ({
    externalId: external_id,
    memberId: member_id,
    personInformation: person_information,
    userData: user_data,
    incomesAvg: incomes_avg,
    ...rest,
  }));

export const EvaluationSchema = zod
  .object({
    applicant_id: zod.string().nullable(),
    brokerage: zod.boolean(),
    created_at: zod.string(),
    id: zod.string(),
    incomes_sum: zod.string().nullable(),
    members: zod.array(EvaluationMemberSchema),
    property_id: zod.number().nullable(),
    property_uid: zod.string().nullable(),
    status: EvaluationStatusSchema,
    updated_at: zod.string().nullable(),
    //TODO ADD COUNTRY
  })
  .transform(
    ({
      applicant_id,
      created_at,
      property_id,
      property_uid,
      updated_at,
      incomes_sum,
      members,
      ...rest
    }) => ({
      applicantId: applicant_id,
      createdAt: created_at,
      propertyId: property_id,
      updatedAt: updated_at,
      propertyUid: property_uid,
      salariesSum: incomes_sum,
      members: members.sort((a, b) => {
        if (a.role === RoleEnum.MAIN) return -1;
        if (b.role === RoleEnum.MAIN) return 1;
        return 0;
      }),
      ...rest,
    })
  );

export const ApplicantSchema = zod
  .object({
    external_id: zod.number(),
    member_id: zod.number(),
    role: MemberRolesSchema,
    user_data: UserFullNameSchema,
  })
  .transform(({ external_id, member_id, user_data, ...rest }) => ({
    externalId: external_id,
    memberId: member_id,
    user: user_data,
    ...rest,
  }));

export type EvaluationsList = zod.infer<typeof EvaluationsListResponseSchema>;
export type EvaluationsFilter = zod.infer<typeof EvaluationsFilterSchema>;
export type EvaluationMemberStatus = zod.infer<typeof EvaluationMemberStatusSchema>;
export type EvaluationStatus = zod.infer<typeof EvaluationStatusSchema>;
export type Statistics = zod.infer<typeof StatisticsResponseSchema>;
export type Evaluations = zod.infer<typeof EvaluationsListSchema>;
export type UserProfile = zod.infer<typeof UserProfileSchema>;
export type Applicant = zod.infer<typeof ApplicantSchema>;
export type Evaluation = zod.infer<typeof EvaluationSchema>;
export type EvaluationMember = zod.infer<typeof EvaluationMemberSchema>;
export type EvaluationComment = zod.infer<typeof EvaluationCommentSchema>;
export type EvaluationDocument = zod.infer<typeof EvaluationDocumentSchema>;
export type MemberPersonalInformation = zod.infer<typeof MemberPersonalInformationSchema>;
export type MemberRoles = zod.infer<typeof MemberRolesSchema>;
export type Incomes = zod.infer<typeof IncomeSchema>;
export type VisaType = zod.infer<typeof VisaTypeSchema>;
export type IncomeType = zod.infer<typeof IncomeTypeSchema>;
export type EvaluationFactors = zod.infer<typeof EvaluationFactorsSchema>;
export type FactorResultStatusList = zod.infer<typeof FactorResultStatus>;
