import { useMutation } from "react-query";

import contractService from "services/contractService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

import { parseExternalColectionRequestBody } from "../utils/utils";
import { PlanProps } from "../utils/types";

const usePostExternalCollection = (contractId: number, country: Locale) => {
  const { getToken } = useGetToken();

  const { mutate, isLoading: submittingExternalCollection } = useMutation(
    async (data: PlanProps) => {
      const requestBody = parseExternalColectionRequestBody(data, country, contractId);
      const token = await getToken();
      await contractService.postExternalCollection(contractId, token, requestBody);
    },
    {
      onError: () => {
        alert.error({
          message: "Error al crear la cobranza externa",
          disclaimer: "No se pudo crear la cobranza externa, contactarse con soporte",
        });
      },
    }
  );
  return { mutate, submittingExternalCollection };
};

export default usePostExternalCollection;
