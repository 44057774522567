import * as yup from "yup";
import { phoneValidator } from "utils/yupValidators/phone";

export const requiredText = "Obligatorio";

interface IDocumentTypeList {
  value: number;
  label: string;
  regex: string;
}

const bannedDocumentListChile = ["11111111", "1.111.111-1", "11.111.111-1", "111111111"];

export const userFormSchema = (documentTypeList: IDocumentTypeList[]) =>
  yup
    .object({
      email: yup.string().email().required(requiredText),
      phone: phoneValidator,
      name: yup.string().required(requiredText),
      lastName: yup.string().required(requiredText),
      secondLastName: yup.string().required(requiredText),
      document: yup.string().required(requiredText),
      documentType: yup.string().required(requiredText),
    })
    .test("validationDocument", null, (obj) => {
      if (!obj.documentType) {
        return new yup.ValidationError("Obligatorio", null, "document_type", "typeError");
      }
      const regexValue = documentTypeList.filter(
        (doc) => doc.value === parseInt(obj.documentType)
      )[0];
      const regex = new RegExp(regexValue.regex);
      if (parseInt(obj.documentType) === 1 && bannedDocumentListChile.includes(obj.document)) {
        return new yup.ValidationError(
          "No es posible usar un RUT genérico",
          null,
          "document",
          "typeError"
        );
      }
      if (regex.test(obj.document.toUpperCase())) {
        return true;
      }
      return new yup.ValidationError("Documento no válido", null, "document", "typeError");
    });
