/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import Swal from "sweetalert2";
import { PhoneInput } from "@houm-com/ui/FieldPhoneNumber/utils/types";
import {
  SET_PROPERTY,
  REFETCH_PROPERTY_INFO,
  SET_ADDRESS,
  SET_LOCATION_OPTIONS,
  SET_USER,
  SET_USER_MODAL,
  SET_CREATED_PROPERTY,
  SET_SUCCESS_MODAL,
  SET_MODAL_MESSAGE,
  SET_IS_SHORTER_FORM,
  SET_HOME_CHECKER_DETAILS,
  SET_PROPERTY_INSPECTION_REPORTS_STATE,
  REFETCH_INSPECTION_REPORTS,
  REFETCH_INSPECTION_REPORT,
  SET_PROPERTY_INSPECTION_REPORT_STATE,
  SET_INSPECTION_REPORTS_CURRENT_VIEW,
  SET_CURRENT_INSPECTION_REPORT_SPACE_FILTER,
  InspectionReportsInfoState,
  InspectionReportInfoState,
  InspectionReportsCurrentViews,
  Action,
  Dispatch,
  ModalMessage,
} from "context/propertyContext/propertyTypes";
import propertyService, {
  parseGetPropertyInspectionReports,
  parseShowPropertyInspectionReportRequest,
} from "services/propertyService";
import { countryCode, neighborhoodOptions, communeOptions, parseProperty } from "utils";
import { formatAddress } from "utils/createProperty";
import { parseGetHomeCheckerDetailResponse, parseHomeCheckerDetailSendData } from "./utils";
import { ModifyPropertyForm, CreatePropertyForm, IHomeCheckerDetails } from "models/createProperty";
import { Comment, PropertyData } from "models/Property";
import { SET_LOADING } from "Components/Admin/administrator/properties/context/propertiesTypes";
import leadService from "services/leadService";
import { UserFormType } from "models/User";
import { InspectionReportUpdateData, UpdateHCDetailsBody } from "services/propertyService/types";
import photoService from "services/photoService";

export const setProperty = (property: PropertyData): Action => ({
  type: SET_PROPERTY,
  property,
});

export const refetchPropertyInfo = (): Action => ({
  type: REFETCH_PROPERTY_INFO,
});

const setLoading = (loading: boolean): Action => ({
  type: SET_LOADING,
  loading,
});

export const setSuccessModal = (openSuccessModal: boolean): Action => ({
  type: SET_SUCCESS_MODAL,
  openSuccessModal,
});

export const setUserModal = (openUserModal: boolean): Action => ({
  type: SET_USER_MODAL,
  openUserModal,
});
export const setIsShorterForm = (isShorterForm: boolean): Action => ({
  type: SET_IS_SHORTER_FORM,
  isShorterForm,
});

export const setModalMessage = (modalMessage: ModalMessage): Action => ({
  type: SET_MODAL_MESSAGE,
  modalMessage,
});

export const setPropertyInspectionReportsState = (
  inspectionReportsInfo: InspectionReportsInfoState
): Action => ({
  type: SET_PROPERTY_INSPECTION_REPORTS_STATE,
  inspectionReportsInfo,
});

export const refetchPropertyInspectionReports = (): Action => ({
  type: REFETCH_INSPECTION_REPORTS,
});

export const setPropertyInspectionReportState = (
  inspectionReportInfo: InspectionReportInfoState
): Action => ({
  type: SET_PROPERTY_INSPECTION_REPORT_STATE,
  inspectionReportInfo,
});

export const refetchPropertyInspectionReport = (): Action => ({
  type: REFETCH_INSPECTION_REPORT,
});

export const setInspectionReportsCurrentView = (view: InspectionReportsCurrentViews): Action => ({
  type: SET_INSPECTION_REPORTS_CURRENT_VIEW,
  view,
});

export const setInspectionReportCurrentSpaceFilter = (spaceName: string): Action => ({
  type: SET_CURRENT_INSPECTION_REPORT_SPACE_FILTER,
  spaceName,
});

export const getLocationOptions = async (country, dispatch, authToken) => {
  const cc = countryCode(country);

  const results = await propertyService.getLocations(cc, authToken);
  const regions = await results.regions;

  const neighborhoods = neighborhoodOptions(regions);

  const communes = communeOptions(regions);

  dispatch({ type: SET_LOCATION_OPTIONS, neighborhoods, communes });
};

export const createNeighborhood = async (address, dispatch, authToken) => {
  if (typeof address === "object" && address !== null) {
    let parsedAddress = {} as {
      neighborhood: string;
      commune: string;
      region: string;
      country: string;
      latitude: number;
      longitude: number;
      prevSearch: string;
    };
    if (address.parsed) {
      parsedAddress = address;
    } else {
      // TODO: this can be removed after the API /locations/address/ is establed and we remove google suggestions
      parsedAddress = formatAddress(address);
    }
    dispatch({ type: SET_ADDRESS, address: parsedAddress });

    if (
      parsedAddress.neighborhood &&
      parsedAddress.commune &&
      parsedAddress.region &&
      parsedAddress.country &&
      parsedAddress.latitude &&
      parsedAddress.longitude &&
      parsedAddress.prevSearch
    ) {
      try {
        return await propertyService.getOrCreateLocation(parsedAddress, authToken);
      } catch (err) {
        console.error(err);
      }
    }
  }
};

export const modifyProperty = async (
  data: ModifyPropertyForm,
  property: PropertyData,
  dispatch: Dispatch,
  authToken: string
) => {
  const newProperty = parseProperty(data, property);
  try {
    dispatch(setLoading(true));

    await propertyService.updateProperty(property.id, newProperty, authToken);

    dispatch(setLoading(false));
    Swal.fire({
      type: "success",
      title: "Propiedad modificada con éxito",
    });

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  } catch (err) {
    dispatch(setLoading(false));

    Swal.fire({
      type: "error",
      title: "Error!",
      text: err as string,
    });
  }
};

export const createProperty = async (
  data: CreatePropertyForm,
  dispatch: Dispatch,
  authToken: string
) => {
  try {
    dispatch(setLoading(true));
    const newProperty = parseProperty(data);

    const { data: resProperty } = await propertyService.createProperty(newProperty, authToken);

    dispatch({ type: SET_CREATED_PROPERTY, property: resProperty });
  } catch (err) {
    dispatch(setLoading(false));

    Swal.fire({
      type: "error",
      title: "Error!",
      text: err as string,
    });
  }
};

export const setNewUser = async (user: UserFormType, token: string, dispatch: Dispatch) => {
  try {
    const defaultValues = {
      documentType: "1",
      pipedrivestage: 194,
      nombrestage: "COMPLETAR_INFO_1",
    };

    const newUser = {
      ...user,
      phone: (user.phone_number as PhoneInput).parsedPhone?.replace(/ /g, ""),
      company: user?.company_suggester_object?.id,
      ...defaultValues,
    };
    delete newUser.phone_number;
    delete newUser.company_suggester_object;

    const res = await leadService.postUser(newUser, token);

    if (res.data?.redirect || res.status === 409) throw new Error("Usuario ya existe");

    dispatch({ type: SET_USER, user: { id: res?.data?.id, ...user } });
    Swal.fire({
      title: "Usuario creado y asignado con éxito",
      timer: 3000,
      type: "success",
    });
  } catch (err) {
    Swal.fire("No se puedo crear el propietario", err as string, "error");
  }
};

const setHomeCheckerDetails = (homeCheckerDetails): Action => ({
  type: SET_HOME_CHECKER_DETAILS,
  homeCheckerDetails,
});

export const getHomeCheckerDetails = async (
  propertyId: string,
  dispatch: Dispatch,
  token: string
) => {
  dispatch(setLoading(true));

  try {
    const response = await propertyService.homeCheckerDetails(propertyId, token);
    if (response) {
      const homeCheckerDetailsResponse = response.data;
      const homeCheckerDetails = parseGetHomeCheckerDetailResponse(homeCheckerDetailsResponse);
      dispatch(setHomeCheckerDetails(homeCheckerDetails));
    }
  } catch (e) {
    dispatch(setLoading(false));
    throw e;
  }
};

export const updateHomeCheckerDetails = async (
  propertyId: number,
  data: IHomeCheckerDetails,
  dispatch: Dispatch,
  token: string
) => {
  dispatch(setLoading(true));
  const sendData = parseHomeCheckerDetailSendData(data);

  try {
    const updated = await propertyService.updateHomeCheckerDetails(propertyId, sendData, token);
    if (updated) {
      dispatch(setLoading(false));
      await Swal.fire({
        type: "success",
        text: "Cambios guardados!",
      });
      window.location.reload();
    } else {
      dispatch(setLoading(false));
      await Swal.fire({
        type: "error",
        text: "No se pudieron realizar los cambios",
      });
    }
  } catch (e) {
    dispatch(setLoading(false));
    throw e;
  }
};

export const updateKeysInfo = async (
  propertyUid: string,
  keysInfo: string,
  token: string,
  dispatch: Dispatch
) => {
  try {
    dispatch(setModalMessage({ isOpen: true, title: "Actualizando información..." }));
    await propertyService.update_keys({ keys_info: keysInfo }, propertyUid, token);
    dispatch(
      setModalMessage({
        isOpen: true,
        title: "Información actualizada con éxito",
      })
    );
    return true;
  } catch (e) {
    dispatch(
      setModalMessage({
        isOpen: true,
        title: "Ha ocurrido un error al obtener los datos de la propiedad",
      })
    );
  }
};

export const getPropertyComments = async (
  propertyUid: string,
  authToken: string
): Promise<Comment[]> => {
  try {
    const response = await propertyService.getPropertyComments(propertyUid, authToken);
    const propertyComments = response.data as Comment[];
    return propertyComments;
  } catch (err) {
    await Swal.fire({
      type: "error",
      text: "No se pudieron obtener los comentarios de la propiedad",
    });
    console.error(err);
    throw err;
  }
};

export const createPropertyComment = async (data: Comment, authToken: string): Promise<boolean> => {
  try {
    await propertyService.createPropertyComment(data, authToken);
    await Swal.fire({
      type: "success",
      text: "Comentario publicado!",
    });
    return true;
  } catch (err) {
    await Swal.fire({
      type: "error",
      text: "No se pudo publicar comentario",
    });
    console.error(err);
    return false;
  }
};

export const getPropertyInspectionReports = async (
  pageSize: number,
  currentPage: number,
  propertyId: number,
  mappedFilters,
  dispatch: Dispatch,
  authToken: string
): Promise<void> => {
  try {
    dispatch(setPropertyInspectionReportsState({ loading: true }));

    const response = await propertyService.indexPropertyInspectionReportsRequest(
      pageSize,
      currentPage,
      propertyId,
      mappedFilters,
      authToken
    );
    const inspectionReports = parseGetPropertyInspectionReports(response?.data?.results);
    dispatch(
      setPropertyInspectionReportsState({
        loading: false,
        count: response?.data?.count,
        results: inspectionReports,
      })
    );
  } catch (err) {
    dispatch(setPropertyInspectionReportsState({ loading: false }));
    await Swal.fire({
      type: "error",
      title: "Ops, algo salió mal",
      text: "No se pudo obtener información de las actas de ésta propiedad",
    });
    console.error(err);
  }
};

export const getPropertyInspectionReport = async (
  id: number | "last_inspection_report",
  propertyId: number,
  dispatch: Dispatch,
  authToken: string
): Promise<void> => {
  try {
    dispatch(setPropertyInspectionReportState({ loading: true }));

    const response = await propertyService.showPropertyInspectionReportRequest(
      id,
      propertyId,
      authToken
    );
    const inspectionReport = parseShowPropertyInspectionReportRequest(response?.data);
    dispatch(
      setPropertyInspectionReportState({
        loading: false,
        data: inspectionReport,
      })
    );
  } catch (err) {
    dispatch(setPropertyInspectionReportState({ loading: false }));
    await Swal.fire({
      type: "error",
      title: "Ops, algo salió mal",
      text: "No se pudo obtener información de ésta acta",
    });
    console.error(err);
  }
};

export const updatePropertyInspectionReport = async (
  id: number,
  propertyId: number,
  data: InspectionReportUpdateData,
  dispatch: Dispatch,
  authToken: string
): Promise<void> => {
  try {
    await propertyService.updatePropertyInspectionReportRequest(id, propertyId, data, authToken);
    await Swal.fire({
      type: "success",
      text: "Acta actualizada con éxito",
    });
    dispatch(refetchPropertyInspectionReport());
  } catch (err) {
    await Swal.fire({
      type: "error",
      title: "Ops, algo salió mal",
      text: "No se pudo actualizar esta acta",
    });
    throw err;
  }
};

export const validatePropertyForPublish = (property: PropertyData): boolean => {
  const propertyYearBuilt = property?.year_built;
  const projectHandoverDate = property?.sales_details?.first_available_handover_date;
  const isProject = property?.user?.role === "real_estate_developer";

  const isValidForPublish = (isProject && !!projectHandoverDate) || !!propertyYearBuilt;

  const invalidProjectForPublishError = "El proyecto no cuenta con 'Fecha de entrega'";
  const invalidPropertyForPublish = "La propiedad no cuenta con campo 'Año de construcción'";
  if (!isValidForPublish) {
    Swal.fire({
      type: "error",
      title: "No se ha podido publicar la propiedad",
      text: `${
        isProject ? invalidProjectForPublishError : invalidPropertyForPublish
      }. Debe detallarlo para poder publicar`,
    });
  }
  return isValidForPublish;
};

export const updatePropertyPhotosOrder = async (
  propertyUid: string,
  sortedPhotoArray,
  dispatch: Dispatch,
  authToken: string
) => {
  try {
    await photoService.order(propertyUid, sortedPhotoArray, authToken);
    Swal.fire({
      type: "success",
      text: "El orden de las fotos ha sido actualizado correctamente",
    });
    dispatch(refetchPropertyInfo());
  } catch (err) {
    Swal.fire({
      type: "error",
      title: "Ops! Algo salió mal",
      text: "No se pudo actualizar el orden de las fotos. Por favor intenténlo nuevamente",
    });
    console.error(err);
  }
};

export const getProperty = async (uid: string, authToken: string, dispatch: Dispatch) => {
  try {
    const res = await propertyService.get(uid, authToken);

    dispatch(setProperty(res.data));
  } catch (err) {
    dispatch(
      setModalMessage({
        isOpen: true,
        title: "Ha ocurrido un error al obtener los datos de la propiedad",
      })
    );
  }
};

export const updateHCDetails = async (
  body: UpdateHCDetailsBody,
  id: number,
  dispatch: Dispatch,
  authToken: string,
  saleMode?: boolean
) => {
  try {
    dispatch(setModalMessage({ isOpen: true, title: "Actualizando información..." }));

    await propertyService.updateHCinfo(id, body, { sale: saleMode }, authToken);
    dispatch(
      setModalMessage({
        isOpen: true,
        title: "Información actualizada con éxito",
      })
    );
    return true;
  } catch (err) {
    console.error({ err });
    dispatch(
      setModalMessage({
        isOpen: true,
        title: "Ha ocurrido un error al actualizar los datos",
      })
    );
  }
};
