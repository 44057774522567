import { format } from "date-fns";
import currency from "@houm-com/formats/currency";

import { Box, Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { IWarrantyReceiver, IWarrantyRetainer, IWarrantyStatus } from "models/Warranty";
import { CurrencyCode } from "models/Currencies";
import { Locale } from "models/Countries";
import useModal from "hooks/useModal";

import ConfirmWarrantyLiquidation from "../modals/ConfirmWarrantyLiquidation";
import ChangeWarrantyPersonModal from "../modals/ChangeWarrantyPersonModal";
import ChangeWarrantyHolder from "../modals/ChangeWarrantyHolder";
import { receiverParser, retainerParser } from "./utils/parsers";
import InfoItemGeneralValue from "../InfoItemGeneralValue";
import PaymentDateModal from "../modals/PaymentDateModal";
import WarrantyInfoStyles from "./WarrantyInfoStyles";
import WarrantyStatus from "../WarrantyStatus";
import InfoItemLabel from "../InfoItemLabel";
import BankAccount from "../BankAccount";
import EmptyValue from "../EmptyValue";
import EditButton from "../EditButton";
import { getResolveWarrantyLabel, isResolveWarrantyDisabled } from "./utils/utils";

interface Props {
  originalAmount: number;
  remainingAmount: number;
  currencyCode: CurrencyCode;
  retainer: IWarrantyRetainer;
  receiver: IWarrantyReceiver | null;
  agreedDate: Date | null;
  contractId: number;
  countryCode: Locale;
  status: IWarrantyStatus;
  landlordId: number;
  tenantId: number | null;
  propertyId: number;
  subscriptionId: number;
  isSmartLeasing: boolean;
}

const WarrantyInfo = ({
  originalAmount,
  remainingAmount,
  currencyCode,
  retainer,
  receiver,
  agreedDate,
  contractId,
  countryCode,
  status,
  landlordId,
  tenantId,
  propertyId,
  subscriptionId,
  isSmartLeasing,
}: Props) => {
  const { handleOpenModal, open } = useModal();
  const { handleOpenModal: handleOpenModalConfirmation, open: openModalConfirmation } = useModal();
  const { handleOpenModal: handleOpenModalHolder, open: openHolderWarranty } = useModal();
  const { handleOpenModal: handleOpenModalPaymentDate, open: openPaymentDate } = useModal();
  const classes = WarrantyInfoStyles({ isNegative: status === "NEGATIVE" });

  return (
    <>
      <AdminCard title="Garantía" withSpace={false} showTitleBackground={true}>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <InfoItemLabel labelText="Saldo disponible:">
                <Box className={classes.infoContainer}>
                  <InfoItemGeneralValue
                    value={currency(remainingAmount, currencyCode)}
                    labelClassName={classes.labelText}
                  />
                  <WarrantyStatus status={status} />
                </Box>
              </InfoItemLabel>
            </Grid>
            <Grid item xs={12}>
              <InfoItemLabel labelText="Destinatario garantía:">
                {receiver ? (
                  <>
                    <InfoItemGeneralValue value={receiverParser[receiver]} />
                    {status === "UNLIQUIDATED" && <EditButton onClick={handleOpenModal} />}
                  </>
                ) : (
                  <EmptyValue onClick={handleOpenModal} />
                )}
              </InfoItemLabel>
            </Grid>
            <Grid item xs={12} md={2}>
              <InfoItemLabel labelText="Cantidad original:">
                <InfoItemGeneralValue value={currency(originalAmount, currencyCode)} />
              </InfoItemLabel>
            </Grid>
            <Grid item xs={12} md={2}>
              <InfoItemLabel labelText="Ubicación garantía:">
                <Box className={classes.infoContainer}>
                  <InfoItemGeneralValue value={retainerParser[retainer]} />
                </Box>
              </InfoItemLabel>
            </Grid>
            <Grid item xs={12} md={8}>
              <InfoItemLabel
                labelText={
                  retainer === "HOUM" ? "Fecha de reembolso programada:" : "Fecha de pago:"
                }>
                {agreedDate && (
                  <>
                    <InfoItemGeneralValue value={format(new Date(agreedDate), "dd/MM/yyyy")} />
                    {status === "UNLIQUIDATED" && (
                      <EditButton onClick={handleOpenModalPaymentDate} />
                    )}
                  </>
                )}
                {!agreedDate && status === "NEGATIVE" && (
                  <Typography className={classes.refundUnavailable}>
                    El reembolso no está disponible ya que el usuario está en saldo negativo
                  </Typography>
                )}
                {!agreedDate && status !== "NEGATIVE" && (
                  <EmptyValue onClick={handleOpenModalPaymentDate} />
                )}
              </InfoItemLabel>
            </Grid>
          </Grid>
          {!isSmartLeasing && (status === "UNLIQUIDATED" || status === "NEGATIVE") && (
            <Grid item xs={12}>
              <DesignSystemButton
                label={getResolveWarrantyLabel(retainer, status)}
                size="small"
                variant="primary"
                disabled={isResolveWarrantyDisabled(status, !!agreedDate, !!receiver)}
                onClick={handleOpenModalConfirmation}
              />
            </Grid>
          )}
          <Grid container item xs={12} justifyContent="space-between" spacing={2}>
            <Grid item xs={6}>
              <BankAccount
                userId={landlordId}
                propertyId={propertyId}
                countryCode={countryCode}
                title="Información bancaria propietario"
              />
            </Grid>
            <Grid item xs={6}>
              <BankAccount
                userId={tenantId}
                propertyId={propertyId}
                countryCode={countryCode}
                title="Información bancaria arrendatario"
              />
            </Grid>
          </Grid>
        </Grid>
      </AdminCard>
      <ChangeWarrantyPersonModal
        contractId={contractId}
        countryCode={countryCode}
        receiver={receiver}
        open={open}
        onHandleClose={handleOpenModal}
      />
      <ChangeWarrantyHolder open={openHolderWarranty} onHandleClose={handleOpenModalHolder} />
      <PaymentDateModal
        contractId={contractId}
        countryCode={countryCode}
        defaultDate={agreedDate}
        open={openPaymentDate}
        onHandleClose={handleOpenModalPaymentDate}
        retainer={retainer}
      />
      <ConfirmWarrantyLiquidation
        open={openModalConfirmation}
        onHandleClose={handleOpenModalConfirmation}
        contractId={contractId}
        countryCode={countryCode}
        propertyId={propertyId}
        lesseeId={tenantId}
        lessorId={landlordId}
        subscriptionId={subscriptionId}
        retainer={retainer}
      />
    </>
  );
};

export default WarrantyInfo;
