import { useEffect } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";

import Spinner from "@houm-com/ui/Spinner";
import Button from "@houm-com/ui/Button";

import UserFormFields from "Components/Admin/UIComponents/UserFormFields";
import useReactHookForm from "hooks/useReactHookForm";
import { CountryUpperCase } from "models/Countries";
import useUserById from "hooks/useUserById";
import { useDocumentTypeList } from "hooks";
import { countryCode } from "utils";

import { userFormSchema } from "./utils/validationSchema";
import { getUserDefaultData } from "./utils/parsers";
import useUpdateUser from "./hooks/useUpdateUser";
import { classes } from "./UserFormStyles";
import { IFormData } from "./utils/types";

interface Props {
  userId: number;
  onSuccessEditUser?: () => void;
}

const UserForm = ({ userId, onSuccessEditUser }: Props) => {
  const { isLoadingUser, dataUser, isErrorUser } = useUserById(userId);
  const { mutate, isLoading } = useUpdateUser({
    onHandleSuccess: onSuccessEditUser,
  });
  const { documentTypeList, loading: loadingDocumentType } = useDocumentTypeList(
    dataUser?.country as CountryUpperCase
  );
  const { methods } = useReactHookForm<IFormData>({
    formSchema: userFormSchema(documentTypeList),
  });

  useEffect(() => {
    if (dataUser) methods.reset(getUserDefaultData(dataUser));
  }, [dataUser, methods]);

  if (isLoadingUser || loadingDocumentType)
    return <Spinner classNameContainer={classes.userFormSpinner} variant="primary" size="2xl" />;

  if (isErrorUser)
    return (
      <div className={classes.userFormError}>
        <p className={classes.userFormErrorText}>No se pudo obtener datos del usuario</p>
      </div>
    );

  const onSubmit: SubmitHandler<IFormData> = (data) =>
    mutate({
      ...data,
      country: countryCode(dataUser.country),
      userId: dataUser.id,
      role: dataUser.role,
    });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.userFormContainer}>
        <UserFormFields
          country={countryCode(dataUser?.country)}
          documentTypeList={documentTypeList}
        />
        <Button variant="primary" size="md" isLoading={isLoading}>
          Guardar
        </Button>
      </form>
    </FormProvider>
  );
};

export default UserForm;
