import { useHistory } from "react-router-dom";

import Button from "@houm-com/ui/Button";
import Tag from "@houm-com/ui/Tag";

import { ContractLeaseApplication } from "domains/leaseApplication/models/LeaseApplication";
import { classes } from "./ContractStyles";

interface Props {
  contract: ContractLeaseApplication;
}

const Contract = ({ contract }: Props) => {
  const history = useHistory();

  const onHandleRedirect = () => history.push(`/admin/contracts/${contract.id}`);

  return (
    <div className={classes.contractContainer}>
      <div className={classes.contractInfoContainer}>
        <p className={classes.contractInfoText}>{`Contrato generado: [ID: ${contract.id}]`}</p>
        <Tag label={contract.status} variant="default" />
      </div>
      <Button variant="primary" size="sm" onClick={onHandleRedirect}>
        Ver contrato
      </Button>
    </div>
  );
};

export default Contract;
