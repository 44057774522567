import Button from "@houm-com/ui/Button";
import Tag from "@houm-com/ui/Tag";

import { EvaluationMember, EvaluationStatus } from "domains/evaluations/models/Evaluations";
import { MEMBER_TITLE } from "apps/evaluator-admin/components/MemberCard/utils/constants";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { statusVariant } from "domains/evaluations/utils/memberStatus";
import {
  EvaluationMemberStatusDictionary,
  Permissions as P,
} from "domains/evaluations/utils/constants";
import { Locale } from "models/Countries";

import DocumentsDrawer from "./components/DocumentsDrawer";
import ModalManager from "./components/ModalManager";
import useMenuOptions from "./hooks/useMenuOptions";
import ProtectComponent from "../ProtectComponent";
import EvaluateDrawer from "./components/Evaluate";
import MemberInfo from "./components/MemberInfo";
import Comments from "./components/Comments";
import { classes } from "./MemberCardStyles";
import Menu from "../Menu";

interface Props {
  member: EvaluationMember;
  country: Locale;
  evaluationStatus: EvaluationStatus;
}

const MemberCard = ({ member, country, evaluationStatus }: Props) => {
  const { menuList, handleCloseModal, modal, handleOpenModal } = useMenuOptions({
    memberRole: member.role,
  });

  return (
    <>
      <AdminCard
        className={classes.memberCard}
        title={MEMBER_TITLE[member.role]}
        showTitleBackground={true}
        withSpace={false}
        component={
          <div className={classes.memberCardHeader}>
            <Tag
              label={EvaluationMemberStatusDictionary[member.status]}
              variant={statusVariant[member.status]}
            />
            <ProtectComponent
              strategy="any"
              requiredPermissions={[
                P.CREATE_COMMENT,
                P.UPDATE_EVALUATION_MEMBER,
                P.UPDATE_MAIN,
                P.DELETE_MEMBER,
                P.READ_MEMBER_INFORMATION,
                P.UPDATE_MEMBER_INFORMATION,
              ]}
              evaluationStatus={evaluationStatus}>
              <Menu menuList={menuList} />
            </ProtectComponent>
          </div>
        }>
        <div className={classes.infoContainer}>
          <MemberInfo
            member={member.userData}
            salaryAverage={Number(member.incomesAvg)}
            country={country}
            evaluationStatus={evaluationStatus}
            factors={member.factors}
          />
          <Comments
            comments={member.comments}
            evaluationMemberId={member.memberId}
            evaluationStatus={evaluationStatus}
          />
          <ProtectComponent
            requiredPermissions={[P.READ_FILES]}
            evaluationStatus={evaluationStatus}>
            <div className={classes.buttonContainer}>
              <Button
                size="md"
                variant="tertiary"
                type="button"
                onClick={handleOpenModal("documents")}>
                Ver documentos
              </Button>
            </div>
          </ProtectComponent>
        </div>
      </AdminCard>
      <EvaluateDrawer
        onHandleClose={handleCloseModal}
        open={modal === "evaluate"}
        documents={member.documents}
        personInfo={member.personInformation}
        userInformation={member.userData}
        memberId={member.memberId}
      />
      <DocumentsDrawer
        onHandleClose={handleCloseModal}
        open={modal === "documents"}
        documents={member.documents}
        memberName={`${member.userData.name} ${member.userData.lastName}`}
        evaluationMemberId={member.memberId}
      />
      <ModalManager memberId={member.memberId} modal={modal} onHandleModal={handleCloseModal} />
    </>
  );
};

export default MemberCard;
