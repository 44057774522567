import { useParams } from "react-router-dom";

import Spinner from "@houm-com/ui/Spinner";
import { cn } from "@houm-com/ui/utils";

import ApplicantBankAccount from "Components/Admin/UIComponents/ApplicantBankAccount";
import PropertyCard from "apps/brokerage-admin/screens/LeaseApplication/components/PropertyCard";
import { classes } from "apps/brokerage-admin/screens/LeaseApplication/LeaseApplicationStyles";
import useLeaseApplication from "domains/leaseApplication/hooks/useLeaseApplication";
import { EvaluationStatusListEnum } from "domains/evaluations/models/Evaluations";
import User from "apps/brokerage-admin/screens/LeaseApplication/components/User";
import Reservation from "Components/Admin/UIComponents/Reservation";

import ContractCard from "./components/ContractCard";
import EvaluationCard from "./components/EvaluationCard";

const LeaseApplication = () => {
  const { id } = useParams<{ id: string }>();
  const { isError, isLoading, data } = useLeaseApplication({
    leaseApplicationId: id,
  });

  if (isLoading)
    return (
      <div className={classes.leaseApplicationSpinner}>
        <Spinner variant="primary" size="2xl" />
      </div>
    );

  if (isError)
    return (
      <p className={classes.leaseApplicationErrorText}>
        Ha ocurrido un error en la carga del lease applicant
      </p>
    );

  return (
    <div className={classes.leaseApplicationContainer}>
      {data.evaluation && data.evaluation.status === EvaluationStatusListEnum.APPROVED && (
        <ContractCard
          contractGenerated={data.contract}
          contractPreparation={data.contractPreparationForm}
        />
      )}
      <PropertyCard property={data.property} />
      <User title="Arrendatario" user={data.applicationUser} />
      {data.evaluation && <EvaluationCard evaluation={data.evaluation} />}

      <ApplicantBankAccount
        propertyId={data.property.id}
        userId={data.applicationUser.id}
        country={data.country}
        cardClassName={cn(
          classes.leaseApplicationCardContainer,
          classes.leaseApplicationCardContainerStart
        )}
      />
      <Reservation
        propertyId={data.property.id}
        userId={data.applicationUser.id}
        country={data.country}
        cardClassName={cn(
          classes.leaseApplicationCardContainer,
          classes.leaseApplicationCardContainerEnd
        )}
      />
    </div>
  );
};

export default LeaseApplication;
