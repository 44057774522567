import { Grid } from "@material-ui/core";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FieldPhoneNumber from "@houm-com/ui/FieldPhoneNumber";

import {
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import { setNewUser } from "context/projectContext/projectActions";
import { useProject } from "context/projectContext/projectContext";
import { ICreateUser } from "models/User";
import { useGetToken } from "hooks";

import useStyles from "./UseFormStyles";
import validationSchema from "./validationSchema";
import CompanyFields from "./CompanyFields";
import { PhoneInput } from "@houm-com/ui/FieldPhoneNumber/utils/types";

function UserForm() {
  const { dispatch } = useProject();
  const { getToken } = useGetToken();

  const classes = useStyles();

  const methods = useForm<ICreateUser>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;

  const onSubmitHandler: SubmitHandler<ICreateUser> = (data, e) => {
    const newUser = {
      ...data,
      role: "real_estate_developer",
      company: data.company.id,
      phone_number: (data.phone_number as PhoneInput).parsedPhone,
    };
    getToken().then((authToken) => setNewUser(newUser, authToken, dispatch));
  };

  return (
    <FormProvider {...methods} key="userProvider">
      <Grid container direction="column" spacing={1}>
        <Grid item md={12}>
          <ControlledSelection
            id="country"
            label="País"
            options={[
              { value: "Chile", label: "Chile" },
              { value: "Colombia", label: "Colombia" },
              { value: "Mexico", label: "México" },
            ]}
          />
          <ControlledTextField
            id="name"
            label="Nombre"
            textFieldProps={{ InputProps: { disableUnderline: false } }}
          />
          <ControlledTextField
            id="last_name"
            label="Apellido"
            textFieldProps={{ InputProps: { disableUnderline: false } }}
          />
          <CompanyFields />
          <ControlledTextField
            id="email"
            label="Email"
            textFieldProps={{ InputProps: { disableUnderline: false } }}
          />
          <FieldPhoneNumber
            id="phone_number"
            label="Teléfono"
            onlyCountries={["CL", "CO", "MX", "US"]}
            errorMessage={errors.phone_number?.message}
            {...methods.register("phone_number")}
          />
        </Grid>
        <Grid item>
          <CustomButton
            id="create-user"
            kind="main"
            type="button"
            active
            className={classes.submitButton}
            onClick={methods.handleSubmit(onSubmitHandler)}>
            Crear usuario inmobiliaria
          </CustomButton>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

export default UserForm;
