import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import service from "domains/evaluations/service";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

interface Props {
  country: Locale;
  evaluationId: string;
}

const useFactorMember = ({ country, evaluationId }: Props) => {
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();

  const queryMutate = useMutation(
    async (data: { evaluationMemberId: string; evaluationMemberFactorId: string }) => {
      const authToken = await getToken();
      await service.factorMember(
        data.evaluationMemberId,
        data.evaluationMemberFactorId,
        country,
        authToken
      );
    }
  );

  useEffect(() => {
    if (queryMutate.isError) {
      alert.error({
        message: "Error al consultar el factor",
      });
    }
  }, [queryMutate.isError]);

  useEffect(() => {
    if (queryMutate.isSuccess) {
      queryClient.invalidateQueries(["evaluation", evaluationId]);
    }
  }, [queryMutate.isSuccess]);

  return queryMutate;
};

export default useFactorMember;
