import zod from "zod";

import {
  EvaluationMemberStatusSchema,
  EvaluationStatusSchema,
  MemberRolesSchema,
  RoleEnum,
  UserProfileSchema,
} from "domains/evaluations/models/Evaluations";
import { COUNTRY_ARRAY } from "models/Countries";
import { CURRENCIES_ARRAY } from "models/Currencies";
import { countryCode } from "utils";
import { ContractStatusSchema } from "domains/contract/models/Contract";

const CurrencyCodes = CURRENCIES_ARRAY;
const CurrencyCodeSchema = zod.enum(CurrencyCodes);
const CountryList = COUNTRY_ARRAY;
const CountrySchema = zod.enum(CountryList);

export const PropertySchema = zod.object({
  id: zod.number(),
  uid: zod.string(),
  address: zod.string(),
  price: zod.number(),
  currency: CurrencyCodeSchema,
});

export const LeaseApplicantSchema = zod
  .object({
    id: zod.number(),
    name: zod.string(),
    last_name: zod.string(),
    second_last_name: zod.string(),
    email: zod.string(),
    document: zod.string(),
    phone: zod.string(),
  })
  .transform(({ last_name, second_last_name, ...rest }) => ({
    lastName: last_name,
    secondLastName: second_last_name,
    ...rest,
  }));

export const ContractPreparationFormSchema = zod
  .object({
    contract_init_date: zod.string(),
    number_of_adults: zod.number(),
    number_of_minors: zod.number(),
    has_pet: zod.boolean(),
  })
  .transform((data) => ({
    contractInitDate: data.contract_init_date,
    numberOfAdults: data.number_of_adults,
    numberOfMinors: data.number_of_minors,
    hasPet: data.has_pet,
  }));

export const ContractLeaseApplicationSchema = zod.object({
  id: zod.number(),
  status: ContractStatusSchema,
});

export const LeaseApplicationEvaluationMemberSchema = zod
  .object({
    member_id: zod.string(),
    role: MemberRolesSchema,
    status: EvaluationMemberStatusSchema,
    user_data: UserProfileSchema,
  })
  .transform(({ member_id, user_data, ...rest }) => ({
    memberId: member_id,
    userData: user_data,
    ...rest,
  }));

export const LeaseApplicationEvaluationSchema = zod
  .object({
    id: zod.string(),
    members: zod.array(LeaseApplicationEvaluationMemberSchema),
    status: EvaluationStatusSchema,
    country: CountrySchema,
  })
  .transform(({ members, country, ...rest }) => ({
    members: members.sort((a, b) => {
      if (a.role === RoleEnum.MAIN) return -1;
      if (b.role === RoleEnum.MAIN) return 1;
      return 0;
    }),
    country: countryCode(country),
    ...rest,
  }));

export const LeaseApplicationSchema = zod
  .object({
    id: zod.number(),
    property: PropertySchema,
    application_user: LeaseApplicantSchema,
    contract_preparation_form: zod.nullable(ContractPreparationFormSchema),
    contract: zod.nullable(ContractLeaseApplicationSchema),
    evaluation: zod.nullable(LeaseApplicationEvaluationSchema),
    country: CountrySchema,
  })
  .transform(({ application_user, contract_preparation_form, country, ...rest }) => ({
    applicationUser: application_user,
    contractPreparationForm: contract_preparation_form,
    country: countryCode(country),
    ...rest,
  }));

export type LeaseApplication = zod.infer<typeof LeaseApplicationSchema>;
export type PropertyLeaseApplication = zod.infer<typeof PropertySchema>;
export type LeaseApplicant = zod.infer<typeof LeaseApplicantSchema>;
export type ContractPreparation = zod.infer<typeof ContractPreparationFormSchema>;
export type ContractLeaseApplication = zod.infer<typeof ContractLeaseApplicationSchema>;
export type LeaseApplicationEvaluationMember = zod.infer<
  typeof LeaseApplicationEvaluationMemberSchema
>;
export type LeaseApplicationEvaluation = zod.infer<typeof LeaseApplicationEvaluationSchema>;
