import { useParams } from "react-router-dom";

import Spinner from "@houm-com/ui/Spinner";
import { cn } from "@houm-com/ui/utils";
import Tag from "@houm-com/ui/Tag";

import BrokerageSwitchSelector from "apps/evaluator-admin/components/BrokerageSwitchSelector";
import { evaluationStatusVariant } from "domains/evaluations/utils/evaluationStatus";
import ApplicantBankAccount from "Components/Admin/UIComponents/ApplicantBankAccount";
import { classes } from "apps/evaluator-admin/screens/Evaluation/EvaluationStyles";
import SalarySummaryCard from "apps/evaluator-admin/components/SalarySummaryCard";
import { EvaluationStatusListEnum } from "domains/evaluations/models/Evaluations";
import { EvaluationStatusDictionary } from "domains/evaluations/utils/constants";
import Breadcrumbs from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import PropertyCard from "apps/evaluator-admin/components/PropertyCard";
import ApproveCard from "apps/evaluator-admin/components/ApproveCard";
import Reservation from "Components/Admin/UIComponents/Reservation";
import MemberCard from "apps/evaluator-admin/components/MemberCard";
import HoumerCard from "apps/evaluator-admin/components/HoumerCard";
import useEvaluation from "domains/evaluations/hooks/useEvaluation";
import { localeToCurrency } from "utils/countryCurrency";
import { Locale } from "models/Countries";

const evaluationsSteps = (evaluationId: string) => [
  {
    label: "Evaluator",
  },
  {
    label: "Evaluaciones",
    link: "/admin/evaluationsv2",
  },
  {
    label: `ID ${evaluationId}`,
  },
];

const Evaluation = () => {
  const { evaluationId, country } = useParams<{ evaluationId: string; country: Locale }>();
  const { data, isLoading, isError } = useEvaluation({
    evaluationId,
    country,
  });

  if (isLoading)
    return <Spinner classNameContainer={classes.spinner} variant="primary" size="2xl" />;

  if (isError) return <p>Error al cargar evaluación</p>;

  const mainMember = data.members.find((member) => member.role === "main");

  return (
    <>
      <div className={classes.headerContainer}>
        <div className="flex justify-between">
          <Breadcrumbs steps={evaluationsSteps(data.id)} />
          <BrokerageSwitchSelector brokerage={data.brokerage} evaluationStatus={data.status} />
        </div>
        <div className={classes.statusContainer}>
          <p className={classes.statusText}>Estado de la evaluación:</p>
          <Tag
            label={EvaluationStatusDictionary[data.status]}
            variant={evaluationStatusVariant[data.status]}
          />
        </div>
      </div>
      <div className={classes.evaluationContainer}>
        {data.status === EvaluationStatusListEnum.PARTIALLY_APPROVED && (
          <ApproveCard totalSalary={data.salariesSum} propertyId={data.propertyId} />
        )}

        {data.brokerage && (
          <HoumerCard applicantId={data.applicantId} evaluationStatus={data.status} />
        )}
        {data.members.map((member) => (
          <MemberCard
            key={member.memberId}
            member={member}
            country={country}
            evaluationStatus={data.status}
          />
        ))}
        <div className={cn(classes.cardContainer, classes.cardContainerStart)}>
          <SalarySummaryCard
            amount={data.salariesSum ? Number(data.salariesSum) : 0}
            currencyCode={localeToCurrency[country]}
            propertyId={data.propertyId}
          />
          <PropertyCard
            propertyId={data.propertyId}
            evaluationId={data.id}
            evaluationStatus={data.status}
          />
        </div>
        <div className={cn(classes.cardContainer, classes.cardContainerEnd)}>
          <ApplicantBankAccount
            propertyId={data.propertyId}
            userId={mainMember.userData.id}
            country={country}
          />
          <Reservation
            propertyId={data.propertyId}
            userId={mainMember.userData.id}
            applicantId={data.applicantId}
            country={country}
          />
        </div>
      </div>
    </>
  );
};

export default Evaluation;
