import { useEffect } from "react";

import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { PhoneInput } from "@houm-com/ui/FieldPhoneNumber/utils/types";
import { Divider, CircularProgress } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useFormNotifications, useGetToken, useDocumentTypeList } from "hooks";
import { useDashboard } from "context/controlTower/controlTowerContext";
import { CreateEventForm } from "context/eventContext/eventTypes";
import { useEvent } from "context/eventContext/eventContext";
import { CountryUpperCase } from "models/Countries";
import {
  initStates,
  postScheduleAdvisory,
  editSchedule,
  getAvailability,
  getAppraiserAvailability,
  setAvailability,
  confirmSchedule,
  setCancelRescheduleModal,
  ManualUserProps,
  setConfigModal,
} from "context/eventContext/eventActions";

import CancelRescheduleEventModal from "./components/CancelRescheduleModal";
import useCreateVisitSchedule from "../../../hooks/useCreateScheduler";
import validationSchema, { requiredText } from "./validationSchema";
import { RightColumnForm, LeftColumnForm } from "./components";
import MessageModal from "./components/MessageModal";
import { parsedScheduleData } from "./utils/parsers";
import useStyles from "./NewEventFormStyles";

interface Props {
  appraiserId: string;
  type?: string;
  propId?: number;
  email?: string;
  eventID?: number;
}

const NewEventForm = ({
  appraiserId,
  type = null,
  propId = null,
  email = null,
  eventID = null,
}: Props) => {
  const { getToken } = useGetToken();
  const classes = useStyles();
  const {
    dispatch,
    state: { appraisers, events, loading, availability, user, userEmail, userCountry },
  } = useEvent();

  const isSuccessHandler = () => {
    dispatch(
      setConfigModal({
        title: "¡Se han guardado los datos con exito!",
        isOpen: true,
      })
    );
  };

  const { error, isError, isLoading, mutate } = useCreateVisitSchedule({ isSuccessHandler });

  const {
    state: { availability: appraiserAv, appraiserProfile },
  } = useDashboard();
  const { documentTypeList } = useDocumentTypeList(userCountry);

  const methods = useForm<CreateEventForm>({
    resolver: yupResolver(validationSchema(documentTypeList, userCountry, user)),
    reValidateMode: "onChange",
  });

  //required to handle availability
  const property = methods.watch("property");

  const inputAppraiser = methods.watch("appraiser");

  const eventType = methods.watch("event_type");

  const { errors } = methods.formState;

  useEffect(() => {
    getToken().then(async (authToken) => {
      initStates(propId, email, dispatch, authToken);
    });
  }, []);

  useEffect(() => {
    //Load default values
    methods.setValue("timezone", appraiserProfile.timezone);
    if (appraiserId) {
      const appraiserInList = appraisers.find((app) => app.value === appraiserId);
      methods.setValue("appraiser", appraiserInList);
    }
    if (events?.length > 0) {
      const eventInList = events.find((event) => event.value === type);
      methods.setValue("event_type", eventInList);
    }
  }, [appraisers, events]);

  useEffect(() => {
    if (userCountry) {
      methods.setValue("documentType", "");
    }
  }, [userCountry]);

  useEffect(() => {
    if (userEmail) {
      methods.setValue("email", userEmail || "");
    }
  }, [userEmail]);

  const onSubmit: SubmitHandler<CreateEventForm> = async (data) => {
    try {
      getToken().then(async (authToken) => {
        if (eventID) {
          editSchedule(authToken, dispatch, eventID, appraiserProfile.timezone, data);
          return;
        }
        const phone = data.phone as PhoneInput;
        const manualUser: ManualUserProps = {
          name: methods.watch("name"),
          last_name: methods.watch("last_name"),
          document: methods.watch("document"),
          documentType: methods.watch("documentType"),
          phone: phone?.parsedPhone,
          email: methods.watch("email"),
          country: phone?.country?.countryName as CountryUpperCase,
        };
        //create a new schedule
        if (data.event_type.value === "Advisory") {
          await postScheduleAdvisory(data, authToken, dispatch, manualUser);
          return;
        }
        mutate(parsedScheduleData(data, manualUser));
      });
    } catch (err) {
      console.error({ err });
    }
  };

  useEffect(() => {
    if (!inputAppraiser || !eventType) {
      dispatch(setAvailability(null));
      return;
    }

    if (eventType?.value?.includes("Photo") || eventType?.value?.includes("Advisory")) {
      getAppraiserAvailability(
        appraiserAv.availability,
        property,
        appraiserProfile.timezone,
        dispatch
      );
      return;
    }
    if (!property) {
      dispatch(setAvailability(null));
      return;
    }

    getToken().then((authToken) => {
      getAvailability(property, dispatch, authToken);
    });
  }, [inputAppraiser, eventType, property]);

  useEffect(() => methods.setValue("availability", availability), [availability]);

  const handleCancelButton = () => {
    /**
     @see CancelRescheduleEventModal component
     */
    dispatch(setCancelRescheduleModal({ isOpen: true, type: "cancelation" }));
  };

  const onConfirm = () => {
    getToken().then((authToken) => {
      confirmSchedule(eventID, "Control Tower", dispatch, authToken);
    });
  };

  useFormNotifications<CreateEventForm>({ errors, requiredText });

  return (
    <FormProvider {...methods} key="EventProvider">
      {loading && <CircularProgress className={classes.circularProgress} color="primary" />}
      {!loading && (
        <form key="NewEventForm" id="NewEventForm" onSubmit={methods.handleSubmit(onSubmit)}>
          <MessageModal />
          {/* TODO: pass props to context */}
          <CancelRescheduleEventModal eventId={eventID} />

          <div className={classes.wrapperContent}>
            <div className={classes.firstColumn}>
              <LeftColumnForm />
            </div>

            <div className={classes.divider}>
              <Divider orientation="vertical" />
            </div>

            <div className={classes.secondColumn}>
              <RightColumnForm isError={isError} error={error} />
            </div>
          </div>
          <div className={classes.buttonWrapper}>
            {eventID && (
              <DesignSystemButton
                type="button"
                disabled={loading}
                isLoading={loading}
                onClick={onConfirm}
                label="Confirmar"
                size="small"
                variant="tertiaryA"
              />
            )}
            {eventID && (
              <DesignSystemButton
                type="submit"
                label="Reagendar"
                size="small"
                variant="tertiaryA"
                disabled={loading}
                isLoading={loading}
              />
            )}

            {!eventID && (
              <DesignSystemButton
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
                label="Agendar"
                size="small"
                variant="tertiaryA"
              />
            )}

            {eventID && (
              <DesignSystemButton
                onClick={handleCancelButton}
                type="button"
                label="Cancelar"
                size="small"
                variant="tertiaryA"
              />
            )}
          </div>
        </form>
      )}
    </FormProvider>
  );
};

export default NewEventForm;
