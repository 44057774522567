import { GridColDef, GridColumns } from "@material-ui/x-grid";

import Tag from "@houm-com/ui/Tag";

import { EvaluationMemberStatusDictionary } from "domains/evaluations/utils/constants";
import {
  StatusReservationEnum,
  StatusReservationParsedEnum,
} from "domains/evaluations/models/Evaluations";
import { CustomLink } from "Components/Admin/UIComponents";
import { Locale } from "models/Countries";

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  align: "center",
  sortable: false,
};

export const getColumns = ({ country }: { country: Locale }): GridColumns => [
  {
    ...columnsConfig,
    resizable: false,
    align: "left",
    hide: true,
    field: "id",
    headerName: "",
  },
  {
    ...columnsConfig,
    field: "reservationStatus",
    headerName: "Reserva",
    renderCell: ({ row }) => (
      <Tag
        label={StatusReservationParsedEnum[row.reservationStatus]}
        variant={row.reservationStatus === StatusReservationEnum.PAID ? "success" : "default"}
      />
    ),
  },
  {
    ...columnsConfig,
    field: "mainMemberName",
    headerName: "Postulante principal",
  },
  {
    ...columnsConfig,
    field: "memberName",
    headerName: "Postulante (Codeudor)",
  },
  {
    ...columnsConfig,
    field: "propertyId",
    headerName: "ID Propiedad",
    renderCell: ({ row }) => (
      <CustomLink to={`/admin/properties/${row.propertyUid}`} text={row.propertyId} />
    ),
  },
  {
    ...columnsConfig,
    field: "status",
    headerName: "Estado",
    sortable: false,
    renderCell: ({ row }) => EvaluationMemberStatusDictionary[row.status],
  },
  {
    ...columnsConfig,
    field: "createdAt",
    headerName: "Creación",
  },
  {
    ...columnsConfig,
    field: "updatedAt",
    headerName: "Última modificación",
  },
  {
    ...columnsConfig,
    field: "actions",
    headerName: "Acciones",
    renderCell: ({ row }) => {
      if (typeof row.rentalEvaluationId === "number")
        return (
          <CustomLink
            to={`/admin/evaluations/${country}/${row.mainMemberUserId}/${row.mainMemberUserId}`}
          />
        );
      return <CustomLink to={`/admin/evaluations/${country}/${row.rentalEvaluationId}`} />;
    },
  },
];
