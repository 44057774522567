import { ComponentProps } from "react";

import Tag from "@houm-com/ui/Tag";

import {
  FactorResultStatusEnum,
  FactorResultStatusList,
} from "domains/evaluations/models/Evaluations";

export const factorStatusVariant: {
  [locale in FactorResultStatusList]: ComponentProps<typeof Tag>["variant"];
} = {
  [FactorResultStatusEnum.APPROVED]: "success",
  [FactorResultStatusEnum.ERROR]: "warning",
  [FactorResultStatusEnum.REJECTED]: "error",
  [FactorResultStatusEnum.PENDING]: "info",
};
