import { useHistory } from "react-router-dom";

import Button from "@houm-com/ui/Button";
import Tag from "@houm-com/ui/Tag";

import { LeaseApplicationEvaluation } from "domains/leaseApplication/models/LeaseApplication";
import { evaluationStatusVariant } from "domains/evaluations/utils/evaluationStatus";
import {
  EvaluationMemberStatusDictionary,
  EvaluationStatusDictionary,
} from "domains/evaluations/utils/constants";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { statusVariant } from "domains/evaluations/utils/memberStatus";

import { classes } from "./EvaluationCardStyles";

interface Props {
  evaluation: LeaseApplicationEvaluation;
}

const EvaluationCard = ({ evaluation }: Props) => {
  const history = useHistory();

  const onHandleEvaluation = () =>
    history.push(`/admin/evaluations/${evaluation.country}/${evaluation.id}`);

  return (
    <AdminCard
      title="Evaluación"
      showTitleBackground={true}
      withSpace={false}
      className={classes.evaluationCardRoot}>
      <div className={classes.evaluationCardContainer}>
        <div className={classes.evaluationCardEvaluationStatusContainer}>
          <p>Estado de la evaluación: </p>
          <Tag
            label={EvaluationStatusDictionary[evaluation.status]}
            variant={evaluationStatusVariant[evaluation.status]}
          />
        </div>
        <div className={classes.evaluationCardMembersContainer}>
          {evaluation.members.map((member) => (
            <div className={classes.evaluationCardMemberInfo} key={member.memberId}>
              <p>{`${member.userData.name} ${member.userData.lastName}`}</p>
              <Tag
                label={EvaluationMemberStatusDictionary[member.status]}
                variant={statusVariant[member.status]}
              />
            </div>
          ))}
        </div>
        <Button variant="primary" size="sm" onClick={onHandleEvaluation}>
          Ver evaluación
        </Button>
      </div>
    </AdminCard>
  );
};

export default EvaluationCard;
