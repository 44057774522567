import { FC, InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";

import { Grid, Typography } from "@material-ui/core";
import FieldInput from "@houm-com/ui/FieldInput";

import useDataWrapperStyles from "./DataWrapperStyles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  editMode?: boolean;
  formatter?: (s: string | number) => string | null;
  id: string;
  label: string;
  type?: string;
  value: string | number;
}

const DataWrapper: FC<Props> = ({
  editMode,
  formatter,
  id,
  label,
  type,
  value,
  ...props
}: Props) => {
  const classes = useDataWrapperStyles();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const displayValue = formatter ? formatter(value) : value;

  if (!editMode)
    return (
      <Grid item xs={6}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.value}>{displayValue || "Sin registro"}</Typography>
      </Grid>
    );

  return (
    <Grid item xs={6} className={classes.dataWrapperRoot}>
      <FieldInput
        id={id}
        label={label}
        defaultValue={value}
        errorMessage={errors[id]?.message as string}
        classNameLabel={classes.inputLabel}
        classNameInput={classes.input}
        type={type}
        {...register(id)}
        {...props}
      />
    </Grid>
  );
};

export default DataWrapper;
