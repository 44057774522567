import { useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";

import { FormControl, Grid, Divider } from "@material-ui/core";
import moment from "moment-timezone";

import {
  HourButton,
  Select,
  HelperText,
} from "Components/Admin/administrator/properties/components/FormElements";
import "moment/locale/es";

import { generalOptions, countries } from "utils/createProperty";
import { getTimezone } from "utils";

interface IPropsSelectHour {
  id: string;
  onChangeValue?: Function;
  day: string;
  hours?: string[];
  country: countries | string; // propertyCountry
  commune: string;
}
const SelectHour = forwardRef(
  ({ id, day, onChangeValue, hours, country, commune }: IPropsSelectHour, ref) => {
    const [current, setCurrent] = useState<string | null>();

    const [timezones, setTimezones] = useState<string[]>([]);
    const [timezone, setTimezone] = useState<string | null>(generalOptions[country].defaultZone);
    const [error, setError] = useState<boolean>(false);
    const [txtError, setTxtError] = useState<string>("");

    // const timezone = moment.tz.zonesForCountry(generalOptions[country].tzName)[0]
    const difRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      setTimezones(generalOptions[country].zones);
      setTimezone(getTimezone(country, commune));
    }, [country]);

    useEffect(() => {
      setCurrent(null);
    }, [day]);
    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          [id]: current,
        };
      },
      setValue(val) {
        setCurrent(val);
      },
      validate() {
        if (!current) {
          setError(true);
          setTxtError("Este campo es necesario");
          difRef?.current.scrollIntoView();
          return false;
        }
        return true;
      },
    }));
    const handleClick = (value) => {
      if (error) {
        setError(false);
        setTxtError("");
      }
      if (value === current) {
        setCurrent(null);
        if (onChangeValue) {
          onChangeValue(null);
        }
      } else {
        setCurrent(value);
        if (onChangeValue) {
          onChangeValue(null);
        }
      }
    };

    return (
      <FormControl fullWidth error={error} required>
        <Grid direction="column" container spacing={4}>
          <Grid item>
            <Select
              label="zonas"
              options={timezones}
              id="zones"
              value={timezone}
              onChangeValue={(e) => setTimezone(e.target.value)}
            />
          </Grid>
          <Grid>
            <Divider />
          </Grid>
          <Grid item>
            <Grid ref={difRef} container spacing={3}>
              {day &&
                hours?.map((timestamp) => {
                  const momentHour = moment.utc(timestamp).clone().tz(timezone).format("HH:mm");
                  return (
                    <Grid item xs={12} sm={6} md={4} key={`hour-${timestamp}`}>
                      <HourButton
                        active={timestamp === current}
                        onClick={() => handleClick(timestamp)}>
                        {momentHour}
                      </HourButton>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
        <HelperText hidden={!error}>{txtError}</HelperText>
      </FormControl>
    );
  }
);
SelectHour.displayName = " SelectHour";
export default SelectHour;
