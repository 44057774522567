export const READJUST_TRANSLATE = {
  annual: "Anual",
  biannual: "Semestral",
  quarterly: "Trimestral",
  no_readjust: "Sin Reajuste",
};

export const CONTRACT_TYPES = {
  lease: {
    value: "lease",
    label: "Arriendo",
  },
  collection_service: {
    value: "collection_service",
    label: "Servicio de cobranza",
  },
};
