import { PhoneInput } from "models/PhoneInput";
import { ContractLandlordProps } from "../../utils/types";

export const parseBackendLandlord = (data: ContractLandlordProps) => ({
  owner: {
    name: data.fullName,
    rut: data.document,
    document: data.document,
    document_type: data.documentType,
    phone: (data.phone as PhoneInput).parsedPhone,
    email: data.email,
    address: data.address,
  },
});
