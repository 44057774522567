import Swal from "sweetalert2";
import moment from "moment";
import { ClassNameMap } from "@material-ui/styles/withStyles";

import {
  Dispatch,
  Action,
  SET_LOADING_UPLOAD,
  NewLayoutForm,
  NewUnitForm,
  UnitsUploadError,
  SET_SELECTED_LAYOUT,
  SET_LOADING,
  SET_PROJECTS,
  SET_CREATED_PROJECT,
  SET_USER,
  SET_USER_MODAL,
  SET_SUCCESS_MODAL,
  SET_LOCATION_OPTIONS,
  SET_ADDRESS,
  SET_IS_SHORTER_FORM,
  CHANGE_COUNTRY,
  SET_UNITS_UPLOAD_MODAL,
  SET_UNITS_UPLOAD_ERRORS,
} from "./projectTypes";
import { uploadToS3 } from "utils/fileStreaming";
import projectsService from "services/projectsService";
import { communeOptions, countryCode, neighborhoodOptions } from "utils";
import propertyService from "services/propertyService";
import leadService from "services/leadService";
import { formatAddress } from "utils/createProperty";
import { propertyStatus } from "utils/propertyStatus";
import { Country, Currency } from "models/Countries";
import { CreateProjectForm, IProject } from "models/Projects";
import parseProject from "utils/parseProject";
import { IUserAutoComplete, PropertyDetails, SalesDetails } from "models";
import userService from "services/userService";
import { UserFormType } from "models/User";
import { parseLayout } from "./projectUtils";

const setLoading = (loading: boolean): Action => ({
  type: SET_LOADING,
  loading,
});

export const setUserModal = (openUserModal: boolean): Action => ({
  type: SET_USER_MODAL,
  openUserModal,
});

export const setUnitsUploadModal = (openUnitsUploadModal: boolean): Action => ({
  type: SET_UNITS_UPLOAD_MODAL,
  openUnitsUploadModal,
});

export const setUnitsUploadErrors = (unitsUploadErrors: UnitsUploadError[]): Action => ({
  type: SET_UNITS_UPLOAD_ERRORS,
  unitsUploadErrors,
});

export const setLoadingUpload = (loadingUpload: boolean): Action => ({
  type: SET_LOADING_UPLOAD,
  loadingUpload,
});

export const setSelectedLayout = (selectedLayout: NewLayoutForm): Action => ({
  type: SET_SELECTED_LAYOUT,
  selectedLayout,
});

export const setSuccessModal = (openSuccessModal: boolean): Action => ({
  type: SET_SUCCESS_MODAL,
  openSuccessModal,
});

export const setIsShorterForm = (isShorterForm: boolean): Action => ({
  type: SET_IS_SHORTER_FORM,
  isShorterForm,
});
export const setProject = (project: IProject): Action => ({
  type: SET_CREATED_PROJECT,
  project,
});

export const changeCountry = (country: Country): Action => ({
  type: CHANGE_COUNTRY,
  country,
});

export const setUser = (user: IUserAutoComplete): Action => ({
  type: SET_USER,
  user,
});

export const getLocationOptions = async (country, dispatch, authToken) => {
  try {
    const cc = countryCode(country);

    const results = await propertyService.getLocations(cc, authToken);
    const regions = await results.regions;

    const neighborhoods = neighborhoodOptions(regions);

    const communes = communeOptions(regions);

    dispatch({ type: SET_LOCATION_OPTIONS, neighborhoods, communes });
  } catch (err) {
    console.error(err);
  }
};

export const createNeighborhood = async (address, dispatch, authToken) => {
  if (typeof address === "object" && address !== null) {
    let parsedAddress = {} as {
      neighborhood: string;
      commune: string;
      region: string;
      country: string;
      latitude: number;
      longitude: number;
      prevSearch: string;
    };
    if (address.parsed) {
      parsedAddress = address;
    } else {
      // TODO: this can be removed after the API /locations/address/ is establed and we remove google suggestions
      parsedAddress = formatAddress(address);
    }
    dispatch({ type: SET_ADDRESS, address: parsedAddress });

    if (
      parsedAddress.neighborhood &&
      parsedAddress.commune &&
      parsedAddress.region &&
      parsedAddress.country &&
      parsedAddress.latitude &&
      parsedAddress.longitude &&
      parsedAddress.prevSearch
    ) {
      try {
        return await propertyService.getOrCreateLocation(parsedAddress, authToken);
      } catch (err) {
        console.error(err);
      }
    }
  }
};

export const getLayouts = async (propId: number, dispatch: Dispatch, token: string) => {
  try {
    dispatch(setLoading(true));
    const layouts = await projectsService.getLayouts(propId, token);
    if (layouts) {
      const results = layouts.data;
      dispatch(setLoading(false));
      return results;
    }
  } catch (err) {
    dispatch(setLoading(false));
    console.error(err);
  }
};

export const uploadLayoutImage = async (
  propId: number,
  layout: NewLayoutForm,
  token: string
): Promise<string> => {
  const timestamp = moment(new Date(Date.now())).format("YYYY-MM-DD-HH:mm:ss");
  let filePath = `${propId}-${layout.name}-${timestamp}.jpeg`;
  filePath = filePath.replaceAll(" ", "");
  try {
    const presignedUrl = await projectsService.getLayoutImagePresignedUrl(filePath, token);
    const uploadImage = await uploadToS3({
      fileContents: layout?.image[0],
      presignedUrl: presignedUrl.data,
    });
    if (uploadImage) return filePath;
    else {
      Swal.fire({
        type: "error",
        title: "Ups!",
        text: "No se pudo guardar el plano",
      });
      return "";
    }
  } catch (error) {
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo guardar el plano",
    });
    console.error(error);
    return "";
  }
};

export const postLayout = async (
  propId: number,
  layout: NewLayoutForm,
  filePath: string,
  dispatch: Dispatch,
  token: string
) => {
  try {
    dispatch(setLoadingUpload(true));
    const data = parseLayout(propId, layout, filePath);
    const postedlayout = await projectsService.postLayout(data, token);
    if (postedlayout) {
      Swal.fire({
        type: "success",
        title: "Éxito",
        text: "Tipología guardada correctamente",
      });
    } else {
      Swal.fire({
        type: "error",
        title: "Ups!",
        text: "No se pudo editar la tipología",
      });
    }
    dispatch(setLoadingUpload(false));
  } catch {
    dispatch(setLoadingUpload(false));
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo editar la tipología",
    });
  }
};

export const editLayout = async (
  propId: number,
  layout: NewLayoutForm,
  filePath: string,
  layoutId: number,
  dispatch: Dispatch,
  token: string
) => {
  try {
    dispatch(setLoadingUpload(true));
    const data = parseLayout(propId, layout, filePath);
    const postedlayout = await projectsService.editLayout(data, layoutId, token);
    if (postedlayout) {
      Swal.fire({
        type: "success",
        title: "Éxito",
        text: "Tipología editada correctamente",
      });
    } else {
      Swal.fire({
        type: "error",
        title: "Ups!",
        text: "No se pudo editar la tipología",
      });
    }
    dispatch(setLoadingUpload(false));
  } catch {
    dispatch(setLoadingUpload(false));
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo editar la tipología",
    });
  }
};

export const deleteLayout = async (
  layoutId: number,
  dispatch: Dispatch,
  name: string,
  classes: ClassNameMap<string>,
  token: string
) => {
  Swal.mixin({
    customClass: {
      confirmButton: classes.confirmButtonRounded,
      cancelButton: classes.cancelButton,
    },
    buttonsStyling: false,
  })
    .fire({
      title: "Borrar tipología",
      html: `<span>¿Estás seguro de querer borrar la tipología <b>“${name}”</b>?</span>`,
      showCancelButton: true,
      confirmButtonText: "Eliminar tipología",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    })
    .then(async (result) => {
      if (result.value) {
        try {
          dispatch(setLoadingUpload(true));
          const postedlayout = await projectsService.deleteLayout(layoutId, token);
          if (postedlayout) {
            Swal.fire({
              type: "success",
              title: "Éxito",
              text: "Tipología eliminada correctamente",
            });
          } else {
            Swal.fire({
              type: "error",
              title: "Ups!",
              text: "No se pudo eliminar la tipología",
            });
          }
          dispatch(setLoadingUpload(false));
        } catch {
          dispatch(setLoadingUpload(false));
          Swal.fire({
            type: "error",
            title: "Ups!",
            text: "No se pudo eliminar la tipología",
          });
        }
      }
    });
};

export const postUnit = async (
  layoutId: number,
  unit: NewUnitForm,
  currency: Currency,
  dispatch: Dispatch,
  token: string
) => {
  try {
    dispatch(setLoadingUpload(true));
    const data = {
      unit_type: layoutId,
      currency,
      ...unit,
    };
    const editedUnit = await projectsService.postUnit(data, token);
    if (editedUnit) {
      Swal.fire({
        type: "success",
        title: "Éxito",
        text: "Unidad guardada correctamente",
      });
    } else {
      Swal.fire({
        type: "error",
        title: "Ups!",
        text: "No se pudo guardar la unidad",
      });
    }
    dispatch(setLoadingUpload(false));
  } catch {
    dispatch(setLoadingUpload(false));
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo guardar la unidad",
    });
  }
};

export const editUnit = async (
  unitId: number,
  unit: NewUnitForm,
  layoutId: number,
  currency: Currency,
  dispatch: Dispatch,
  token: string
) => {
  try {
    dispatch(setLoadingUpload(true));
    const data = {
      unit_type: layoutId,
      currency,
      ...unit,
    };
    const postedlayout = await projectsService.editUnit(data, unitId, token);
    if (postedlayout) {
      Swal.fire({
        type: "success",
        title: "Éxito",
        text: "Modelo editado correctamente",
      });
    } else {
      Swal.fire({
        type: "error",
        title: "Ups!",
        text: "No se pudo editar el modelo",
      });
    }
    dispatch(setLoadingUpload(false));
  } catch {
    dispatch(setLoadingUpload(false));
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo editar el modelo",
    });
  }
};

export const deleteUnit = async (
  unitId: number,
  dispatch: Dispatch,
  name: string,
  subname: string,
  classes: ClassNameMap<string>,
  token: string
) => {
  Swal.mixin({
    customClass: {
      confirmButton: classes.confirmButtonRounded,
      cancelButton: classes.cancelButton,
    },
    buttonsStyling: false,
  })
    .fire({
      title: "Borrar unidad",
      html: `<span>¿Estás seguro de querer borrar la unidad <b>“${subname}”</b> de la tipología <b>“${name}”</b>?</span>`,
      showCancelButton: true,
      confirmButtonText: "Eliminar unidad",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    })
    .then(async (result) => {
      if (result.value) {
        try {
          dispatch(setLoadingUpload(true));
          const deletedUnit = await projectsService.deleteUnit(unitId, token);
          if (deletedUnit) {
            Swal.fire({
              type: "success",
              title: "Éxito",
              text: "Unidad eliminada correctamente",
            });
          } else {
            Swal.fire({
              type: "error",
              title: "Ups!",
              text: "No se pudo eliminar la unidad",
            });
          }
          dispatch(setLoadingUpload(false));
        } catch {
          dispatch(setLoadingUpload(false));
          Swal.fire({
            type: "error",
            title: "Ups!",
            text: "No se pudo eliminar la unidad",
          });
        }
      }
    });
};

export const getProjects = async (
  pageSize,
  currentPage,
  mappedFilters,
  dispatch: Dispatch,
  token: string
) => {
  dispatch({ type: SET_LOADING, loading: true });

  try {
    const response = await projectsService.getProjects(pageSize, currentPage, mappedFilters, token);
    if (response) {
      const { results, count } = response.data;
      const projects = results.map((project) => {
        const result = {
          id: project?.id,
          published:
            project?.property?.sales_details?.status === "published" ? "Publicada" : "No publicada",
          status: propertyStatus.find(
            (item) => item.value === project?.property?.sales_details?.status
          )?.name,
          lastStatusChange: moment(project?.property?.sales_details?.last_status_change).format(
            "YYYY-MM-DD"
          ),
          landlordEmail: project?.property?.user?.email,
          projectName: project?.property?.association_amenities?.association_name,
          commune: project?.property?.comuna,
          link: `/admin/properties/${project?.property?.uid}`,
        };
        return result;
      });

      dispatch({ type: SET_PROJECTS, payload: { projects, count } });
    }
  } catch (e) {
    dispatch({ type: SET_LOADING, loading: false });
    throw e;
  }
};

export const createProject = async (
  data: CreateProjectForm,
  dispatch: Dispatch,
  authToken: string
) => {
  const newProject = parseProject(data);
  try {
    dispatch(setLoading(true));
    const { data: resProject } = await projectsService.createProject(newProject, authToken);
    dispatch(setProject(resProject));
  } catch (err) {
    Swal.fire({
      type: "error",
      title: "Error!",
      text: err as string,
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const setSingleProject = async (id: number, dispatch: Dispatch, authToken: string) => {
  try {
    const { data: resProject } = await projectsService.getProject(id, authToken);
    dispatch(setProject(resProject));
  } catch (err) {
    Swal.fire({
      type: "error",
      title: "Error!",
      text: err as string,
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const editProject = async (
  projectId: number,
  data: CreateProjectForm,
  dispatch: Dispatch,
  authToken: string
) => {
  const project = parseProject(data);
  try {
    dispatch(setLoading(true));
    const { data: resProject } = await projectsService.editProject(projectId, project, authToken);
    dispatch({ type: SET_CREATED_PROJECT, project: resProject });
    Swal.fire({
      type: "success",
      title: "Proyecto editado con éxito",
      text: "El proyecto ha sido editado correctamente.",
    }).then(() => window.location.reload());

    return resProject;
  } catch (err) {
    Swal.fire({
      type: "error",
      title: "Error!",
      text: err as string,
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const setNewUser = async (user: UserFormType, token: string, dispatch: Dispatch) => {
  try {
    const defaultValues = {
      documentType: "1",
      pipedrivestage: 194,
      nombrestage: "COMPLETAR_INFO_1",
    };

    const newUser = {
      ...user,
      phone: String(user.phone_number)?.replace(/ /g, ""),
      ...defaultValues,
    };
    delete newUser.phone_number;

    const res = await leadService.postUser(newUser, token);

    if (res.data?.redirect || res.status === 409) throw new Error("Usuario ya existe");

    dispatch(setUser({ id: res?.data?.id, ...user }));
    dispatch(setUserModal(false));

    Swal.fire({
      title: "Usuario creado y asignado con éxito",
      timer: 3000,
      type: "success",
    });
  } catch (err) {
    Swal.fire("No se pudo crear el propietario", err as string, "error");
    console.error(err);
  }
};

export const uploadUnitsFile = async (
  developmentId: number,
  file: File,
  token: string,
  dispatch: Dispatch
) => {
  try {
    dispatch(setLoadingUpload(true));
    const timestamp = moment(new Date(Date.now())).format("YYYY-MM-DD-HH:mm:ss");
    const filename = `units_${developmentId}_ ${timestamp}.xlsx`;
    const presignedUrl = await projectsService.getUnitsFilePresignedUrl(filename, token);
    await uploadToS3({
      fileContents: file,
      presignedUrl: presignedUrl.data,
    });
    return filename;
  } catch {
    dispatch(setLoadingUpload(false));
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo subir el archivo para ser procesado",
    });
  }
};

export const parseUnitsFile = async (
  developmentId: number,
  filename: string,
  token: string,
  dispatch: Dispatch
) => {
  try {
    const res = await projectsService.parseUnitsFile(
      { filename, development: developmentId },
      token
    );
    if (res.status == 200) return { parseErrors: true, errorsList: res.data };
    Swal.fire({
      type: "success",
      title: "Carga exitosa",
      text: "Las unidades han sido subidas correctamente",
    });
    return { parseErrors: false, errorsList: [] };
  } catch {
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo procesar el archivo",
    });
  } finally {
    dispatch(setLoadingUpload(false));
  }
};

export const getCompanies = async (search: string, token: string) => {
  try {
    const companies = await userService.getCompanies(search, token);
    return companies.data.results;
  } catch (er) {
    console.error(er);
    return [];
  }
};

export const postCompanies = async (name: string, token: string) => {
  try {
    const data = {
      name,
      role: "real_estate_developer",
    };
    const newCompany = await userService.postCompany(data, token);
    Swal.fire({
      type: "success",
      title: "Éxito",
      text: "Compañía creada con éxito",
    });
    return { id: newCompany.data.id, name: newCompany.data.name };
  } catch {
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo crear la compañía",
    });
  }
};

export const updatePropertyDetails = async (
  propertyId: number,
  data: PropertyDetails,
  token: string
) => {
  try {
    const sendData = {
      property_details: data,
    };
    await propertyService.updateProperty(propertyId, sendData, token);
    Swal.fire("Actualizado con éxito", "Se ha actualizado la propiedad correctamente", "success");
  } catch {
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo actualizar la propiedad",
    });
  }
};

export const updateSalesDetails = async (propertyId: number, data: SalesDetails, token: string) => {
  try {
    const sendData = {
      sales_details: data,
    };
    await propertyService.updateProperty(propertyId, sendData, token);
    Swal.fire("Actualizado con éxito", "Se ha actualizado la propiedad correctamente", "success");
  } catch {
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo actualizar la propiedad",
    });
  }
};
