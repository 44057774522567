import { PhoneInput } from "@houm-com/ui/FieldPhoneNumber/utils/types";
import { CountryUpperCase } from "models/Countries";
import { BooleanString } from "./constants";

export interface SelectionOption {
  value: number;
  label: string;
}

export interface idsProps {
  propertyDetailsId: number;
  propertyId: number;
  propertyUid: string;
  userId: number;
}
export interface PropertyDataProps {
  address: string;
  addressNumber: string;
  outerNumber: string;
  innerNumber: string;
  neighborhood: SelectionOption;
  commune: string;
  region: string;
  country: CountryUpperCase | "";
  postalCode: string;
  rentValue: string;
  availableDate: string;
  isFlat: BooleanString;
  isHorizontal: BooleanString;
  departmentNumber: string;
  isFurnished: "fully" | "partially" | "non";
  parkingNumbers: string;
  isUrban: BooleanString;
  inCondo: BooleanString;
  publicDeed: BooleanString;
  publicDeedNumber: string;
  publicDeedDate: string;
  notary: string;
  notaryNumber: string;
  propertyRegistrationNumber: string;
  predialNumber: string;
  hasCommonExpenses: BooleanString;
  commonExpenses: string;
  mortgage: string;
  domainRestriction: string;
  paymentCondition: string;
  chargeCondition: string;
  policy: string;
  warranty: string;
  warehouseNumber: string;
  maxPeopleQuantity: string;
}

export type CivilStatus = "unmarried" | "married" | "divorced" | "widowed" | "freeUnion" | "other";

export type MaritalRegime = "conjugalSociety" | "separatedGoods" | "notApply" | "other";

export interface LandlordDataProps {
  name: string;
  userType: "natural" | "corporative";
  document: string;
  documentType: number | "0";
  phoneNumber: string | PhoneInput;
  email: string;
  address: string;
  city: string;
  country: CountryUpperCase | "";
  civilStatus: CivilStatus;
  maritalRegime: MaritalRegime;
  isIvaResponsible: BooleanString | "";
  specialCondition: string;
  isNew: BooleanString;
  landlordBrokerageCommission: number;
  tenantBrokerageCommission: number;
  rfc: string;
  occupation: string;
}

export interface ThirdPartyProps {
  name: string;
  document: string;
  documentType: number | "0";
  email: string;
  phone: string | PhoneInput;
  address: string;
  country: CountryUpperCase | "";
}

export interface Services {
  energy: SelectionOption[];
  gas: SelectionOption[];
  water: SelectionOption[];
}

export interface PublicServicesProps {
  waterService: number;
  waterClient: string;
  energyService: number;
  energyClient: string;
  gasService: number;
  gasClient: string;
  cleaningClient: string;
  availableServices: Services | null;
  includedServices: string;
  additionalServices: string;
}

export interface AdminDataProps {
  email: string;
  phoneNumber: string;
  hasHoumCleaning: BooleanString;
  cleaningAmount: string;
}

export interface FilesProps {
  ccFile: File;
  predialFile: File;
  energyReceipt: File;
  gasReceipt: File;
  waterReceipt: File;
  cleaningReceipt: File;
  administrationReceipt: File;
  traditionLibertyFile: File;
  ineFile: File;
  csfFile: File;
  publicDeed: File;
  predialVoucher: File;
}

export interface FileLinksProps {
  ccFile: string;
  predialFile: string;
  energyReceipt: string;
  gasReceipt: string;
  waterReceipt: string;
  cleaningReceipt: string;
  administrationReceipt: string;
  traditionLibertyFile: string;
  ineFile: string;
  csfFile: string;
  publicDeed: string;
  predialVoucher: string;
}

export interface DocumentOptionProps extends SelectionOption {
  validationRegex: string;
}

export const step = [
  "propertyData",
  "landlordData",
  "thirdPartyData",
  "publicServices",
  "adminData",
  "fileLinks",
];

export type Step = (typeof step)[number];

export interface State {
  availableSteps: Step[];
  step: Step;
  loading: boolean;
  submitting: boolean;
  navText: string;
  stepMode: boolean;
  ids: idsProps;
  isAdmin: boolean;
  readyToSubmit: Step | "";
  alreadySubmitted: boolean;
  documentTypes: DocumentOptionProps[];
  propertyData: PropertyDataProps;
  landlordData: LandlordDataProps;
  thirdPartyData: ThirdPartyProps;
  publicServices: PublicServicesProps;
  adminData: AdminDataProps;
  files: FilesProps;
  fileLinks: FileLinksProps;
}

export const SET_LOADING = "SET_LOADING";
export const SET_AVAILABLE_STEPS = "SET_AVAILABLE_STEPS";
export const SET_STEP = "SET_STEP";
export const NEXT_STEP = "NEXT_STEP";
export const PREV_STEP = "PREV_STEP";
export const SET_IS_ADMIN = "SET_IS_ADMIN";
export const SET_READY = "SET_READY";
export const SET_ALREADY_SUBMITTED = "SET_ALREADY_SUBMITTED";
export const SET_SUBMITTING = "SET_SUBMITTING";
export const SET_NAV_TEXT = "SET_NAV_TEXT";
export const SET_STEP_MODE = "SET_STEP_MODE";
export const SET_DOC_TYPES = "SET_DOC_TYPES";
export const SET_IDS = "SET_IDS";
export const SET_PROPERTY_DATA = "SET_PROPERTY_DATA";
export const SET_LANDLORD_DATA = "SET_LANDLORD_DATA";
export const SET_THIRD_PARTY_DATA = "SET_THIRD_PARTY_DATA";
export const SET_PUBLIC_SERVICES = "SET_PUBLIC_SERVICES";
export const SET_ADMIN_DATA = "SET_ADMIN_DATA";
export const SET_FILES = "SET_FILES";
export const SET_FILE_LINKS = "SET_FILE_LINKS";

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface setAvailableSteps {
  type: typeof SET_AVAILABLE_STEPS;
  steps: Step[];
}

interface setStep {
  type: typeof SET_STEP;
  step: Step;
}

interface nextStep {
  type: typeof NEXT_STEP;
}

interface prevStep {
  type: typeof PREV_STEP;
}

interface setIsAdmin {
  type: typeof SET_IS_ADMIN;
  isAdmin: boolean;
}

interface setReady {
  type: typeof SET_READY;
  ready: Step | "";
}

interface setAlreadySubmitted {
  type: typeof SET_ALREADY_SUBMITTED;
  alreadySubmitted: boolean;
}

interface setSubmitting {
  type: typeof SET_SUBMITTING;
  submitting: boolean;
}

interface setNavText {
  type: typeof SET_NAV_TEXT;
  navText: string;
}

interface setStepMode {
  type: typeof SET_STEP_MODE;
  stepMode: boolean;
}

interface setDocumentTypes {
  type: typeof SET_DOC_TYPES;
  documentTypes: DocumentOptionProps[];
}

interface setIds {
  type: typeof SET_IDS;
  ids: idsProps;
}

interface setPropertyData {
  type: typeof SET_PROPERTY_DATA;
  propertyData: Partial<PropertyDataProps>;
}

interface setLandlordData {
  type: typeof SET_LANDLORD_DATA;
  landlordData: Partial<LandlordDataProps>;
}

interface setThirdPartyData {
  type: typeof SET_THIRD_PARTY_DATA;
  thirdPartyData: ThirdPartyProps;
}

interface setPublicServices {
  type: typeof SET_PUBLIC_SERVICES;
  publicServices: PublicServicesProps;
}

interface setAdminData {
  type: typeof SET_ADMIN_DATA;
  adminData: AdminDataProps;
}

interface setFiles {
  type: typeof SET_FILES;
  files: FilesProps;
}

interface setFileLinks {
  type: typeof SET_FILE_LINKS;
  fileLinks: FileLinksProps;
}

export type Action =
  | setLoading
  | setAvailableSteps
  | setStep
  | nextStep
  | prevStep
  | setIsAdmin
  | setReady
  | setAlreadySubmitted
  | setSubmitting
  | setNavText
  | setStepMode
  | setDocumentTypes
  | setIds
  | setPropertyData
  | setLandlordData
  | setThirdPartyData
  | setPublicServices
  | setAdminData
  | setFiles
  | setFileLinks;

export type Dispatch = (action: Action) => void;
