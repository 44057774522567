import { IoDocumentLockOutline as BrokerageIcon } from "@houm-com/ui-icons";
import PropertyIndex from "Components/Admin/administrator/properties/index";
import ReservationIndex from "Components/Admin/administrator/reservations/index";
import MultiFamilyIndex from "Components/Admin/administrator/multifamily/MultiFamilyIndex";
import MultiFamilyCreate from "Components/Admin/administrator/multifamily/MultiFamilyCreate";
import MultiFamilyTableIndex from "Components/Admin/administrator/multifamily/MultiFamilyTableIndex";
import TypologyCreate from "Components/Admin/administrator/multifamily/TypologyCreate";
import TypologyTableIndex from "Components/Admin/administrator/multifamily/TypologyTableIndex";
import MultifamilyUnit from "Components/Admin/administrator/multifamily/MultifamilyUnitIndex";
import ApplicantShow from "Components/Admin/administrator/applicants";
import AdminPropertyShow from "Components/Admin/administrator/properties/show";
import CreatePropertyView from "Components/Admin/administrator/properties/create";
import ClosureForm from "Components/Admin/administrator/ClosureForm";
import { routeBuilder } from "domains/auth/utils/router-builder";
import LeaseApplication from "apps/brokerage-admin/screens/LeaseApplication";

export default routeBuilder({
  label: "Corretaje",
  icon: BrokerageIcon,
  children: [
    {
      to: "/admin/properties",
      path: "/admin/properties",
      component: PropertyIndex,
      label: "Propiedades",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
        "property_advisor",
      ],
    },
    {
      to: "/admin/reservations",
      path: "/admin/reservations",
      component: ReservationIndex,
      label: "Reservas",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "super_admin",
        "sales_admin",
      ],
    },
    {
      to: "/admin/multifamily",
      path: "/admin/multifamily",
      component: MultiFamilyIndex,
      label: "Multifamily",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      path: "/admin/multifamily/create/:id?",
      component: MultiFamilyCreate,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      path: "/admin/multifamily/:id",
      component: MultiFamilyTableIndex,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      path: "/admin/multifamily/:MFid/typology/create/:id?",
      component: TypologyCreate,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      path: "/admin/multifamily/:MFid/typology/:id",
      component: TypologyTableIndex,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      path: "/admin/multifamily/:MFid/typology/:UTid/unit/:id",
      component: MultifamilyUnit,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      path: "/admin/applicants/:id",
      component: ApplicantShow,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "super_admin",
        "property_advisor",
      ],
    },
    {
      path: "/admin/properties/create",
      component: CreatePropertyView,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
        "houmer_group_leader",
        "property_advisor",
      ],
    },
    {
      path: "/admin/properties/:id",
      component: AdminPropertyShow,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
        "property_advisor",
      ],
    },
    {
      path: "/admin/properties/:id/closureForm",
      component: ClosureForm,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
        "property_advisor",
      ],
    },
    {
      path: "/admin/lease-application/:id",
      component: LeaseApplication,
      scope: "permissions",
      strategy: "all",
      require: [],
    },
  ],
});
